/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';

import {
  Box,
  Typography,
  TypographyProps,
} from '@mui/material';

import InfoPopover from '../components/layout/InfoPopover';

const Label = ({
  title,
  info,
  variant,
  TypographyPropsSx,
}: {
  title: string;
  info?: string | React.ReactElement;
  variant?: TypographyProps['variant'];
  TypographyPropsSx?: TypographyProps['sx'];
}) => (
  <Box display="flex" alignItems="center">
    <Typography sx={TypographyPropsSx} variant={variant ?? 'body1'}>{title}</Typography>
    {!!info && <InfoPopover title={title} content={info} />}
  </Box>
);

export default Label;
