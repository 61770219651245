import React from 'react';
import {
  List,
  Datagrid,
  DateField,
  SelectField,
  WrapperField,
} from 'react-admin';

import { Card } from '@mui/material';

import { reportTypesDisplayChoices } from '../../constants/reportTypes';
import { boldDataGridStyle } from '../../constants/style/datagridStyles';

import DownloadReports from '../../customFields/DownloadReports';

import Pagination from '../layout/Pagination';
import NoResults from '../layout/NoResults';
import { reportStatusesChoices } from '../../constants/reportStatuses';

const ReportList = () => (
  <List
    queryOptions={{ refetchInterval: 5000 }}
    sort={{ field: 'createdAt', order: 'DESC' }}
    empty={false}
    pagination={<Pagination disableEmptyText />}
    exporter={false}
    filter={{ excludeFipo: true }}
  >
    <Card sx={{ margin: 0 }} variant="outlined">
      <Datagrid
        sx={boldDataGridStyle}
        bulkActionButtons={false}
        empty={<NoResults variant="h6" />}
      >
        <DateField sortable showTime source="createdAt" />
        <WrapperField source="files">
          <DownloadReports />
        </WrapperField>
        <SelectField sortable={false} choices={Object.values(reportStatusesChoices)} source="status" />
        <SelectField sortable={false} choices={reportTypesDisplayChoices} source="type" />
      </Datagrid>
    </Card>
  </List>
);

export default ReportList;
