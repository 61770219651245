import React from 'react';

import {
  ReferenceManyField,
} from 'react-admin';

import {
  Grid,
  Card,
  CardHeader,
  Divider,
} from '@mui/material';

import CategorizationActions from '../CategorizationActions';
import { DetailedCategorizationTableBody } from '../DetailedCategorizationTable';
import Params from '../Params';

import Pagination from '../../layout/Pagination';

import { EntityTypes } from '../../../constants/entityTypes';
import NoResults from '../../layout/NoResults';

const DetailedView = ({
  params,
  setParams,
  filter,
}: {
  params: Params;
  setParams: React.Dispatch<React.SetStateAction<Params>>;
  filter?: {
    from: string;
    to: string;
    currency: string;
    labels?: string[] | undefined;
    entityId: string | undefined;
    entityType: EntityTypes;
  };
}) => (
  <Grid container>
    <Grid item xs={12} md={12}>
      <Card variant="outlined">
        <CardHeader title="Detailed Transactions" action={<CategorizationActions params={params} setParams={setParams} />} />
        <Divider />
        {params.currency && (
          <ReferenceManyField
            filter={{
              labels: filter?.labels,
              currency: filter?.currency,
              'custom.entityId': filter?.entityId,
              'custom.entityType': filter?.entityType,
              'custom.from': filter?.from,
              'custom.to': filter?.to,
            }}
            source="transactions"
            perPage={10}
            pagination={<Pagination disableEmptyText rowsPerPageOptions={[5, 10, 25]} />}
            reference="bank-data/transactions"
            target="id"
            sort={{ field: 'date', order: 'DESC' }}
          >
            <DetailedCategorizationTableBody />
          </ReferenceManyField>
        )}
        {!params.currency && <NoResults variant="h4" timeout={2000} />}
      </Card>
    </Grid>
  </Grid>
);

export default DetailedView;
