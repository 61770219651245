import { ValueOf } from '../utilities/types';

const actions = {
  API_KEY_GET: 'ApiKeyGet',
  API_KEY_LIST: 'ApiKeyList',
  API_KEY_CREATE: 'ApiKeyCreate',
  API_KEY_GET_SECRET: 'ApiKeyGetSecret',
  API_KEY_UPDATE: 'ApiKeyUpdate',
  AUDIT_TRACK_LIST: 'AuditTrackList',
  BUSINESS_GET: 'BusinessGet',
  BUSINESS_GET_IP_ADDRESS: 'BusinessGetIpAddress',
  BUSINESS_GET_TRANSACTIONS: 'BusinessGetTransactions',
  BUSINESS_GET_OWNERSHIP: 'BusinessGetOwnership',
  BUSINESS_GET_COMPANY_REP: 'BusinessGetCompanyRep',
  BUSINESS_GET_GENERAL_INFO: 'BusinessGetGeneralInfo',
  BUSINESS_GET_FINANCIAL_DATA: 'BusinessGetFinancialData',
  BUSINESS_GET_EXPOSURE: 'BusinessGetExposure',
  BUSINESS_GET_CREDIT: 'BusinessGetCredit',
  BUSINESS_CREATE_EXPOSURE: 'BusinessCreateExposure',
  BUSINESS_LIST: 'BusinessList',
  BUSINESS_UPDATE: 'BusinessUpdate',
  BUSINESS_CREATE: 'BusinessCreate',
  BUSINESS_CREATE_OWNERSHIP: 'BusinessCreateOwnership',
  BUSINESS_CREATE_COMPANY_REP: 'BusinessCreateCompanyRep',
  BUSINESS_CREATE_GENERAL_INFO: 'BusinessCreateGeneralInfo',
  BUSINESS_CREATE_FINANCIAL_DATA: 'BusinessCreateFinancialData',
  BUSINESS_CREATE_CREDIT: 'BusinessCreateCredit',
  BUSINESS_CREATE_IP_ADDRESS: 'BusinessCreateIpAddress',
  BUSINESS_GET_SANCTION_LIST_COMPANY: 'BusinessGetSanctionListCompany',
  BUSINESS_CREATE_SANCTION_LIST_COMPANY: 'BusinessCreateSanctionListCompany',
  CAR_GET: 'CarGet',
  CAR_GET_BASIC_DATA: 'CarGetBasic',
  CAR_CREATE_BASIC_DATA: 'CarCreateBasic',
  CAR_GET_PREVIOUS_OWNERS_DATA: 'CarGetPreviousOwner',
  CAR_CREATE_PREVIOUS_OWNERS_DATA: 'CarCreatePreviousOwner',
  CAR_GET_TECHNICAL_DATA: 'CarGetTechnical',
  CAR_CREATE_TECHNICAL_DATA: 'CarCreateTechnical',
  CAR_GET_VEHICLE_INSPECTION_DATA: 'CarGetVehicleInspection',
  CAR_CREATE_VEHICLE_INSPECTION_DATA: 'CarCreateVehicleInspection',
  CAR_GET_EXEMPTION_DATA: 'CarGetExemption',
  CAR_CREATE_EXEMPTION_DATA: 'CarCreateExemption',
  CAR_LIST: 'CarList',
  CAR_CREATE: 'CarCreate',
  CAR_UPDATE: 'CarUpdate',
  CASE_ADD_COMMENT: 'CaseAddComment',
  CASE_DOWNLOAD: 'CaseDownload',
  CASE_GET: 'CaseGet',
  CASE_LIST: 'CaseList',
  CASE_UPDATE: 'CaseUpdate',
  CASE_UPDATE_STATUS: 'CaseUpdateStatus',
  CONNECTION_LIST: 'ConnectionList',
  CONNECTION_CREATE: 'ConnectionCreate',
  INDIVIDUAL_GET: 'IndividualGet',
  INDIVIDUAL_GET_IP_ADDRESS: 'IndividualGetIpAddress',
  INDIVIDUAL_GET_BUSINESS_ASSOCIATION: 'IndividualGetBusinessAssociation',
  INDIVIDUAL_GET_PEP: 'IndividualGetPEP',
  INDIVIDUAL_GET_ADVERSE_MEDIA: 'IndividualGetAdverseMedia',
  INDIVIDUAL_GET_SANCTION_LIST: 'IndividualGetSanctionList',
  INDIVIDUAL_GET_SPAR: 'IndividualGetSpar',
  INDIVIDUAL_GET_TRANSACTIONS: 'IndividualGetTransactions',
  INDIVIDUAL_CREATE_BUSINESS_ASSOCIATION: 'IndividualCreateBusinessAssociation',
  INDIVIDUAL_CREATE_PEP: 'IndividualCreatePEP',
  INDIVIDUAL_CREATE_ADVERSE_MEDIA: 'IndividualCreateAdverseMedia',
  INDIVIDUAL_CREATE_SANCTION_LIST: 'IndividualCreateSanctionList',
  INDIVIDUAL_CREATE_SPAR: 'IndividualCreateSpar',
  INDIVIDUAL_CREATE: 'IndividualCreate',
  INDIVIDUAL_LIST: 'IndividualList',
  INDIVIDUAL_UPDATE: 'IndividualUpdate',
  INDIVIDUAL_CREATE_CREDIT: 'IndividualCreateCredit',
  INDIVIDUAL_GET_CREDIT: 'IndividualGetCredit',
  INDIVIDUAL_CREATE_LEGAL_SCORE: 'IndividualCreateLegalScore',
  INDIVIDUAL_GET_LEGAL_SCORE: 'IndividualGetLegalScore',
  INDIVIDUAL_CREATE_IP_ADDRESS: 'IndividualCreateIpAddress',
  MEMBER_GET: 'MemberGet',
  MEMBER_CREATE: 'MemberCreate',
  MEMBER_LIST: 'MemberList',
  MEMBER_UPDATE: 'MemberUpdate',
  MEMBER_INITIATE_RESET: 'MemberInitiateRest',
  MEMBER_UPDATE_ACTIVATION: 'MemberUpdateActivation',
  PING_ADD_COMMENT: 'PingAddComment',
  PING_CREATE: 'PingCreate',
  PING_GET: 'PingGet',
  PING_LIST: 'PingList',
  PING_UPDATE: 'PingUpdate',
  PING_UPDATE_STATUS: 'PingUpdateStatus',
  TAG_GET: 'TagGet',
  TAG_CREATE: 'TagCreate',
  TAG_LIST: 'TagList',
  TAG_UPDATE: 'TagUpdate',
  TAG_UPDATE_ACTIVATION: 'TagUpdateActivation',
  TEAM_GET: 'TeamGet',
  TEAM_CREATE: 'TeamCreate',
  TEAM_LIST: 'TeamList',
  TEAM_UPDATE: 'TeamUpdate',
  TEAM_UPDATE_ACTIVATION: 'TeamUpdateActivation',
  REQUEST_ADD_COMMENT: 'RequestAddComment',
  REQUEST_GET: 'RequestGet',
  REQUEST_LIST: 'RequestList',
  REQUEST_CREATE: 'RequestCreate',
  REQUEST_REPROCESS: 'RequestReprocess',
  REQUEST_UPDATE: 'RequestUpdate',
  REQUEST_UPDATE_RECOMMENDATION: 'RequestUpdateRecommendation',
  RULE_GET: 'RuleGet',
  RULE_DELETE: 'RuleDelete',
  RULE_LIST: 'RuleList',
  RULE_CREATE: 'RuleCreate',
  RULE_UPDATE: 'RuleUpdate',
  REPORT_CREATE: 'ReportCreate',
  REPORT_LIST: 'ReportList',
  REPORT_GET: 'ReportGet',
  REPORT_DOWNLOAD: 'ReportDownload',
  RISK_CLASS_GET: 'RiskClassGet',
  RISK_CLASS_CREATE: 'RiskClassCreate',
  RISK_CLASS_UPDATE: 'RiskClassUpdate',
  RISK_CLASS_LIST: 'RiskClassList',
  RISK_CLASS_DELETE: 'RiskClassDelete',
  RULE_VERSION_LIST: 'RuleVersionList',
  SCORE_LIST: 'ScoreList',
  BANK_DATA_GET_CATEGORIZATION: 'BankDataGetCategorization',
  ACCOUNT_ENTITY_GET: 'AccountEntityGet',
  ACCOUNT_ENTITY_UPDATE: 'AccountEntityUpdate',
  ACCOUNT_ENTITY_LIST: 'AccountEntityList',
  FORM_CREATE: 'FormCreate',
  FORM_GET: 'FormGet',
  FORM_GET_LINK: 'FormGetLink',
  FORM_ANSWER_GET: 'FormAnswerGet',
  FORM_ANSWER_SUBMIT: 'FormAnswerSubmit',
  FORM_ANSWER_UPDATE: 'FormAnswerUpdate',
  PROCESS_GET: 'ProcessGet',
  PROCESS_CREATE: 'ProcessCreate',
  PROCESS_UPDATE: 'ProcessUpdate',
  PROCESS_LIST: 'ProcessList',
  PROCESS_UPDATE_ACTIVATION: 'ProcessUpdateActivation',
  PRODUCT_GET: 'ProductGet',
  PRODUCT_CREATE: 'ProductCreate',
  PRODUCT_UPDATE: 'ProductUpdate',
  PRODUCT_LIST: 'ProductList',
  GROUP_GET: 'GroupGet',
  GROUP_CREATE: 'GroupCreate',
  GROUP_UPDATE: 'GroupUpdate',
  GROUP_LIST: 'GroupList',
  GROUP_UPDATE_ACTIVATION: 'GroupUpdateActivation',
  TRANSACTION_TYPE_GET: 'TransactionTypeGet',
  TRANSACTION_TYPE_CREATE: 'TransactionTypeCreate',
  TRANSACTION_TYPE_UPDATE: 'TransactionTypeUpdate',
  TRANSACTION_TYPE_LIST: 'TransactionTypeList',
  TRANSACTION_TYPE_UPDATE_ACTIVATION: 'TransactionTypeUpdateActivation',
  SIGNAL_GET: 'SignalGet',
  SIGNAL_CREATE: 'SignalCreate',
  SIGNAL_UPDATE: 'SignalUpdate',
  SIGNAL_LIST: 'SignalList',
  SIGNAL_UPDATE_ACTIVATION: 'SignalUpdateActivation',
  PROVIDER_MANAGEMENT: 'ProviderManagement',
} as const;

export type Actions = ValueOf<typeof actions>;

export default actions;
