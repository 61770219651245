import React, { useEffect, useState } from 'react';

import {
  Box, Button, Card, Grid, Typography,
} from '@mui/material';

import {
  FileInput, Form, RaRecord, useUpdate,
} from 'react-admin';

import { useParams } from 'react-router-dom';

import { useFormContext } from 'react-hook-form';

import EnhancedFileField from '../../../customFields/EnhancedFileField';

const InsertFiles = ({
  kycFormId,
  setFiles,
}: {
  kycFormId?: string;
  setFiles: React.Dispatch<React.SetStateAction<RaRecord[]>>;
}) => {
  const { watch } = useFormContext();
  const files = watch('files');

  useEffect(() => {
    setFiles(files);
  }, [files, setFiles]);

  if (!kycFormId) return null;

  return (
    <Card variant="outlined">
      <FileInput
        resource="form-answers"
        source="files"
        label={false}
        accept="application/pdf, .doc, .docx, image/*, .xlsx, .xls"
        sx={{
          '& .RaFileInput-dropZone': {
            borderBottom: '1px solid #e2e3e4',
            borderRadius: 0,
          },
          '& .previews': {
            pl: 2,
            pb: 4,
          },
        }}
        multiple
      >
        <EnhancedFileField source="_id" title="name" target="_blank" parentId={kycFormId} resource="form-answers" />
      </FileInput>
    </Card>
  );
};

const UploadsFiles = ({ pageNumber }: {pageNumber: number}) => {
  const { kycFormId } = useParams();
  const [files, setFiles] = useState<RaRecord[]>([]);

  const [update] = useUpdate();

  return (
    <Box display="flex" flexDirection="column" gap={4} my="1%">
      <Typography variant="h5" color="primary">{`${pageNumber}. Upload files`}</Typography>
      <Grid container spacing={6}>
        <Grid item xs={12} md={12}>
          <Form>
            <InsertFiles setFiles={setFiles} kycFormId={kycFormId} />
          </Form>
        </Grid>
        <Grid item xs={12} md={12} display="flex" justifyContent="center">
          <Button variant="contained" onClick={() => update('form-answers', { id: kycFormId, data: { files } })}>Upload files</Button>
        </Grid>
      </Grid>
    </Box>
  );
};

export default UploadsFiles;
