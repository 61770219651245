import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';

import { camelCase } from 'lodash';

import {
  DateInput,
  Identifier,
  RecordContextProvider,
  useGetOne,
  useRefresh,
} from 'react-admin';

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from '@mui/material';

import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';

import { useParams } from 'react-router-dom';
import useDialogStatus from '../../hooks/useDialogStatus';

import Params from './Params';

import labels from '../../constants/labels';

type CurrenciesResp = {id: Identifier; currencies: string[]}

const DateInputField = ({
  date, handleChange, label,
}: {
  label: string;

  date: string;
  handleChange: ({ target: { value } }: any) => void;
}) => (
  <RecordContextProvider value={{ [`_${camelCase(label)}`]: date }}>
    <DateInput value={date} helperText={false} onChange={handleChange} source={`_${camelCase(label)}`} label={label} />
  </RecordContextProvider>
);

const CategorizationActions = ({
  setParams,
  params,
} : {
  setParams: React.Dispatch<React.SetStateAction<Params>>;
  params: Params;
}) => {
  const { open, closeDialog, openDialog } = useDialogStatus();
  const [tempParams, setTempParams] = useState(params);

  const refresh = useRefresh();

  const { id: entityId } = useParams();

  const handleChange = useCallback(
    (name: string) => ({ target: { value } }: any) => {
      setTempParams(
        (prev) => ({ ...prev, [name]: value }),
      );
    },
    [],
  );

  const applyTempParams = useCallback(
    () => {
      setParams(tempParams);
      refresh();
      closeDialog();
    },

    [closeDialog, refresh, setParams, tempParams],
  );

  const { data } = useGetOne<CurrenciesResp>('bank-data', { id: `currencies/${entityId}` });

  useEffect(() => {
    if (data) {
      handleChange('currency')({ target: { value: data.currencies[0] } });
    }
  }, [data, handleChange]);

  const activeFilters = useMemo(
    () => [params.currency, params.from, params.to, params.labels?.length]
      .filter((param) => !!param)
      .length,
    [params.currency, params.from, params.labels, params.to],
  );

  return (
    <>
      <Button sx={{ minHeight: 44 }} startIcon={<PlaylistAddIcon />} variant="contained" onClick={openDialog}>
        {`Filters (${activeFilters})`}
      </Button>
      <Dialog open={open} fullWidth maxWidth="xs">
        <DialogTitle color="secondary">Filter Transactions</DialogTitle>
        <DialogContent>
          <Grid container spacing={6}>
            <Grid item xs={12} md={12}>
              <FormControl fullWidth variant="filled" sx={{ marginBottom: 1 }}>
                <InputLabel>Labels</InputLabel>
                <Select
                  multiple
                  size="small"
                  value={tempParams?.labels ?? []}
                  onChange={handleChange('labels')}
                >
                  {labels.map(
                    ({ id, name }) => (<MenuItem key={id} value={id}>{name}</MenuItem>),
                  )}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={12}>
              <FormControl fullWidth variant="filled" sx={{ marginBottom: 1 }}>
                <InputLabel>Currency</InputLabel>
                <Select
                  size="small"
                  value={tempParams.currency}
                  onChange={handleChange('currency')}
                >
                  {data?.currencies.map(
                    (currency: string) => (
                      <MenuItem key={currency} value={currency}>{currency}</MenuItem>),
                  )}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={12}>
              <Box gap={2} flexDirection="column" display="flex">
                <Typography variant="label">Dates</Typography>
                <DateInputField handleChange={handleChange('from')} date={tempParams.from} label="From" />
                <DateInputField handleChange={handleChange('to')} date={tempParams.to} label="To" />

              </Box>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Box paddingTop="5%" gap={2} display="flex" justifyContent="flex-end">
            <Button onClick={closeDialog} color="error" variant="contained">Close</Button>
            <Button onClick={applyTempParams} variant="contained">Save</Button>
          </Box>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default CategorizationActions;
