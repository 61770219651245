import React, { useCallback } from 'react';

import { ToggleButton, Typography } from '@mui/material';

import { useListContext } from 'react-admin';

import StyledToggleButtonGroup from '../../../layout/button/StyledToggleButtonGroup';
import ruleTypes, { RuleTypes } from '../../../../constants/ruleTypes';

const Type = ({ changeRuleType, ruleType, changeVersion }: {
  ruleType: RuleTypes;
  changeRuleType: (x: RuleTypes) => void;
  changeVersion: (x: string) => void;
}) => {
  const { setFilters, filterValues } = useListContext();

  const handleChange = useCallback((
    event: React.MouseEvent<HTMLElement>,
    value: RuleTypes,
  ) => {
    if (value !== null) {
      changeRuleType(value);
      setFilters({
        ...filterValues,
        ruleType: value,
      }, {}, false);
    }
    changeVersion('draft');
  }, [changeRuleType, changeVersion, filterValues, setFilters]);

  return (
    <StyledToggleButtonGroup
      size="small"
      value={ruleType}
      exclusive
      onChange={handleChange}
      aria-label="text alignment"
    >
      <ToggleButton color="primary" value={ruleTypes.REQUEST}>
        <Typography>Request</Typography>
      </ToggleButton>
      <ToggleButton color="primary" value={ruleTypes.ONGOING}>
        <Typography>Ongoing</Typography>
      </ToggleButton>
    </StyledToggleButtonGroup>
  );
};

export default Type;
