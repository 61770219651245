import { Route } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import {
  Resource,
  ResourceOptions,
  useGetResourceLabel,
  usePermissions,
  NotFound,
  useAuthState,
  useLogout,
} from 'react-admin';

import hasAccess from '../../utilities/hasAccess';
import getResourceName from '../../utilities/getResourceName';

import { Actions } from '../../constants/actions';
import { useRegisterBreadcrumb } from '../layout/navbar/BreadcrumbsManager';

const getActionName = (resourceName: string, actionType: string) => `${getResourceName(resourceName)}${actionType}` as Actions;

const ResourceBreadcrumb = ({
  name,
}: { name: string }) => {
  const getResourceLabel = useGetResourceLabel();
  useRegisterBreadcrumb({
    title: getResourceLabel(name, 2),
    path: name,
  });

  return null;
};

const NonFlickeringNotFound = () => {
  const { isLoading, authenticated } = useAuthState();
  const logout = useLogout();
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    const callback = async () => {
      if (!authenticated) {
        await logout();
      }
      setIsLoaded(true);
    };
    if (!isLoading) {
      callback();
    }
  }, [authenticated, isLoading, logout]);

  return isLoaded ? (<NotFound />) : null;
};

type AuthenticatedResourceInput = {
  name: string;
  list?: React.FC;
  edit?: React.FC;
  show?: React.FC;
  create?: React.FC;
  icon?: React.FC;
  options?: ResourceOptions;
}

const AuthenticatedResource = ({
  name,
  list,
  edit,
  show,
  create,
  icon,
  options,
}: AuthenticatedResourceInput) => {
  const { permissions } = usePermissions();
  return (
    <>
      <ResourceBreadcrumb name={name} />
      <Resource
        name={name}
        list={hasAccess(permissions, getActionName(name, 'List')) ? list : undefined}
        edit={hasAccess(permissions, getActionName(name, 'Get')) ? edit : undefined}
        show={hasAccess(permissions, getActionName(name, 'Get')) ? show : undefined}
        create={hasAccess(permissions, getActionName(name, 'Create')) ? create : undefined}
        icon={icon}
        options={options}
      >
        <Route path="*" element={<NonFlickeringNotFound />} />
      </Resource>
    </>
  );
};

AuthenticatedResource.raName = Resource.raName;
AuthenticatedResource.registerResource = ({
  create,
  edit,
  icon,
  list,
  name,
  options,
  show,
}: AuthenticatedResourceInput, permissions: Actions[]) => ({
  name,
  options,
  hasList: !!list && hasAccess(permissions, getActionName(name, 'List')),
  hasCreate: !!create && hasAccess(permissions, getActionName(name, 'Create')),
  hasEdit: !!edit && hasAccess(permissions, getActionName(name, 'Get')),
  hasShow: !!show && hasAccess(permissions, getActionName(name, 'Get')),
  icon,
});

export default AuthenticatedResource;
