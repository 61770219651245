import React from 'react';

import { Grid } from '@mui/material';

import {
  SelectInput,
  TextInput,
  SelectInputProps,
  required,
} from 'react-admin';

const Select = ({ choices }: {
  choices: SelectInputProps['choices'];
}) => (
  <Grid item xs={12} md={7}>
    <Grid container>
      <Grid item xs={12} md={12}>
        <SelectInput
          choices={choices}
          fullWidth
          source="value"
          label="New value"
          validate={[required()]}
        />
      </Grid>
      <Grid item xs={12} md={12}>
        <TextInput
          fullWidth
          source="comment"
          multiline
          rows={5}
          helperText={false}
          placeholder="Please explain your decision."
          validate={[required()]}
        />
      </Grid>
    </Grid>
  </Grid>
);

export default Select;
