import React, { useCallback } from 'react';

import { usePermissions } from 'react-admin';

import { Box, Button } from '@mui/material';

import hasAccess from '../../../utilities/hasAccess';

import actions, { Actions } from '../../../constants/actions';

const EntityType = ({ type, setType }: { type: string; setType: (x: string) => void}) => {
  const { permissions } = usePermissions<Actions[]>();

  const handleTypeChange = useCallback((value: string) => {
    setType(value);
  }, [setType]);

  return (
    <Box display="flex" justifyContent="end" margin={1} gap={2}>
      {hasAccess(permissions, actions.INDIVIDUAL_GET) && (
        <Button
          size="small"
          variant="text"
          color="primary"
          disabled={type === 'Individual'}
          onClick={() => handleTypeChange('Individual')}
        >
          Individuals
        </Button>
      )}

      {hasAccess(permissions, actions.BUSINESS_GET) && (
        <Button
          size="small"
          variant="text"
          color="primary"
          disabled={type === 'Business'}
          onClick={() => handleTypeChange('Business')}
        >
          Businesses
        </Button>
      )}

      {hasAccess(permissions, actions.ACCOUNT_ENTITY_GET) && (
        <Button
          size="small"
          variant="text"
          color="primary"
          disabled={type === 'Account'}
          onClick={() => handleTypeChange('Account')}
        >
          Accounts
        </Button>
      )}

      {hasAccess(permissions, actions.CAR_GET) && (
        <Button
          size="small"
          variant="text"
          color="primary"
          disabled={type === 'Car'}
          onClick={() => handleTypeChange('Car')}
        >
          Cars
        </Button>
      )}

      <Button
        size="small"
        variant="text"
        color="primary"
        disabled={type === 'All'}
        onClick={() => handleTypeChange('All')}
      >
        All
      </Button>
    </Box>
  );
};

export default EntityType;
