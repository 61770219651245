import React, { useMemo } from 'react';

import {
  ChoicesContextProvider,
  useChoicesContext,
} from 'react-admin';

const ExtendedChoiceContext = ({
  extraChoices,
  children,
}: {
  extraChoices: { id: string; name: string }[];
  children: React.ReactNode;
}) => {
  const choiceContext = useChoicesContext();

  const extendedChoiceContext = useMemo(() => ({
    ...choiceContext,
    allChoices: [...choiceContext.allChoices, ...extraChoices],
  }), [choiceContext, extraChoices]);

  return (
    <ChoicesContextProvider value={extendedChoiceContext}>
      {children}
    </ChoicesContextProvider>
  );
};

export default ExtendedChoiceContext;
