import React from 'react';

import {
  ArrayField,
  Datagrid,
  DateField,
  RaRecord,
  RecordContextProvider,
  SelectField,
  TextField,
} from 'react-admin';

import {
  Box,
  Card,
  CardHeader,
  Grid,
  CardContent,
  Typography,
  Divider,
} from '@mui/material';

import { boldDataGridStyle } from '../../../../../constants/style/datagridStyles';
import actions from '../../../../../constants/actions';

import NoResults from '../../../../layout/NoResults';
import PurchaseButton from '../../../../layout/button/PurchaseButton';

const VehicleInspection = ({
  data,
  handlePurchase,
  isLoading,
}: {
  data: RaRecord;
  handlePurchase: () => void;
  isLoading: boolean;
}) => {
  if (data) {
    return (
      <RecordContextProvider value={data}>
        <Grid item xs={12} md={12}>
          <Grid container>
            <Grid item xs={12} md={12}>
              <Card variant="outlined">
                <CardHeader
                  title="Details"
                  subheader={(
                    <Box display="flex" alignItems="baseline">
                      <Typography>Latest update:</Typography>
                      <DateField
                        sx={{ marginLeft: 1, fontWeight: 'bold' }}
                        source="createdAt"
                        emptyText="-"
                        showTime
                      />
                    </Box>
                  )}
                  action={(
                    <PurchaseButton
                      loading={isLoading}
                      label="Get Inspection"
                      onClick={handlePurchase}
                      action={actions.CAR_CREATE_VEHICLE_INSPECTION_DATA}
                    />
                  )}
                />
                <Divider />
                <CardContent>
                  <Grid container spacing={6}>
                    <Grid item xs={2} md={2}>
                      <Box>
                        <Typography variant="smallTitle">
                          Latest inspection:
                        </Typography>
                        <TextField
                          emptyText="-"
                          source="senastbskt"
                        />
                      </Box>
                    </Grid>
                    <Grid item xs={2} md={2}>
                      <Box>
                        <Typography variant="smallTitle">
                          Latest outcome:
                        </Typography>
                        <TextField
                          emptyText="-"
                          source="senastutfall"
                        />
                      </Box>
                    </Grid>
                    <Grid item xs={2} md={2}>
                      <Box>
                        <Typography variant="smallTitle">
                          Latest approval:
                        </Typography>
                        <TextField
                          emptyText="-"
                          source="senastgodk"
                        />
                      </Box>
                    </Grid>
                    <Grid item xs={2} md={2}>
                      <Box>
                        <Typography variant="smallTitle">
                          Driving ban:
                        </Typography>
                        <SelectField
                          choices={[
                            { id: 'J', name: 'Yes' },
                            { id: 'N', name: 'No' },
                          ]}
                          emptyText="-"
                          source="korforbud"
                        />
                      </Box>
                    </Grid>
                    <Grid item xs={2} md={2}>
                      <Box>
                        <Typography variant="smallTitle">
                          Driving ban date:
                        </Typography>
                        <TextField
                          emptyText="-"
                          source="kforbdatum"
                        />
                      </Box>
                    </Grid>
                    <Grid item xs={2} md={2}>
                      <Box>
                        <Typography variant="smallTitle">
                          Approved until:
                        </Typography>
                        <TextField
                          emptyText="-"
                          source="kontrollbesGallerTom"
                        />
                      </Box>
                    </Grid>
                    <Grid item xs={2} md={2}>
                      <Box>
                        <Typography variant="smallTitle">
                          Latest inspection:
                        </Typography>
                        <TextField
                          emptyText="-"
                          source="senastbskt"
                        />
                      </Box>
                    </Grid>
                    <Grid item xs={2} md={2}>
                      <Box>
                        <Typography variant="smallTitle">
                          Inspection group:
                        </Typography>
                        <TextField
                          emptyText="-"
                          source="bsktgrupp"
                        />
                      </Box>
                    </Grid>
                    <Grid item xs={2} md={2}>
                      <Box>
                        <Typography variant="smallTitle">
                          Inspection station number:
                        </Typography>
                        <TextField
                          emptyText="-"
                          source="stationnr"
                        />
                      </Box>
                    </Grid>
                    <Grid item xs={2} md={2}>
                      <Box>
                        <Typography variant="smallTitle">
                          Inspection station:
                        </Typography>
                        <TextField
                          emptyText="-"
                          source="station"
                        />
                      </Box>
                    </Grid>
                    <Grid item xs={2} md={2}>
                      <Box>
                        <Typography variant="smallTitle">
                          Road master:
                        </Typography>
                        <TextField
                          emptyText="-"
                          source="vagmatare"
                        />
                      </Box>
                    </Grid>
                    <Grid item xs={2} md={2}>
                      <Box>
                        <Typography variant="smallTitle">
                          Retardation:
                        </Typography>
                        <TextField
                          emptyText="-"
                          source="retardation"
                        />
                      </Box>
                    </Grid>
                    <Grid item xs={2} md={2}>
                      <Box>
                        <Typography variant="smallTitle">
                          Reason:
                        </Typography>
                        <TextField
                          emptyText="-"
                          source="orsak"
                        />
                      </Box>
                    </Grid>
                    <Grid item xs={2} md={2}>
                      <Box>
                        <Typography variant="smallTitle">
                          Respit:
                        </Typography>
                        <SelectField
                          choices={[
                            { id: 'J', name: 'Yes' },
                            { id: 'N', name: 'No' },
                          ]}
                          emptyText="-"
                          source="respit"
                        />
                      </Box>
                    </Grid>
                    <Grid item xs={2} md={2}>
                      <Box>
                        <Typography variant="smallTitle">
                          Noise:
                        </Typography>
                        <TextField
                          emptyText="-"
                          source="buller"
                        />
                      </Box>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} md={12}>
              <Card variant="outlined">
                <CardHeader
                  title="Deficiency"
                  subheader={(
                    <Box display="flex" alignItems="baseline">
                      <Typography>Latest update:</Typography>
                      <DateField
                        sx={{ marginLeft: 1, fontWeight: 'bold' }}
                        source="createdAt"
                        emptyText="-"
                        showTime
                      />
                    </Box>
                  )}
                  action={(
                    <PurchaseButton
                      loading={isLoading}
                      label="Get Deficiency"
                      onClick={handlePurchase}
                      action={actions.CAR_CREATE_VEHICLE_INSPECTION_DATA}
                    />
                  )}
                />
                <Divider />
                <ArrayField record={{ brister: data.brister.map((item: string, idx: number) => ({ idx: idx + 1, desc: item })) }} source="brister">
                  <Datagrid
                    sx={boldDataGridStyle}
                    bulkActionButtons={false}
                    empty={<NoResults />}
                  >
                    <TextField source="idx" emptyText="-" label="Index" />
                    <TextField source="desc" emptyText="-" label="Description" />
                  </Datagrid>
                </ArrayField>
              </Card>
            </Grid>
          </Grid>
        </Grid>
      </RecordContextProvider>
    );
  }
  return null;
};

export default VehicleInspection;
