import { ValueOf } from '../utilities/types';

const entityTypes = {
  INDIVIDUAL: 'Individual',
  BUSINESS: 'Business',
  CAR: 'Car',
  ACCOUNT: 'Account',
  PRODUCT: 'Product',
} as const;

export const entityTypesFromResource: Record<string, EntityTypes> = {
  individuals: entityTypes.INDIVIDUAL,
  businesses: entityTypes.BUSINESS,
  cars: entityTypes.CAR,
  'account-entities': entityTypes.ACCOUNT,
  products: entityTypes.PRODUCT,
};

export const resourceFromEntityTypes: Record<EntityTypes, string> = {
  [entityTypes.INDIVIDUAL]: 'individuals',
  [entityTypes.BUSINESS]: 'businesses',
  [entityTypes.CAR]: 'cars',
  [entityTypes.ACCOUNT]: 'account-entities',
  [entityTypes.PRODUCT]: 'products',
};

export const entityTypesChoices = Object.values(entityTypes).map((type) => ({
  id: type,
  name: type,
}));

export type EntityTypes = ValueOf<typeof entityTypes>;

export default entityTypes;
