import React from 'react';
import { RaRecord, TextField, useRecordContext } from 'react-admin';

import {
  get,
  isArray,
  isObject,
  isString,
  lowerCase,
  startCase,
} from 'lodash';

import {
  Box,
  Grid,
  Typography,
} from '@mui/material';

import ruleParameters, { RuleParameters } from '../../../constants/ruleParams';
import { mapRuleParams } from '../ruleFeatures';
import { Rule } from '../../../utilities/schemas/rules';
import { transactionMonitoringModesChoices } from '../../../constants/transactionMonitoringMode';

const ruleDataTransform = (data: RaRecord | string, param: string) => {
  const regex = /[ !"#$%&'()*+,-./:;<=>?@[\\\]^_`{|}~]/; // special characters'
  if (param.includes('commonThreshold')) {
    const global = get(data, 'global');
    if (global) return `${global}`;
    return 'Risk-based';
  }

  if (ruleParameters.TRANSACTION_MONITORING_MODE.id === param) {
    return transactionMonitoringModesChoices.find((choice) => choice.id === data)?.name;
  }

  if (isArray(data)) {
    if ([ruleParameters.FORM_THRESHOLDS.id, ruleParameters.DEFAULT_THRESHOLDS.id]
      .some((field) => field === param as RuleParameters['id'])) {
      return data.map((val) => Object.values(val)
        .filter((v) => !!v)
        .map(((v) => (isObject(v) ? 'Risk-based' : `${v}`)))
        .join(': '))
        .join(', ');
    }
    if (ruleParameters.LOWEST_ACCEPTABLE_INCOME.id === param) {
      return data.map((threshold) => `${threshold.currency}: ${threshold.amount} / ${threshold.unitOfTime}`).join(', ');
    }

    return data.map((item) => lowerCase(startCase(item))).join(', ');
  }
  if (isString(data) && regex.test(data)) return data;
  if (isString(data)) return startCase(data);
  return data;
};

const Default = () => {
  const record = useRecordContext<Rule>();
  if (!record) return null;

  return mapRuleParams[record.name]?.map((param: {
    id: string;
    name: string;
    unit?: string;
    subIds?: readonly string[];
    riskBased?: boolean;
  }) => {
    const data = param.id.split('.').reduce((o, i) => get(o, i, null), record?.ruleParameters);
    const ruleValue = ruleDataTransform(data, param.id);

    if (!data) return null;

    if (!ruleValue) return null;
    const string = param.subIds
      ? param.subIds.map((id) => data[id]).join(' ')
      : `${ruleValue} ${param.unit ?? ''}`;
    return (
      <Grid key={param.id} item xs={12} sm={6}>
        <Box display="flex" flexDirection="column">
          <Box display="flex" flexDirection="row" gap={2} alignItems="center">
            <Typography fontWeight="bold" color="black">{param?.name}</Typography>
          </Box>
          <Box display="flex" flexDirection="row" gap={2} alignItems="center" flexWrap="wrap">
            <TextField record={{ data: string }} source="data" />
          </Box>
        </Box>
      </Grid>
    );
  });
};
export default Default;
