import { LoginMethods } from '../constants/loginMethods';

const getItem = (key: string): AccountDetails | null => {
  try {
    const item = localStorage.getItem(key);
    if (item) return JSON.parse(item);
    return null;
  } catch {
    return null;
  }
};

const setItem = (key: string, value: any) => localStorage.setItem(key, JSON.stringify(value));

const safeRemoveItemLocalStorage = (key: string) => localStorage.removeItem(key);

export type AccountDetails = {
  alias: string;
  currentLoginMethod?: LoginMethods;
}
class AccountDetailsStore {
  accountDetails: AccountDetails | null;

  private readonly key = 'accountDetails';

  constructor() {
    this.accountDetails = null;
  }

  getAccountDetails() {
    if (!this.accountDetails) {
      this.accountDetails = getItem(this.key);
    }
    return this.accountDetails;
  }

  setAccountDetails(accountDetails: AccountDetails) {
    this.accountDetails = accountDetails;
    setItem(this.key, accountDetails);
  }

  removeAccountDetails() {
    this.accountDetails = null;
    safeRemoveItemLocalStorage(this.key);
  }
}

const accountDetailsStore = new AccountDetailsStore();

export default accountDetailsStore;
