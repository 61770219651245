import React, { useCallback, useMemo } from 'react';

import {
  TextField,
  Datagrid,
  useRecordContext,
  ReferenceArrayField,
} from 'react-admin';

import {
  Box,
  Button,
  Card,
  CardHeader,
  Divider,
} from '@mui/material';

import Visibility from '@mui/icons-material/Visibility';
import { boldDataGridStyle } from '../../../../../constants/style/datagridStyles';

import useRelatedEntities from '../../../../../hooks/useRelatedEntities';
import NoResults from '../../../../layout/NoResults';
import entityTypes from '../../../../../constants/entityTypes';
import StatusField from '../../../../../customFields/StatusField';
import ColoredScoreField from '../../../../../customFields/ColoredScoreField';

const RelationField = ({
  entities,
}: {
  entities: {
    entityId: string;
    entityType: string;
    relation: string;
  }[];
  label: string;
}) => {
  const record = useRecordContext();

  const relation = useMemo(
    () => entities.find((entity) => entity.entityId === record?.id)?.relation,
    [entities, record?.id],
  );

  return (
    <TextField record={{ relation }} source="relation" emptyText="-" />
  );
};

const OpenField = ({
  label,
}: {
  label: string;
}) => {
  const record = useRecordContext();

  const handelClick = useCallback(() => {
    window.open(`/individuals/${record?.id}`, '_blank');
  }, [record?.id]);

  return (
    <Button startIcon={<Visibility />} onClick={handelClick}>
      {label}
    </Button>
  );
};

const List = () => {
  const { entities } = useRelatedEntities();

  const individuals = useMemo(() => entities
    .filter((individual) => individual.entityType === entityTypes.INDIVIDUAL)
    .map((individual) => individual.entityId), [entities]);

  if (individuals?.length > 0) {
    return (
      <Box width="100%">
        <Card variant="outlined">
          <CardHeader
            sx={{
              color: 'black',
              fontWeight: 'bold',
            }}
            titleTypographyProps={{ variant: 'inherit' }}
            title={`Related Individuals: ${individuals.length}`}
          />
          <Divider />
          <ReferenceArrayField reference="individuals" fullWidth record={{ individuals, id: '' }} source="individuals">
            <Datagrid sx={boldDataGridStyle} bulkActionButtons={false}>
              <TextField source="firstName" />
              <TextField source="lastName" />
              <RelationField entities={entities} label="Relation to company" />
              <ColoredScoreField source="riskScore" />
              <StatusField source="populationRegisterCollected" label="Population register collected" />
              <StatusField source="isPep" label="Is PEP" />
              <StatusField source="isSanctioned" label="Is sanctioned" />
              <OpenField label="Open" />
            </Datagrid>
          </ReferenceArrayField>
        </Card>
      </Box>
    );
  }
  return <NoResults />;
};

export default List;
