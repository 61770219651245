import React from 'react';

import {
  Box,
  Theme,
  Accordion as MuiAccordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  styled,
} from '@mui/material';

const StyledAccordion = styled(MuiAccordion)(({ theme }: { theme: Theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&:before': {
    display: 'none',
  },
}));

type AccordionItemType = {
  children: React.ReactElement;
  actions?: React.ReactElement;
  label: string;
}

export const Accordion = ({
  children, actions, label,
} : AccordionItemType) => (
  <StyledAccordion disableGutters elevation={0} expanded>
    <AccordionSummary>
      <Box width="100%" display="flex" alignItems="center" justifyContent="space-between">
        <Typography variant="h6" color="secondary">{label}</Typography>
        {actions}
      </Box>
    </AccordionSummary>
    <AccordionDetails>
      {children}
    </AccordionDetails>
  </StyledAccordion>
);

export default Accordion;
