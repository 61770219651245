import React from 'react';
import {
  NumberField,
  useRecordContext,
  NumberFieldProps,
  RaRecord,
} from 'react-admin';

const coloredStyles = {
  green: { color: '#477B3B' },
  yellow: { color: '#F3B61F' },
  red: { color: '#DE2B2B' },
  black: { color: 'black' },
};

const getClassName = (score: number) => {
  if (!score) return 'black';
  if (score <= 30) return 'green';
  if (score < 80) return 'yellow';
  return 'red';
};

const ColoredScoreField = <RecordType extends RaRecord>({
  source,
  label,
}: {
  source: NumberFieldProps<RecordType>['source'];
  label?: NumberFieldProps<RecordType>['label'];
  sortable?: boolean;
}) => {
  const record = useRecordContext<RecordType>();
  if (!record || !source) return null;
  return (
    <NumberField
      source={source}
      label={label}
      emptyText="-"
      sx={coloredStyles[getClassName(record[source])]}
    />
  );
};

export default ColoredScoreField;
