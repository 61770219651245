import React from 'react';

import {
  useRecordContext,
  SelectField,
  TextField,
} from 'react-admin';

import {
  Box,
  Grid,
  Typography,
} from '@mui/material';

import { mapRuleParams, mapRuleParamsComponents, ruleNameChoices } from '../../../ruleFeatures';
import { Rule } from '../../../../../utilities/schemas/rules';

const RuleLogic = () => {
  const record = useRecordContext<Rule>();

  if (!record) return null;

  return (
    <Grid container spacing={6}>
      <Grid item xs={12} md={12}>
        <Box display="flex" flexDirection="column">
          <Typography variant="label" color="black" fontWeight="bold">Rule Name</Typography>
          <SelectField choices={ruleNameChoices} source="name" />
        </Box>
      </Grid>
      <Grid item xs={12} md={12}>
        <Box display="flex" flexDirection="column">
          <Typography variant="label" color="black" fontWeight="bold">Custom Rule Name</Typography>
          <TextField source="customName" />
        </Box>
      </Grid>
      <Grid item container spacing={2} xs={12} md={12} display={mapRuleParams[record.name]?.length > 0 ? 'flex' : 'none'}>
        <Grid item xs={12} md={12}>
          <Typography variant="label" fontWeight="bold">Rule parameters</Typography>
        </Grid>
        {React.createElement(mapRuleParamsComponents[record.name])}
      </Grid>
    </Grid>
  );
};

export default RuleLogic;
