import React from 'react';

import { TextField, useRecordContext } from 'react-admin';

import { get, isEmpty, keyBy } from 'lodash';

import { Typography } from '@mui/material';

import Answer from '../Answer';

import { QuestionElementTypes } from '../../../../constants/questionElementTypes';
import { Languages } from '../../../../constants/languages';
import conflictFields from '../../../../constants/conflictFields';

const ValueField = ({
  extraParameters,
}: {
  extraParameters?: {
    label?: string;
    options?: { id: string; label: string }[];
    type?: QuestionElementTypes;
  };
  language?: Languages;
}) => {
  const record = useRecordContext();

  const values = get(record, 'value.options');
  const sx = get(record, 'sx', {});

  if (isEmpty(values)) return <Typography>-</Typography>;

  const mapFields = keyBy(
    extraParameters?.options?.map((option) => ({ id: option.id, name: option.label })),
    'id',
  );

  const value = values.map((v: string) => get(mapFields, v, { name: v }).name).join(', ');

  return <TextField record={{ value }} sx={sx} source="value" emptyText="-" fontWeight="bold" />;
};

const Products = ({
  extraParameters,
}: {
  extraParameters?: {
    label?: string;
    options?: { id: string; label: string }[];
    type?: QuestionElementTypes;
  };
}) => (
  <Answer
    datapoint={conflictFields.PRODUCTS}
    label={extraParameters?.label ?? ''}
    source="products"
  >
    <ValueField extraParameters={extraParameters} />
  </Answer>
);

Products.ValueField = ValueField;

export default Products;
