import React, { useCallback, useState } from 'react';

import {
  RaRecord,
  useArrayInput,
  useRecordContext,
} from 'react-admin';

import {
  IconButton,
} from '@mui/material';

import { UseFieldArrayReturn, useFormContext } from 'react-hook-form';

import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import RemoveIcon from '@mui/icons-material/Remove';

type RecordIdField = {
  recordId: string;
};
type ArrayInputField<T extends RecordIdField> = Record<'id', string> & Partial<T>;

const PrefillButton = <T extends RaRecord, U extends RecordIdField>({
  handleOpenChange,
  prefillForm,
}: {
  handleOpenChange: () => void;
  prefillForm: (record: T) => U;
}) => {
  const [hover, setHover] = useState<boolean>(false);
  const { reset } = useFormContext();
  // @ts-ignore
  const { fields, remove } = useArrayInput() as UseFieldArrayReturn<{ path?: ArrayInputField<T>[] }, 'path'>;
  const record = useRecordContext<T>();

  const handleRemove = useCallback(() => {
    if (!record?.id) return;

    const index = fields.findIndex((field) => field.recordId === record.id);
    remove(index);
  }, [fields, record?.id, remove]);

  const handleAdd = useCallback(() => {
    if (!record) return;
    handleOpenChange();
    reset(prefillForm(record));
  }, [handleOpenChange, prefillForm, record, reset]);

  if (!record) return null;

  if (fields.find((field) => field.recordId === record.id)) {
    return (
      <IconButton
        onMouseOver={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
        onClick={hover ? handleRemove : undefined}
      >
        {!hover && <CheckCircleOutlineIcon color="secondary" />}
        {hover && <RemoveIcon color="error" />}
      </IconButton>
    );
  }
  return (
    <IconButton onClick={handleAdd}>
      <AddCircleOutlineIcon />
    </IconButton>
  );
};

export default PrefillButton;
