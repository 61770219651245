import React, { useCallback } from 'react';
import { Panel } from 'reactflow';
import { Box, Chip } from '@mui/material';
import Params from '../../Params';
import { relatedEntityTypeChoices, relatedRolesChoices, sortChoices } from './FilterPane';

const renderChoice = (choices: {
  id: string;
  name: string;
}[]) => (value: string) => choices.find(({ id }) => id === value)?.name ?? '-';

const AppliedFiltersPane = ({
  params,
  setParams,
}: {
  params: Params;
  setParams: React.Dispatch<React.SetStateAction<Params>>;
}) => {
  const removeSort = useCallback(() => {
    setParams((currentParams) => ({
      ...currentParams,
      flowSort: undefined,
    }));
  }, [setParams]);

  const removeRelatedEntityType = useCallback(() => {
    setParams((currentParams) => ({
      ...currentParams,
      flowRelatedEntityType: undefined,
    }));
  }, [setParams]);

  const removeRelatedRoles = useCallback(() => {
    setParams((currentParams) => ({
      ...currentParams,
      flowRelatedRoles: undefined,
    }));
  }, [setParams]);

  return (
    <Panel position="top-left">
      <Box display="flex" gap={4}>
        {params.flowSort && (
          <Chip
            onDelete={removeSort}
            label={`Sort: ${renderChoice(sortChoices)(params.flowSort)}`}
          />
        )}
        {params.flowRelatedEntityType && (
          <Chip
            onDelete={removeRelatedEntityType}
            label={`Entity type: ${renderChoice(relatedEntityTypeChoices)(params.flowRelatedEntityType)}`}
          />
        )}
        {params.flowRelatedRoles && (
          <Chip
            onDelete={removeRelatedRoles}
            label={`Direction: ${renderChoice(relatedRolesChoices)(params.flowRelatedRoles)}`}
          />
        )}
      </Box>
    </Panel>
  );
};

export default AppliedFiltersPane;
