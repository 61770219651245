import React, { useState, useCallback } from 'react';
import {
  useInput,
  SelectArrayInputProps,
  InputHelperText,
  ChipFieldProps,
  ChoicesProps,
} from 'react-admin';
import {
  MenuItem,
  FormControl,
  Select,
  Box,
  FormHelperText,
  InputLabel,
  Chip,
  IconButton,
  InputAdornment,
} from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';

const ChipField = ({ ...props }: ChipFieldProps & ChoicesProps) => {
  const field = props.choices?.find((choice) => choice?.id === props?.record);
  if (!field) return null;
  return <Chip size="small" variant="filled" label={field[props?.source!]} />;
};

const SelectArrayInput = ({
  variant = 'filled',
  source = '', ...props
}: SelectArrayInputProps & {
  popups?: React.ReactElement[];
  onCreate?: React.MouseEventHandler;
}) => {
  const [open, setOpen] = useState(false);

  const {
    field,
    fieldState: { isTouched, error },
    formState: { isSubmitted, isValid },
  } = useInput({ source, defaultValue: [], validate: props.validate });

  const popups = [
    <ArrowDropDownIcon
      key="dropDown"
      sx={{ transform: open ? 'rotate(180deg)' : 'none' }}
    />,
    ...(props.onCreate ? [<IconButton key="create" onClick={props?.onCreate}><AddCircleOutlineIcon /></IconButton>] : []),
    ...(props.popups! ?? [])];

  const handelFocusChange = useCallback(() => setOpen(!open), [setOpen, open]);

  // eslint-disable-next-line react/no-unstable-nested-components
  const IconComponent = () => (
    <InputAdornment position="end">
      {popups}
    </InputAdornment>
  );

  return (
    <FormControl margin="dense" fullWidth={props.fullWidth}>
      <InputLabel variant={variant} margin="dense">{props.label}</InputLabel>
      <Select<{ id: string; name: string }[]>
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...field}
        sx={props.sx}
        disabled={props.disabled}
        size="small"
        variant={variant}
        multiple
        label={props.label}
        multiline={false}
        fullWidth={props.fullWidth}
        onFocus={handelFocusChange}
        IconComponent={IconComponent}
        error={(isTouched || isSubmitted) && !isValid}
        renderValue={(selected) => (
          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
            {selected.map((value) => (
              <ChipField
                key={value.id}
                source="name"
                record={value}
                choices={props?.choices}
              />
            ))}
          </Box>
        )}
      >
        {props.choices?.map((choice) => (
          <MenuItem key={choice.id} value={choice.id}>{choice.name}</MenuItem>
        ))}
      </Select>
      <FormHelperText error={isSubmitted || (isTouched && !isValid)}>
        <InputHelperText
          touched={isTouched || isSubmitted}
          error={error?.message}
          helperText={props.helperText}
        />
      </FormHelperText>
    </FormControl>
  );
};

export default SelectArrayInput;
