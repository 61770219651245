import React, { useCallback, useEffect } from 'react';
import { useFieldArray } from 'react-hook-form';
import {
  AutocompleteInput,
  ReferenceInput,
  required,
  SelectInput,
} from 'react-admin';

import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import AddBox from '@mui/icons-material/AddBox';
import DeleteIcon from '@mui/icons-material/Delete';
import RemoveCircleOutline from '@mui/icons-material/RemoveCircleOutline';
import useMediaQuery from '@mui/material/useMediaQuery';
import theme from '../theme';

type TransactionConfigurationFieldValues = {
  source: {
    or: {
      and: {
        inclusion: 'included' | 'excluded';
        transactionTypeId?: string;
      }[];
    }[];
  };
};

const getInitialValue = () => ({
  inclusion: 'included' as const,
});

const TransactionTypeConfigurationAndBlock = ({
  source,
  indexOr,
}: {
  source: string;
  indexOr: number;
}) => {
  const {
    fields,
    append,
    remove,
  } = useFieldArray<TransactionConfigurationFieldValues, `source.or.${number}.and`>({
    name: `${source}.or.${indexOr}.and` as 'source.or.0.and',
  });

  useEffect(() => {
    if (fields.length === 0) {
      append(getInitialValue());
    }
  }, [append, fields.length]);

  const addAnd = useCallback(() => {
    append(getInitialValue());
  }, [append]);

  const removeAnd = (indexAnd: number) => () => {
    remove(indexAnd);
  };

  return (
    <Box display="flex" bgcolor="background.grey" padding={2} gap={2} flexWrap="wrap">
      {fields.map((andItem, indexAnd) => (
        <Box key={andItem.id} display="flex" gap={2} alignItems="baseline" flexWrap="wrap">
          <SelectInput
            source={`${source}.or.${indexOr}.and.${indexAnd}.inclusion`}
            sx={{ minWidth: 75 }}
            choices={[
              { id: 'included', name: 'is' },
              { id: 'excluded', name: 'is not' },
            ]}
            validate={required()}
            helperText={false}
          />
          <ReferenceInput
            source={`${source}.or.${indexOr}.and.${indexAnd}.transactionTypeId`}
            reference="transaction-types"
            filter={{ active: true }}
            perPage={50}
          >
            <AutocompleteInput
              sx={{ display: 'flex', translate: '0 1px' }}
              filterSelectedOptions={false}
              filterToQuery={(searchText) => ({ name: searchText })}
              optionText={(item: { name: string }) => item.name}
              helperText={false}
              validate={required()}
            />
          </ReferenceInput>
          {(fields.length > 1) && (
            <IconButton onClick={removeAnd(indexAnd)} size="small" sx={{ margin: '-5px' }}>
              <RemoveCircleOutline />
            </IconButton>
          )}
          {(indexAnd !== fields.length - 1) ? (
            <Typography>and</Typography>
          ) : (
            <Button
              startIcon={<AddBox color="primary" />}
              onClick={addAnd}
              size="small"
            >
              and
            </Button>
          )}
        </Box>
      ))}
    </Box>
  );
};

const TransactionTypeConfigurationInput = ({
  source,
}: {
  source: string;
}) => {
  const {
    fields,
    append,
    remove,
  } = useFieldArray<TransactionConfigurationFieldValues, 'source.or'>({
    name: `${source}.or` as 'source.or',
  });

  useEffect(() => {
    if (fields.length === 0) {
      append({ and: [] });
    }
  }, [append, fields.length]);

  const addOr = useCallback(() => {
    append({ and: [] });
  }, [append]);

  const removeOr = (indexOr: number) => () => {
    remove(indexOr);
  };

  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Box display="flex" gap={2} flexDirection="column" alignItems="flex-start">
      {fields.map((orItem, indexOr) => (
        <Box key={orItem.id} display="flex" alignItems="baseline" flexDirection={isSmallScreen ? 'column' : 'row'}>
          {indexOr !== 0 ? (
            <Box width={12} marginRight={2}>
              <Typography>or</Typography>
            </Box>
          ) : (
            <Box width={12} marginRight={2} />
          )}
          <TransactionTypeConfigurationAndBlock source={source} indexOr={indexOr} />
          {fields.length > 1 && (
            <IconButton onClick={removeOr(indexOr)} size="small">
              <DeleteIcon />
            </IconButton>
          )}
        </Box>
      ))}
      <Button
        startIcon={<AddBox color="primary" />}
        onClick={addOr}
        size="small"
      >
        or
      </Button>
    </Box>
  );
};

export default TransactionTypeConfigurationInput;
