import React from 'react';
import {
  Datagrid,
  ReferenceManyField,
  TextField,
  usePermissions,
  Identifier,
  useRecordContext,
} from 'react-admin';
import { Card, CardContent, CardHeader } from '@mui/material';

import ReferenceEntityField from '../../customFields/ReferenceEntityField';
import hasAccess from '../../utilities/hasAccess';
import CreateCustomConnectionButton from './CreateCustomConnectionButton';
import Pagination from '../layout/Pagination';
import NoResults from '../layout/NoResults';
import RemoveCustomConnectionButton from './RemoveCustomConnectionButton';
import { EntityTypes } from '../../constants/entityTypes';
import actions, { Actions } from '../../constants/actions';

type Connection = {
  id: Identifier;
  from: {
    id: Identifier;
    type: EntityTypes;
  };
  to: {
    id: Identifier;
    type: EntityTypes;
  };
  type: 'custom';
  createdAt: string;
  connectionInfo: unknown;
}

const ConnectedEntityField = ({
  parentId,
  label,
}: {
  parentId: Identifier;
  label: string;
}) => {
  const record = useRecordContext<Connection>();

  if (!record) return null;

  let source;
  if (record.from.id === parentId) source = 'to';
  else if (record.to.id === parentId) source = 'from';

  if (!source) return null;

  return (
    <ReferenceEntityField source={source} label={label} />
  );
};

const RelatedCustomConnections = () => {
  const { permissions } = usePermissions<Actions[]>();
  const record = useRecordContext();

  if (!hasAccess(permissions, actions.CONNECTION_LIST)) return null;
  if (!record) return null;

  return (
    <Card variant="outlined">
      <CardHeader
        title="Custom connections"
        action={hasAccess(permissions, actions.CONNECTION_CREATE) && (
          <CreateCustomConnectionButton />
        )}
      />
      <CardContent>
        <ReferenceManyField
          reference="connections"
          target="entity"
          filter={{ type: 'custom' }}
          perPage={15}
          pagination={<Pagination disableEmptyText />}
        >
          <Datagrid bulkActionButtons={false} empty={<NoResults />}>
            <ConnectedEntityField parentId={record.id} label="Connected entity" />
            <TextField sortable={false} source="connectionInfo.description" label="Description" />
            <RemoveCustomConnectionButton />
          </Datagrid>
        </ReferenceManyField>
      </CardContent>
    </Card>
  );
};

export default RelatedCustomConnections;
