import { ValueOf } from '../utilities/types';

const formRelatedEntities = {
  ALL: 'all',
  SELF: 'self',
  RELATED_ACCOUNTS: 'related-accounts',
  RELATED_PRODUCTS: 'related-products',
} as const;

export type FormRelatedEntities = ValueOf<typeof formRelatedEntities>;

export default formRelatedEntities;
