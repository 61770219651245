import { ValueOf } from '../utilities/types';

const reportStatuses = {
  LOADING: 'loading',
  READY: 'ready',
  FAILED: 'failed',
};

export const reportStatusesChoices = {
  LOADING: { id: 'loading', name: 'Loading' },
  READY: { id: 'ready', name: 'Ready' },
  FAILED: { id: 'failed', name: 'Failed' },
};

export type ReportStatuses = ValueOf<typeof reportStatuses>;

export default reportStatuses;
