import React from 'react';

import {
  CreateBase,
  SaveButton,
  SimpleForm,
  Toolbar,
  useNotify,
  useRefresh,
} from 'react-admin';

import {
  Dialog,
  Box,
  Button,
  DialogTitle,
  Typography,
  IconButton,
} from '@mui/material';

import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add';

import { upperFirst } from 'lodash';

import { useFormContext } from 'react-hook-form';
import useDialogStatus from '../../../hooks/useDialogStatus';

const CustomToolbar = () => {
  const { formState: { isValid } } = useFormContext();

  return (
    <Toolbar sx={{ backgroundColor: 'transparent', display: 'flex', justifyContent: 'flex-end' }}>
      <SaveButton
        disabled={!isValid}
        icon={undefined}
      />
    </Toolbar>
  );
};

const CreateDialog = ({ title, children }: {title: string; children: React.ReactElement }) => {
  const { open, openDialog, closeDialog } = useDialogStatus();
  const notify = useNotify();
  const refresh = useRefresh();

  const onSuccess = () => {
    notify(`${upperFirst(title)} created`);
    closeDialog();
    refresh();
  };

  return (
    <Box>
      <Button startIcon={<AddIcon />} size="small" onClick={openDialog}>Create</Button>
      <Dialog open={open} onClose={closeDialog} fullWidth>
        <DialogTitle>
          <Box display="flex" alignItems="baseline" justifyContent="space-between">
            <Typography variant="largeTitle">{`Create new ${title}`}</Typography>
            <IconButton onClick={closeDialog}>
              <CloseIcon color="primary" />
            </IconButton>
          </Box>
        </DialogTitle>
        <CreateBase mutationOptions={{ onSuccess }}>
          <SimpleForm mode="all" toolbar={<CustomToolbar />}>
            {children}
          </SimpleForm>
        </CreateBase>
      </Dialog>
    </Box>
  );
};

export default CreateDialog;
