import React from 'react';

import {
  DateField,
  Labeled,
  TextField,
  usePermissions,
} from 'react-admin';

import {
  Box,
  Card,
  CardHeader,
  Grid,
  CardContent,
  Typography,
  Divider,
} from '@mui/material';

import EditField from '../../../customFields/EditField';
import RelatedIndividualsList from '../../../customFields/RelatedIndividualsList';
import hasAccess from '../../../utilities/hasAccess';
import LatestRelatedRequests from '../../../customFields/LatestRelatedRequests';
import LatestRelatedCases from '../../../customFields/LatestRelatedCases';
import LatestRelatedPings from '../../../customFields/LatestRelatedPings';
import RelatedConductors from '../../../customFields/RelatedConductors';
import PerformKyc from '../../../customFields/PerformKyc';
import EditSelectInput from '../../../customFields/EditSelectInput';

import countries from '../../../constants/countries';
import { Actions } from '../../../constants/actions';

import RiskManagement from '../../riskManagment';
import RelatedCustomConnections from '../../connection/RelatedCustomConnections';
import EditArrayInput from '../../../customFields/EditArrayInput';

const Information = ({ setKycTab }: { setKycTab: React.Dispatch<React.SetStateAction<string>>}) => {
  const { permissions } = usePermissions<Actions[]>();

  return (
    <Grid container spacing={6}>
      <Grid item xs={12} lg={5}>
        <Card variant="outlined">
          <CardHeader
            title="General"
            action={
              hasAccess(permissions, 'RequestCreate') && (<PerformKyc />)
            }
          />
          <Divider />
          <CardContent>
            <Grid container spacing={2}>
              <Grid item xs={12} md={12}>
                <Box flexDirection="column" display="flex">
                  <Typography variant="smallTitle">ID</Typography>
                  <TextField source="id" />
                </Box>
              </Grid>
              <Grid item xs={12} md={12}>
                <Box>
                  <Labeled label="created At">
                    <DateField source="createdAt" />
                  </Labeled>
                </Box>
              </Grid>
              <Grid item xs={12} md={12}>
                <EditArrayInput showEdit={hasAccess(permissions, 'BusinessUpdate')} source="customIds" label="Custom IDs" />
              </Grid>
              <Grid item xs={12} md={12}>
                <EditField showEdit={hasAccess(permissions, 'BusinessUpdate')} source="name" label="Name" />
              </Grid>
              <Grid item xs={12} md={12}>
                <EditField showEdit={hasAccess(permissions, 'BusinessUpdate')} source="registrationNumber" label="Registration Number" />
              </Grid>
              <Grid item xs={12} md={12}>
                <EditSelectInput choices={countries} showEdit={hasAccess(permissions, 'BusinessUpdate')} source="residence" label="Registration Country" />
              </Grid>
              <Grid item xs={12} md={12}>
                <EditField showEdit={hasAccess(permissions, 'BusinessUpdate')} source="email" label="Email" />
              </Grid>
              <Grid item xs={12} md={12}>
                <EditField showEdit={hasAccess(permissions, 'BusinessUpdate')} source="phoneNumber" label="Phone number" />
              </Grid>
              <Grid item xs={12} md={12}>
                <EditField showEdit={hasAccess(permissions, 'BusinessUpdate')} type="date" source="kycApprovedAt" label="KYC Approved" />
              </Grid>
              <Grid item xs={12} md={12}>
                <RiskManagement />
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12} lg={7} container direction="column">
        <Grid item>
          <Card variant="outlined">
            <CardHeader title="Board members and beneficial owners" />
            <Divider />
            <CardContent>
              <RelatedIndividualsList setKycTab={setKycTab} />
            </CardContent>
          </Card>
        </Grid>
        <Grid item>
          <RelatedCustomConnections />
        </Grid>
      </Grid>
      <RelatedConductors />
      <LatestRelatedRequests />
      <LatestRelatedCases />
      <LatestRelatedPings />
    </Grid>
  );
};

export default Information;
