import React from 'react';

import { isArray } from 'lodash';

import {
  ArrayField,
  Datagrid,
  DateField,
  RecordContextProvider,
  TextField,
  SelectField,
} from 'react-admin';

import {
  Box,
  Card,
  CardHeader,
  Divider,
  Grid,
  Typography,
} from '@mui/material';

import useEntityData from '../../../../hooks/useEntityData';

import actions from '../../../../constants/actions';
import { boldDataGridStyle } from '../../../../constants/style/datagridStyles';

import NoResults from '../../../layout/NoResults';
import PurchaseButton from '../../../layout/button/PurchaseButton';

import EntityData from './EntityData';

import InfoPopover from '../../../layout/InfoPopover';
import { LegalScore } from '../../../../utilities/schemas/legalScore';

const CriminalRecordBody = ({
  data,
  handlePurchase,
  isLoading,
}: {
  data: LegalScore | undefined;
  handlePurchase: () => void;
  isLoading: boolean;
}) => (
  <RecordContextProvider value={data}>
    <Card variant="outlined">
      <CardHeader
        title="Criminal Record"
        subheader={(
          <Box display="flex" alignItems="baseline">
            <Typography>Latest update:</Typography>
            <DateField sx={{ marginLeft: 1, fontWeight: 'bold' }} source="createdAt" emptyText="-" />
          </Box>
        )}
        action={(
          <PurchaseButton
            loading={isLoading}
            label="Get Criminal Record"
            onClick={handlePurchase}
            empty={!isArray(data?.persons)}
            action={actions.INDIVIDUAL_CREATE_LEGAL_SCORE}
          />
        )}
      />
      <Divider />
      <ArrayField source="hits">
        <Datagrid empty={<NoResults variant="h6" />} sx={{ ...boldDataGridStyle, width: '100%' }} bulkActionButtons={false}>
          <TextField source="personalId" label="Ssn" />
          <SelectField
            emptyText="-"
            record={data}
            source="criminalRecord"
            choices={[
              { id: false, name: 'No' },
              { id: true, name: 'Yes' },
            ]}
          />
          <TextField
            emptyText="-"
            source="score"
            label={(
              <Box display="flex" alignItems="center">
                <Typography sx={{ fontWeight: 600 }}>Score</Typography>
                <InfoPopover
                  title="Legal score"
                  content="Specifies the score based on a pre-configured criminal record library.  A score in the range of 1-3 indicates a criminal record with lighter crimes, 4-9 suggests that the user needs to review the records, and >10 indicate a heavier criminal record."
                />
              </Box>
            )}
          />
        </Datagrid>
      </ArrayField>
    </Card>
  </RecordContextProvider>
);

const CriminalRecord = () => {
  const {
    data,
    handlePurchase,
    isLoading,
  } = useEntityData<LegalScore>({
    source: 'legal-score',
    getAction: actions.INDIVIDUAL_GET_LEGAL_SCORE,
  });

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} md={12}>
        <EntityData />
      </Grid>
      <Grid item xs={12} md={12}>
        <CriminalRecordBody data={data} handlePurchase={handlePurchase} isLoading={isLoading} />
      </Grid>
    </Grid>
  );
};

export default CriminalRecord;
