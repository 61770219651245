import React from 'react';

import { TextField, useRecordContext } from 'react-admin';

import { get } from 'react-hook-form';

import Answer from '../Answer';
import conflictFields from '../../../../constants/conflictFields';

const ValueField = () => {
  const record = useRecordContext();

  const value = get(record, 'value');
  const sx = get(record, 'sx', {});

  return <TextField record={{ value }} sx={sx} source="value" emptyText="-" fontWeight="bold" />;
};

const Activities = ({ extraParameters }: {
  extraParameters?: {
    label?: string;
  };
}) => (
  <Answer
    label={`${extraParameters?.label ?? 'Activities of the Business'}`}
    source="activities"
    datapoint={conflictFields.ACTIVITIES}
  >
    <ValueField />
  </Answer>
);

Activities.ValueField = ValueField;

export default Activities;
