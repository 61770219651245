import React, { useMemo } from 'react';
import { get, isNumber } from 'lodash';

import Typography from '@mui/material/Typography';
import Chip from '@mui/material/Chip';
import Tooltip from '@mui/material/Tooltip';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';

import {
  NumberField,
  useRecordContext,
  useGetOne,
  RecordContextProvider,
  useStore,
} from 'react-admin';

import conflictFields from '../../constants/conflictFields';
import { EntityTypes } from '../../constants/entityTypes';
import theme from '../../theme';
import { TransactionMonitoringThresholds } from '../../utilities/schemas/datapoints/transactionMonitoringThresholds';

const getIconFromDeviation = (deviation: number) => {
  if (deviation === 0) return undefined;
  if (deviation > 0) return <ArrowUpwardIcon color="info.dark" />;
  return <ArrowDownwardIcon color="info.dark" />;
};

const formatInfinity = (deviation: number) => {
  if (deviation === Infinity) {
    return '+∞';
  } if (deviation === -Infinity) {
    return '-∞';
  }
  return `${deviation.toString()}%`;
};

const CompareTransactionBehaviorField = ({
  source,
  label,
  comparedSource,
  options,
  showExpectedBehavior,
  comparedTransform,
  entityId,
  entityType,
  currency,
}: {
  source: string;
  label?: string;
  comparedSource: string;
  options?: {};
  showExpectedBehavior: boolean;
  comparedTransform?: (value: number) => number;
  entityId: string;
  entityType: EntityTypes;
  textAlign: string;
  currency: string;
}) => {
  const record = useRecordContext();

  const [
    overriddenExpectedBehaviours,
  ] = useStore(`transactionOverriddenExpectedBehaviours-${entityId}`, new Map<string | undefined, TransactionMonitoringThresholds | undefined>());

  const transactionTypeConfiguration = get(record, 'typeConfiguration');
  const identificationInfo = transactionTypeConfiguration ? {
    transactionTypes: transactionTypeConfiguration,
  } : undefined;
  const { data: expectedTMThresholdDatapoint } = useGetOne<{
    id: string;
    status: 'resolved' | 'conflict' | 'empty';
    selectedValue: TransactionMonitoringThresholds | undefined;
  }>('data-points/status', {
    id: `${conflictFields.TRANSACTION_MONITORING_THRESHOLD}/${entityId}/${entityType}${identificationInfo ? `/${JSON.stringify(identificationInfo)}` : ''}`,
  }, {
    enabled: showExpectedBehavior,
  });
  const actualValue = get(record, source);

  const {
    expectedInfo,
    deviation,
  } = useMemo(() => {
    const typeConfigurationKey = transactionTypeConfiguration
      ? JSON.stringify(transactionTypeConfiguration) : undefined;
    const expectedDatapointValue = overriddenExpectedBehaviours.get(typeConfigurationKey)
      ?? expectedTMThresholdDatapoint?.selectedValue;
    if (
      !expectedDatapointValue
      || expectedDatapointValue.currency !== currency
    ) return {};

    const { incoming, outgoing } = expectedDatapointValue;
    const incomingVolume = incoming?.monthlyVolume;
    const outgoingVolume = outgoing?.monthlyVolume;

    const expandedExpectedValues = {
      ...expectedDatapointValue,
      netMonthlyVolume: isNumber(incomingVolume) && isNumber(outgoingVolume) ? (
        incomingVolume - outgoingVolume
      ) : undefined,
    };

    const rawExpectedValue = get(expandedExpectedValues, comparedSource);
    const expectedValue = isNumber(rawExpectedValue) && comparedTransform
      ? comparedTransform(rawExpectedValue) : rawExpectedValue;

    const percentage = isNumber(expectedValue) && isNumber(actualValue)
      ? Math.round(((actualValue - expectedValue) * 100) / expectedValue)
      : undefined;

    return {
      expectedInfo: expandedExpectedValues,
      deviation: percentage,
    };
  }, [
    overriddenExpectedBehaviours,
    transactionTypeConfiguration,
    expectedTMThresholdDatapoint?.selectedValue,
    currency,
    comparedSource,
    comparedTransform,
    actualValue,
  ]);

  const actualValueNode = (
    <Typography textAlign="right">
      <NumberField
        label={label}
        source={source}
        options={options}
      />
    </Typography>
  );

  return showExpectedBehavior ? (
    <>
      <Tooltip title="Actual">
        {actualValueNode}
      </Tooltip>
      <RecordContextProvider value={expectedInfo ?? {}}>
        <Tooltip title="Expected">
          <Typography sx={{ color: 'grey', fontStyle: 'italic' }} textAlign="right">
            <NumberField
              source={comparedSource}
              transform={comparedTransform}
              options={options}
              emptyText="-"
            />
          </Typography>
        </Tooltip>
        <Tooltip title="Deviation">
          <Typography>
            {isNumber(deviation) ? (
              <Chip
                component="span"
                label={formatInfinity(deviation)}
                size="small"
                color="default"
                sx={{ color: theme.palette.info.dark }}
                icon={getIconFromDeviation(deviation)}
              />
            ) : '-'}
          </Typography>
        </Tooltip>
      </RecordContextProvider>
    </>
  ) : actualValueNode;
};

export default CompareTransactionBehaviorField;
