import { useState } from 'react';
import { useLocation } from 'react-router-dom';

type RuleVersionState = {
  state?: {
    record?: {
      version?: string;
    };
  };
}

const useRuleVersion = () => {
  const {
    state,
  } = useLocation() as RuleVersionState;

  const [version, setVersion] = useState<string>(state?.record?.version ?? 'draft');

  const changeVersion = (v: string) => {
    if (v) setVersion(v);
  };

  return { version, isDraft: version === 'draft', changeVersion };
};

export default useRuleVersion;
