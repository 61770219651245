import React, { useEffect } from 'react';

import {
  Loading,
  maxLength,
  NumberInput,
  required,
  SelectInput,
  TextInput,
  useGetOne,
} from 'react-admin';

import { Box, Grid } from '@mui/material';
import { useFormContext, useWatch } from 'react-hook-form';

export type CarValues = {
  id?: string;
  item_type: 'FORDB';
  registration_number?: string;
  estimated_value?: number;
  description: string;
}

const Car = () => {
  const source = 'goods_services.0.item';
  const defaultCarId = useWatch({ name: `${source}.id` });
  const { data, isLoading } = useGetOne('cars', { id: defaultCarId }, { enabled: !!defaultCarId });
  const { resetField } = useFormContext();

  useEffect(() => {
    if (data) {
      resetField(source, {
        defaultValue: {
          name: data.name,
          incorporation_legal_form: data.incorporationLegalForm,
          incorporation_number: data.swedishOrganizationNumber,
        },
      });
    }
  }, [data, resetField, source]);

  if (isLoading) {
    return (
      <Box width="100%" height="100%" sx={{ transform: 'translate(0%, 100%)' }}>
        <Loading />
      </Box>
    );
  }
  return (
    <Grid container spacing={6}>
      <Grid item xs={12} sm={6} md={3}>
        <SelectInput choices={[{ id: 'FORDB', name: 'Car' }]} fullWidth source={`${source}.item_type`} label="Type" validate={required()} />
      </Grid>
      <Grid item xs={12} sm={6} md={3}>
        <TextInput fullWidth source={`${source}.registration_number`} label="Registration number" />
      </Grid>
      <Grid item xs={12} sm={6} md={3}>
        <NumberInput fullWidth source={`${source}.estimated_value`} label="Valuation" />
      </Grid>
      <Grid item xs={12} sm={12} md={12}>
        <TextInput fullWidth multiline minRows={3} label="Description of the item" source={`${source}.description`} validate={[required(), maxLength(4000)]} />
      </Grid>
    </Grid>
  );
};

export default Car;
