import React, {
  useCallback, useMemo, useRef, useState,
} from 'react';

import {
  Box,
  Button,
  ButtonProps,
  Checkbox,
  MenuItem,
  Select,
  SelectChangeEvent,
} from '@mui/material';

import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import RefreshIcon from '@mui/icons-material/Refresh';
import ClearIcon from '@mui/icons-material/Clear';

type SelectInputType = {
  value: any;
  setValue: (x: any) => void;
  choices: {
    id: string;
    name: string;
    custom?: React.ReactElement;
  }[];
  label?: string;
  hasMore?: boolean;
  handleLoadMore?: () => void;
  multiple?: boolean;
  color?: ButtonProps['color'];
  size?: ButtonProps['size'];
  sx?: ButtonProps['sx'];
  resettable?: boolean;
}

const SelectInputButton = ({
  value,
  setValue,
  choices,
  label = 'See',
  multiple = false,
  sx = {},
  color = 'primary',
  size = 'small',
  handleLoadMore,
  hasMore,
  resettable = false,
}: SelectInputType) => {
  const [open, setOpen] = useState(false);
  const ref = useRef(null);

  const handelChangePeriod = useCallback((event: SelectChangeEvent) => {
    setValue(event.target.value as string);
  }, [setValue]);

  const handelChangeOpen = useCallback(() => {
    setOpen((prev) => !prev);
  }, []);

  const CurrentChoice = useMemo(
    () => choices.find((choice) => choice.id === value),
    [choices, value],
  );

  return (
    <Box>
      <Box ref={ref} height="100%">
        <Button
          sx={{ height: 'inherit', ...sx }}
          endIcon={!open ? <ArrowDropDownIcon /> : <ArrowDropUpIcon />}
          variant="text"
          color={color}
          onClick={handelChangeOpen}
          size={size}
          fullWidth
        >
          {CurrentChoice?.name ?? label}
        </Button>
      </Box>
      <Select
        MenuProps={{
          anchorEl: ref.current,
        }}
        style={{ display: 'none' }}
        open={open}
        onClose={handelChangeOpen}
        onOpen={handelChangeOpen}
        multiple={multiple}
        value={value ?? (multiple ? [] : '')}
        onChange={handelChangePeriod}
        fullWidth
        color="primary"
      >
        {choices.map((choice) => (
          <MenuItem
            key={choice.id}
            value={choice.id}
          >
            {multiple && (
              <Checkbox size="small" checked={value.includes(choice.id)} />
            )}
            {!!choice.custom && choice.custom}
            {!choice.custom && choice.name}
          </MenuItem>
        ))}
        {(resettable && value) && (
          <Button
            onClick={() => setValue(undefined)}
            fullWidth
            startIcon={<ClearIcon color="error" />}
          >
            Clear Field
          </Button>
        )}
        {(hasMore && handleLoadMore) && (
          <Button
            onClickCapture={(e) => {
              // prevent the select to be closed when button is clicked
              e.stopPropagation();
              handleLoadMore();
            }}
            fullWidth
            startIcon={<RefreshIcon />}
          >
            Load more
          </Button>
        )}
      </Select>
    </Box>
  );
};

export default SelectInputButton;
