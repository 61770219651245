import React from 'react';
import {
  Create,
  SimpleForm,
  SelectInput,
  FormDataConsumer,
  required,
  Toolbar,
  SaveButton,
} from 'react-admin';
import { useFormContext } from 'react-hook-form';

import SendIcon from '@mui/icons-material/Send';
import cleaner from '../../utilities/dataProvider/cleaner';
import { reportTypesChoices, SelectableReportTypes } from '../../constants/reportTypes';
import reportCreateMap from '../../constants/reportFeatures';

const CustomToolbar = () => {
  const { formState: { isValid } } = useFormContext();

  return (
    <Toolbar>
      <SaveButton
        label="Create"
        icon={<SendIcon />}
        disabled={!isValid}
      />
    </Toolbar>
  );
};

const ReportCreate = () => (
  <Create transform={cleaner}>
    <SimpleForm mode="all" toolbar={<CustomToolbar />}>
      <SelectInput
        source="type"
        label="Report Type"
        choices={reportTypesChoices}
        validate={required()}
      />
      <FormDataConsumer<{ type?: SelectableReportTypes }>>
        {({ formData }) => formData?.type && reportCreateMap[formData.type]()}
      </FormDataConsumer>
    </SimpleForm>
  </Create>
);

export default ReportCreate;
