import React from 'react';

import {
  DateField,
  RaRecord,
  ReferenceInput,
  required,
  SelectInput,
} from 'react-admin';

import {
  Grid,
  Box,
  Typography,
} from '@mui/material';

const Custom = (record: RaRecord) => (
  <Box display="flex">
    <Typography>
      {`Version ${record?.number}, \xa0`}
    </Typography>
    <DateField record={record} source="date" showTime />
    <Typography>
      {`,\xa0 ${record?.description}`}
    </Typography>
  </Box>
);

const PingTestOverview = () => (
  <Grid container>
    <Grid item xs={12} md={12}>
      <Box display="flex" height="fit-content" gap="2%" alignItems="center" flexWrap="wrap">
        <ReferenceInput
          source="report.ruleVersion"
          reference="rule-version"
          filter={{ isTestVersion: true }}
        >
          <SelectInput optionText={Custom} validate={[required()]} />
        </ReferenceInput>
      </Box>
    </Grid>
  </Grid>
);

export default PingTestOverview;
