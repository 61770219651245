import React from 'react';
import {
  ReferenceInput,
  AutocompleteInput,
  required,
} from 'react-admin';

import { Box, styled } from '@mui/material';

import { EntityTypes, resourceFromEntityTypes } from '../constants/entityTypes';

const Root = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'left',
  alignItems: 'center',
});

const searchQuery = (search: string) => ({ id: search });

const ReferenceEntityInput = ({ entityType, source, role }: {
  entityType: EntityTypes;
  source: string;
  role: string;
}) => {
  if (!entityType) return null;

  return (
    <Root>
      <ReferenceInput
        label="id"
        source={source}
        reference={resourceFromEntityTypes[entityType]}
        filterToQuery={searchQuery}
        validate={required()}
      >
        <AutocompleteInput
          label={`Select ${role ?? entityType.toLocaleLowerCase()}`}
          optionText="id"
        />
      </ReferenceInput>
    </Root>
  );
};

export default ReferenceEntityInput;
