import React, { useCallback } from 'react';

import { useRecordContext } from 'react-admin';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';

import { RemoveCircle } from '@mui/icons-material';

import { UseFieldArrayRemove } from 'react-hook-form';

import useDialogStatus from '../../../hooks/useDialogStatus';

const DeleteEntities = ({ remove, fields }: {remove: UseFieldArrayRemove; fields: Record<'key', string>[]}) => {
  const { open, openDialog, closeDialog } = useDialogStatus();

  const record = useRecordContext();

  const handleClick = useCallback(() => {
    const indexToRemove = fields.findIndex((field) => field.key === record?.key);
    remove(indexToRemove);
    closeDialog();
  }, [closeDialog, fields, record?.key, remove]);

  return (
    <>
      <Dialog open={open} onClose={closeDialog}>
        <DialogTitle>Remove entity</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to remove the entity from the ping?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeDialog}>Cancel</Button>
          <Button onClick={handleClick} variant="contained">Confirm</Button>
        </DialogActions>
      </Dialog>
      <Button color="error" startIcon={<RemoveCircle />} onClick={openDialog}>
        Remove
      </Button>
    </>
  );
};

export default DeleteEntities;
