import React from 'react';

import {
  Datagrid,
  DateField,
  Edit,
  Pagination,
  ReferenceManyField,
  SimpleForm,
  TextField,
  usePermissions,
  TabbedShowLayout,
  Labeled,
} from 'react-admin';

import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  SxProps,
} from '@mui/material';

import { Routes, Route } from 'react-router-dom';
import useCustomEditView from '../../hooks/useCustomEditView';

import ReferenceMemberField from '../../customFields/ReferenceMemberField';
import CustomToolbar from '../../customFields/CustomToolbar';

import { boldDataGridStyle } from '../../constants/style/datagridStyles';
import actions, { Actions } from '../../constants/actions';

import hasAccess from '../../utilities/hasAccess';

import IndividualBreadcrumb from './ProductBreadcrumb';

import KycForm from '../kycForm';
import KycFormView from '../kycForm/view';

import ConflictResolution from '../conflict/ConflictResolution';

import useValidateId from '../../hooks/useValidateId';

import resourceTypes from '../../constants/resourceTypes';
import { entityCleaner } from '../../utilities/dataProvider/cleaner';
import EditArrayInput from '../../customFields/EditArrayInput';
import RiskManagement from '../riskManagment';
import RelatedCustomConnections from '../connection/RelatedCustomConnections';
import LatestRelatedRequests from '../../customFields/LatestRelatedRequests';
import LatestRelatedCases from '../../customFields/LatestRelatedCases';
import LatestRelatedPings from '../../customFields/LatestRelatedPings';
import EditField from '../../customFields/EditField';
import EditSelectInput from '../../customFields/EditSelectInput';
import { productTypesChoices } from '../../constants/productTypes';
import ProductParties from '../../customFields/ProductParties';
import ProductInformationField from './productInformation/fields';
import Transaction from '../transaction';

const customEditSx = (isCustomView: boolean): SxProps => (
  isCustomView
    ? { '& .RaEdit-card': { backgroundColor: 'transparent', boxShadow: 'none' } }
    : {}
);

const ProductsEdit = () => {
  const { isCustomView } = useCustomEditView();

  const { permissions } = usePermissions<Actions[]>();

  const validatedId = useValidateId();
  if (!validatedId.isValid) return null;

  return (
    <Edit
      mutationMode="pessimistic"
      redirect={false}
      sx={customEditSx(isCustomView)}
      transform={entityCleaner}
    >
      <>
        <IndividualBreadcrumb />

        {/* Custom nested routes */}
        <Routes>
          <Route path="/form-answers/:formId/:kycFormId/:formPage">
            <Route path="" Component={KycFormView} />
          </Route>
          <Route path="conflict-resolution" Component={ConflictResolution} />
          <Route
            path="*"
            element={(
              <TabbedShowLayout
                sx={isCustomView ? { display: 'none' } : {}}
              >
                <TabbedShowLayout.Tab label="Information">
                  <Labeled label={false} sx={{ display: 'flex' }}>
                    <SimpleForm
                      mode="all"
                      toolbar={
                        <CustomToolbar sx={{ marginBottom: -6, marginLeft: -4, marginRight: -4 }} />
                      }
                      sx={{ padding: 0 }}
                      component={Box}
                    >
                      <Grid container spacing={6}>
                        <Grid item xs={12} lg={5}>
                          <Card variant="outlined">
                            <CardHeader
                              title="General"
                            />
                            <Divider />
                            <CardContent>
                              <Grid container spacing={2}>
                                <Grid item xs={12} md={12}>
                                  <Box>
                                    <Labeled label="ID">
                                      <TextField source="id" />
                                    </Labeled>
                                  </Box>
                                </Grid>
                                <Grid item xs={12} md={12}>
                                  <Box>
                                    <Labeled label="created At">
                                      <DateField source="createdAt" />
                                    </Labeled>
                                  </Box>
                                </Grid>
                                <Grid item xs={12} md={12}>
                                  <EditArrayInput showEdit={hasAccess(permissions, 'ProductUpdate')} source="customIds" label="Custom IDs" />
                                </Grid>
                                <Grid item xs={12} md={12}>
                                  <EditField showEdit={hasAccess(permissions, 'ProductUpdate')} source="productNumber" label="Product number" />
                                </Grid>
                                <Grid item xs={12} md={12}>
                                  <EditSelectInput
                                    showEdit={hasAccess(permissions, 'ProductUpdate')}
                                    source="productType"
                                    label="Product type"
                                    choices={productTypesChoices}
                                  />
                                </Grid>
                                <Grid item xs={12} md={12}>
                                  <ProductInformationField showEdit={hasAccess(permissions, 'ProductUpdate')} />
                                </Grid>
                                <Grid item xs={12} md={12}>
                                  <ProductParties showEdit={hasAccess(permissions, 'ProductUpdate')} />
                                </Grid>
                                <Grid item xs={12} md={12}>
                                  <EditField showEdit={hasAccess(permissions, 'ProductUpdate')} type="date" source="kycApprovedAt" label="KYC Approved" />
                                </Grid>
                                <Grid item xs={12} md={12}>
                                  <RiskManagement />
                                </Grid>
                              </Grid>
                            </CardContent>
                          </Card>
                        </Grid>
                        <Grid item xs={12} lg={7} container direction="column">
                          <Grid item>
                            <RelatedCustomConnections />
                          </Grid>
                        </Grid>
                        <LatestRelatedRequests />
                        <LatestRelatedCases />
                        <LatestRelatedPings />
                      </Grid>
                    </SimpleForm>
                  </Labeled>
                </TabbedShowLayout.Tab>
                <TabbedShowLayout.Tab label="KYC Form" path="form-answers">
                  <KycForm />
                </TabbedShowLayout.Tab>
                {hasAccess(permissions, actions.REQUEST_GET) && (
                  <TabbedShowLayout.Tab label="Transactions">
                    <Transaction />
                  </TabbedShowLayout.Tab>
                )}
                {hasAccess(permissions, actions.AUDIT_TRACK_LIST) && (
                  <TabbedShowLayout.Tab label="Audit Tracks" path="audit-tracks">
                    <Grid container>
                      <Grid item xs={12} md={12}>
                        <Card variant="outlined">
                          <ReferenceManyField
                            reference="audit-tracks"
                            target="resource.id"
                            filter={{ 'resource.type': resourceTypes.PRODUCT }}
                            sort={{ field: 'date', order: 'DESC' }}
                            pagination={<Pagination />}
                          >
                            <Datagrid sx={boldDataGridStyle} bulkActionButtons={false}>
                              <TextField sortable={false} source="id" />
                              <ReferenceMemberField label="User" />
                              <TextField sortable={false} source="description" />
                              <DateField sortable source="date" showTime />
                            </Datagrid>
                          </ReferenceManyField>
                        </Card>
                      </Grid>
                    </Grid>
                  </TabbedShowLayout.Tab>
                )}
              </TabbedShowLayout>
            )}
          />
        </Routes>
      </>
    </Edit>
  );
};

export default ProductsEdit;
