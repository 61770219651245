import { capitalize } from 'lodash';
import { ValueOf } from '../utilities/types';

const kycFormStatuses = {
  PENDING: 'pending',
  COMPELTED: 'completed',
  REJECTED: 'rejected',
  WITHDRAWN: 'withdrawn',
} as const;

export type KycFormStatuses = ValueOf<typeof kycFormStatuses>;

export const kycFormStatusesChoices = Object.values(kycFormStatuses).map((status) => ({
  id: status,
  name: capitalize(status),
}));

export default kycFormStatuses;
