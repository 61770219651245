import React from 'react';
import { AutocompleteInput, AutocompleteInputProps } from 'react-admin';

const AutoCompleteInput = ({
  source,
  label,
  choices,
  open,
  validate,
  popups,
}: AutocompleteInputProps & { popups: React.ReactElement[];
}) => (
  <AutocompleteInput
    source={source}
    fullWidth
    label={label}
    choices={choices}
    open={open}
    autoHighlight
    forcePopupIcon
    validate={validate}
    popupIcon={(
      // eslint-disable-next-line react/jsx-no-useless-fragment
      <>
        {popups}
      </>
    )}
    sx={{
      '& .MuiAutocomplete-popupIndicator': {
        '&:hover': {
          backgroundColor: 'transparent',
        },
        transform: 'none',
      },
    }}
  />
);

export default AutoCompleteInput;
