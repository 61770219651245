import React from 'react';

import { Box, Tooltip } from '@mui/material';
import WarningIcon from '@mui/icons-material/Warning';
import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

import formAnswerConflictStatus, { FormAnswerConflictStatus } from '../../constants/formAnswerConflictStatus';

const ConflictStatusIcon = ({
  status,
}: {
  status: FormAnswerConflictStatus;
}) => (
  <>
    {(status === formAnswerConflictStatus.CONFLICT) && (
      <Tooltip title="Conflict">
        <WarningIcon color="yellow" sx={{ fontSize: 24 }} />
      </Tooltip>
    )}
    {status === formAnswerConflictStatus.EMPTY && (
      <Tooltip title="Empty">
        <CancelIcon color="disabled" sx={{ fontSize: 24 }} />
      </Tooltip>
    )}
    {(
      status === formAnswerConflictStatus.RESOLVED
      || status === formAnswerConflictStatus.OTHER_VALUE_SELECTED
    ) && (
      <Tooltip title="Resolved">
        <CheckCircleIcon color="secondary" sx={{ fontSize: 24 }} />
      </Tooltip>
    )}
    {status === formAnswerConflictStatus.NO_DATAPOINT && <Box width={24} />}
  </>
);

export default ConflictStatusIcon;
