import React, { useCallback } from 'react';

import {
  useListContext,
} from 'react-admin';

import {
  Box,
  IconButton,
  InputBase,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography,
  styled,
} from '@mui/material';

import SkipPreviousIcon from '@mui/icons-material/SkipPrevious';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';

const InputStyle = styled(InputBase)(({ theme }) => ({
  '& .MuiInputBase-input': {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: theme.palette.background.paper,
    border: '1px solid #ced4da',
    fontSize: 12,
    padding: '4px 4px 4px 4px',
  },
}));

const PageOptions = ({
  setPerPage,
  perPage,
}: {
  setPerPage: (x: number) => void;
  perPage: number;
}) => {
  const handleChange = (event: SelectChangeEvent<number>) => {
    if (typeof event.target.value === 'number') {
      setPerPage(event.target.value);
    }
  };

  return (
    <Select
      size="small"
      input={<InputStyle />}
      value={perPage}
      onChange={handleChange}

    >
      <MenuItem value={5}>5</MenuItem>
      <MenuItem value={10}>10</MenuItem>
      <MenuItem value={15}>15</MenuItem>
      <MenuItem value={30}>30</MenuItem>
      <MenuItem value={50}>50</MenuItem>
    </Select>
  );
};

const CustomPagination = ({
  hasNextPage,
  hasPreviousPage,
  setPage,
  page,
  setPerPage,
  perPage,
}: {
  hasNextPage: boolean;
  hasPreviousPage: boolean;
  setPage: (x: number) => void;
  page: number;
  setPerPage: (x: number) => void;
  perPage: number;
}) => {
  const goToFirstPage = useCallback(() => setPage(1), [setPage]);
  const goBackOnePage = useCallback(() => setPage(page - 1), [setPage, page]);
  const goToNextPage = useCallback(() => setPage(page + 1), [setPage, page]);

  return (
    <Box display="flex" justifyContent="flex-end" py={1}>
      <Box display="flex" gap={2}>
        <PageOptions setPerPage={setPerPage} perPage={perPage} />
        <IconButton color="primary" disabled={!hasPreviousPage} onClick={goToFirstPage}>
          <SkipPreviousIcon />
        </IconButton>
        <IconButton color="primary" onClick={goBackOnePage} disabled={!hasPreviousPage}>
          <NavigateBeforeIcon />
        </IconButton>
        <IconButton color="primary" onClick={goToNextPage} disabled={!hasNextPage}>
          <NavigateNextIcon />
        </IconButton>
      </Box>
    </Box>
  );
};
const Pagination = ({
  disableEmptyText = true,
  emptyText = 'No results found',
  hidden = false,
}: {
  emptyText?: string;
  disableEmptyText?: boolean;
  hidden?: boolean;
  rowsPerPageOptions?: number[];
}) => {
  const {
    data,
    setPage,
    page,
    hasNextPage,
    hasPreviousPage,
    setPerPage,
    perPage,
  } = useListContext();

  if (hidden) return null;

  if (data?.length === 0) {
    if (disableEmptyText) {
      return null;
    }
    return <Typography>{emptyText}</Typography>;
  }

  return (
    <CustomPagination
      hasNextPage={hasNextPage}
      hasPreviousPage={hasPreviousPage}
      page={page}
      setPage={setPage}
      setPerPage={setPerPage}
      perPage={perPage}
    />
  );
};

export default Pagination;
