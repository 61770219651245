import { useRecordContext } from 'react-admin';
import { useRegisterBreadcrumb } from '../layout/navbar/BreadcrumbsManager';
import { IProduct } from '../../utilities/schemas/product';

const ProductBreadcrumb = () => {
  const record = useRecordContext<IProduct>();
  useRegisterBreadcrumb({
    title: record?.productNumber
       ?? record?.customIds?.at(0) ?? record?.id,
    path: `/products/${record?.id}`,
  });

  return null;
};

export default ProductBreadcrumb;
