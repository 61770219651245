import { ValueOf } from '../utilities/types';

const resourceTypes = {
  INDIVIDUAL: 'Individual',
  PING: 'Ping',
  BUSINESS: 'Business',
  MEMBER: 'Member',
  REQUEST: 'Request',
  CASE: 'Case',
  CONNECTION: 'Connection',
  CAR: 'Car',
  RULE: 'Rule',
  KYCFORM: 'KycForm',
  API_KEY: 'ApiKey',
  REPORT: 'Report',
  RISK_CLASS: 'RiskClass',
  TEAM: 'Team',
  TAG: 'Tag',
  FORM: 'Form',
  ACCOUNT_ENTITY: 'AccountEntity',
  PROCESS: 'Process',
  PRODUCT: 'Product',
  GROUP: 'Group',
  SIGNAL: 'Signal',
  DATA_POINT: 'DataPoint',
  TRANSACTION_TYPE: 'TransactionType',
} as const;

export type ResourceTypes = ValueOf<typeof resourceTypes>;

export default resourceTypes;
