import React from 'react';

import {
  ListBase,
} from 'react-admin';

import {
  Grid,
  Card,
  CardHeader,
  Divider,
} from '@mui/material';

import Pagination from '../../layout/Pagination';

import { requestTypes } from '../../../constants/requestTypes';
import TransactionList from '../Transaction';
import entityTypes, { EntityTypes } from '../../../constants/entityTypes';

const DetailedView = ({
  filter,
}: {
  filter: {
    from: string;
    to: string;
    requestType: typeof requestTypes.TRANSACTION;
    currency: string;
    entityId?: string;
    entityType?: EntityTypes;
    transactionTypeConfiguration?: string;
  };
}) => {
  const isproductLookup = filter.entityType === entityTypes.PRODUCT;
  return (
    <Grid container spacing={6}>
      <Grid item xs={12} md={12}>
        <Card variant="outlined">
          <CardHeader title="Detailed Transactions" />
          <Divider />
          <ListBase
            storeKey={false}
            filter={{
              isproductLookup,
              requestType: filter.requestType,
              'extraInformation.currency': filter.currency,
              'custom.entity': filter.entityId,
              'custom.from': filter.from,
              'custom.to': filter.to,
              'extraInformation.transactionTypes': filter.transactionTypeConfiguration,
              includeRelatedAccounts: filter.entityType === entityTypes.BUSINESS
              || filter.entityType === entityTypes.INDIVIDUAL,
            }}
            perPage={10}
            resource="requests"
            sort={{ field: 'extraInformation.date', order: 'DESC' }}
          >
            <TransactionList />
            <Pagination disableEmptyText rowsPerPageOptions={[10, 15, 20]} />
          </ListBase>
        </Card>
      </Grid>
    </Grid>
  );
};

export default DetailedView;
