import React from 'react';
import { List, Box, styled } from '@mui/material';

import SidebarNavSection from './SidebarNavSection';
import { Page } from './SidebarNavList';

const Scrollbar = styled(Box)(({ theme }) => ({
  backgroundColor: theme.sidebar.background,
  borderRight: '1px solid rgba(0, 0, 0, 0.12)',
  display: 'flex',
  flexGrow: 1,
}));

const Items = styled(Box)(({ theme }) => ({
  paddingTop: theme.spacing(2.5),
  paddingBottom: theme.spacing(2.5),
}));

const SidebarNav = ({ items, onClose }: {
  items: {
    title: string;
    pages: Page[];
  }[];
  onClose?: () => void;
}) => (
  <Scrollbar>
    <List disablePadding sx={{ width: '100%' }}>
      <Items>
        {items && items.map((item) => (
          <SidebarNavSection
            key={item.title}
            pages={item.pages}
            title={item.title}
            onClose={onClose}
          />
        ))}
      </Items>
    </List>
  </Scrollbar>
);

export default SidebarNav;
