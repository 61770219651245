import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';

import {
  DateField,
  RaRecord,
  useCreate,
  useInfiniteGetList,
  useListContext,
  useNotify,
} from 'react-admin';

import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Typography,
  Grid,
  ToggleButtonGroup,
  ToggleButton,
} from '@mui/material';

import CircleIcon from '@mui/icons-material/Circle';
import ScienceIcon from '@mui/icons-material/Science';

import RestartAltIcon from '@mui/icons-material/RestartAlt';

import { isBoolean, uniqBy } from 'lodash';
import useDialogStatus from '../../../../hooks/useDialogStatus';

import { RuleTypes } from '../../../../constants/ruleTypes';
import SelectInputButton from '../../../layout/inputFields/SelectInputButton';

const Custom = ({ vers }: { vers: RaRecord }) => (
  <Box display="flex">
    <Typography>
      {`Version ${vers?.number}, \xa0`}
    </Typography>
    <DateField record={vers} source="date" showTime />
    <Typography>
      {`,\xa0 ${vers?.description}`}
    </Typography>
  </Box>
);

const Reset = ({ disabled, ruleType }: { disabled: boolean; ruleType: RuleTypes }) => {
  const { refetch } = useListContext();

  const [isTest, setIsTest] = useState<boolean>(false);
  const [version, setVersion] = useState<string>('');
  const notify = useNotify();
  const { open, openDialog, closeDialog } = useDialogStatus();
  const [reset] = useCreate();
  const [choices, setChoices] = useState<{
    id: string;
    name: string;
    custom: React.JSX.Element;
  }[]>([]);

  const {
    data, hasNextPage, fetchNextPage,
  } = useInfiniteGetList<{ id: string; number: number }>('rule-version', { filter: { ruleType, isTestVersion: isTest } });

  const handelChangeIsTestVersion = useCallback((
    e: React.MouseEvent<HTMLElement, MouseEvent>,
    newValue: boolean,
  ) => {
    e.stopPropagation();
    if (isBoolean(newValue)) setIsTest(newValue);
  }, [setIsTest]);

  const pages = useMemo(() => data?.pages.map((item) => item.data).flat(), [data?.pages]);

  useEffect(() => {
    if (pages) {
      setChoices(() => uniqBy([
        ...(pages?.map((vers) => ({ id: vers.id, name: `Version ${vers.number}`, custom: <Custom vers={vers} /> })) ?? []),
      ], 'id'));
    }
  }, [pages]);

  const handleLoadMore = useCallback(() => {
    fetchNextPage();
  }, [fetchNextPage]);

  const handleClick = useCallback(() => {
    reset(
      'rules/reset',
      { data: { version, ruleType } },
      {
        onSuccess: () => {
          notify('Rule draft was reset', { type: 'success' });
          closeDialog();
          refetch();
        },
        onError: () => {
          notify('Rule draft could not be reset', { type: 'error' });
        },
      },
    );
  }, [closeDialog, notify, refetch, reset, ruleType, version]);

  return (
    <>
      <Button
        color="primary"
        startIcon={<RestartAltIcon />}
        onClick={openDialog}
        disabled={disabled}
      >
        Reset
      </Button>
      <Dialog onClose={closeDialog} open={open} fullWidth maxWidth="sm">
        <DialogTitle>Reset Rule Tree Draft</DialogTitle>
        <DialogContent>
          <Grid container spacing={6}>
            <Grid item xs={12} md={12}>
              <Typography>
                Resetting the rule tree draft to the selected version of the rule
                tree will remove any changes made to the current draft. Do you wish to continue?
              </Typography>
            </Grid>
            <Grid item xs={12} md={12}>
              <ToggleButtonGroup
                fullWidth
                color="primary"
                value={isTest}
                exclusive
                onChange={handelChangeIsTestVersion}
              >
                <ToggleButton value={false}>
                  <Box display="flex" alignItems="center" gap={1}>
                    <CircleIcon color={isTest ? 'inherit' : 'red'} sx={{ fontSize: 10 }} />
                    <Typography>Live</Typography>
                  </Box>
                </ToggleButton>
                <ToggleButton value>
                  <Box display="flex" alignItems="center" gap={1}>
                    <ScienceIcon />
                    <Typography>Test</Typography>
                  </Box>
                </ToggleButton>
              </ToggleButtonGroup>
            </Grid>
            <Grid item xs={12} md={12}>
              <SelectInputButton
                label="Select version"
                choices={[{ id: 'draft', name: 'Draft' }, ...choices]}
                value={version}
                setValue={setVersion}
                handleLoadMore={handleLoadMore}
                hasMore={hasNextPage}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <Box display="flex" gap={2} justifyContent="flex-end">
                <Button onClick={closeDialog} color="error" variant="contained">No</Button>
                <Button disabled={version === ''} onClick={handleClick} variant="contained">Yes</Button>
              </Box>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default Reset;
