import React, { useState, useEffect, useCallback } from 'react';

import {
  RaRecord, useGetList,
} from 'react-admin';

import ReactApexChart from 'react-apexcharts';

import { isEmpty, isNil } from 'lodash';

import {
  Grid,
  Card,
  CardHeader,
  Divider,
  LinearProgress,
} from '@mui/material';

import useDialogStatus from '../../../hooks/useDialogStatus';

import DetailedCategorizationTable from '../DetailedCategorizationTable';
import Params from '../Params';
import CategorizationActions from '../CategorizationActions';
import NoResults from '../../layout/NoResults';

import { EntityTypes } from '../../../constants/entityTypes';

const useGetGraphData = (
  data: RaRecord[] | undefined,
  onClickGraph: (_event: unknown, _chartContext: unknown, config: {
    w: {
      config: {
        series: { data: { [x: string]: any } }[];
      };
    };
    dataPointIndex: string | number;
  }) => void,
  currency?: string,
) => {
  const [hasData, setHasData] = useState(false);
  const [options, setOptions] = useState<ReactApexChart['props']['options']>();
  const [series, setSeries] = useState<ReactApexChart['props']['series']>();

  useEffect(() => {
    if (isEmpty(data) || !currency) return;

    const newSeries = data as ReactApexChart['props']['series'];

    const newOptions = {
      colors: [
        '#388e3c',
        '#880808',
        '#36c2f6',
      ],
      chart: {
        height: 400,
        events: {
          markerClick: onClickGraph,
        },
        zoom: {
          enabled: true,
        },
        toolbar: {
          show: true,
        },
      },
      stroke: {
        curve: 'smooth',
        width: [2, 2, 2],
      },
      fill: {
        type: ['solid', 'solid', 'solid'],
        opacity: [0.35, 0.35, 0.35],
      },
      dataLabels: {
        enabled: false,
      },
      yaxis: {
        decimalsInFloat: 0,
      },
      tooltip: {
        shared: true,
        intersect: false,
        y: {
          formatter(value: number, {
            seriesIndex, dataPointIndex, w,
          }: {
            seriesIndex: number;
            dataPointIndex: number;
            w: RaRecord;
          }) {
            const {
              y,
              frequency,
            } = w.globals.initialSeries[seriesIndex].data[dataPointIndex];

            if (!isNil(y)) {
              return `${y.toFixed(0)} ${currency} (${frequency})`;
            }
            return 'NaN';
          },
        },
      },
      xaxis: {
        type: 'datetime',
        labels: {
          rotate: -90,
        },
      },
    } as ReactApexChart['props']['options']; // satisfies ReactApexChart['props']['options'] when ts is upgraded;;

    setHasData(true);
    setOptions(newOptions);
    setSeries(newSeries);
  }, [currency, data, onClickGraph]);

  return {
    hasData,
    options,
    series,
  };
};

const DailySum = ({
  params,
  setParams,
  filter,
}: {
  params: Params;
  filter: {
    from: string;
    to: string;
    currency: string;
    labels?: string[] | undefined;
    entityId: string | undefined;
    entityType: EntityTypes;
  };
  setParams: React.Dispatch<React.SetStateAction<Params>>;

}) => {
  const [selectedData, setData] = useState<{
    transactions: RaRecord[];
  }>();
  const { open, closeDialog, openDialog } = useDialogStatus();

  const { data, isLoading } = useGetList(
    'bank-data/graph/daily-sum',
    {
      filter,
    },
    { enabled: !!params.from && !!params.to && !!params.currency },
  );

  const onClickGraph = useCallback((
    _event: unknown,
    _chartContext: unknown,
    config: {
      w: {
        config: {
          series: { data: { [x: string]: any } }[];
        };
      };
      dataPointIndex: string | number;
    },
  ) => {
    const dataPointFirstSeries = config?.w?.config?.series[0]?.data[config?.dataPointIndex];
    const dataPointSecondSeries = config?.w?.config?.series[1]?.data[config?.dataPointIndex];
    setData(() => ({
      ...dataPointFirstSeries,
      transactions: [...dataPointFirstSeries.transactions, ...dataPointSecondSeries.transactions],
    }));
    openDialog();
  }, [openDialog]);

  const { hasData, options, series } = useGetGraphData(data, onClickGraph, params.currency);

  if (isLoading) return <LinearProgress />;

  return (
    <Grid container spacing={6}>
      <Grid item xs={12} md={12}>
        <Card variant="outlined">
          <CardHeader
            title="Categorized Transactions"
            action={<CategorizationActions setParams={setParams} params={params} />}
          />
          <Divider />
          {hasData && <ReactApexChart options={options} series={series} type="line" height={600} />}
          {!hasData && <NoResults variant="h4" timeout={2000} />}
        </Card>
      </Grid>
      <DetailedCategorizationTable open={open} closeDialog={closeDialog} data={selectedData} />
    </Grid>
  );
};

export default DailySum;
