import React from 'react';

import { Grid, Grow, Typography } from '@mui/material';

import { FormDataConsumer } from 'react-admin';
import RuleSelect from '../../../layout/ruleSelectDialog/RuleSelect';

import { ruleSpecifParamsMapper, ruleDescriptions } from '../../ruleFeatures';
import { RuleNames } from '../../../../constants/ruleNames';
import TextInput from '../../../layout/inputFields/TextField';

const RuleParameters = ({ name }: {name: RuleNames}) => {
  const RuleParametersComponent = ruleSpecifParamsMapper[name];

  return (
    <Grow in>
      <Grid container spacing={3}>
        <Grid item xs={12} md={12}>
          <Typography variant="description">
            {ruleDescriptions[name]}
          </Typography>
        </Grid>
        <Grid item xs={12} md={12} display={RuleParametersComponent ? 'block' : 'none'}>
          <Typography variant="label" fontWeight="bold">Rule parameters</Typography>
        </Grid>
        <Grid item xs={12} md={12}>
          {RuleParametersComponent && <RuleParametersComponent />}
        </Grid>
      </Grid>
    </Grow>
  );
};

const RuleLogic = () => (
  <Grid container spacing={0}>
    <Grid item xs={12} md={12}>
      <Typography variant="label">Rule name</Typography>
    </Grid>
    <Grid item xs={12} md={12}>
      <RuleSelect />
    </Grid>
    <Grid item xs={12} md={12}>
      <Typography variant="label">Custom Rule Name</Typography>
    </Grid>
    <Grid item xs={12} md={12}>
      <TextInput
        label="Custom Rule Name"
        source="customName"
      />
    </Grid>
    <FormDataConsumer>
      {({ formData }) => formData?.name && (
        <RuleParameters name={formData.name} />
      )}
    </FormDataConsumer>
  </Grid>
);

export default RuleLogic;
