import React, { useCallback, useEffect, useMemo } from 'react';

import {
  required,
  usePermissions,
  useRecordContext,
  Form,
} from 'react-admin';

import {
  Box,
  Dialog,
  DialogActions,
  Button,
  DialogContent,
} from '@mui/material';

import ReorderIcon from '@mui/icons-material/Reorder';
import DoneIcon from '@mui/icons-material//Done';

import { useFormContext, useWatch } from 'react-hook-form';

import RulePathList from './RulePathList';
import AutoCompleteInput from '../inputFields/AutoCompleteInput';

import hasAccess from '../../../utilities/hasAccess';

import {
  RequestRules,
  ongoingRules,
  requestRules,
  requestTypesSupported,
  ruleAssociations,
  rulePermissions,
  rulesShortDescription,
} from '../../rules/ruleFeatures';
import { RuleSubCategories, ruleSubCategoriesChoices } from '../../rules/categories/ruleSubCategories';
import ruleTypes, { RuleTypes } from '../../../constants/ruleTypes';
import { Actions } from '../../../constants/actions';
import useDialogStatus from '../../../hooks/useDialogStatus';
import { RuleCategories, ruleCategoriesChoices } from '../../rules/categories/ruleCategories';
import { RuleNames } from '../../../constants/ruleNames';
import ruleCategoriesMapping from '../../rules/categories/ruleCategoriesMapping';
import ruleSubCategoriesPermissions from '../../rules/categories/ruleSubCategoriesPermissions';
import ruleCategoriesPermissions from '../../rules/categories/ruleCategoriesPermissions';

const RuleSelectDialogContent = ({
  ruleType,
  ruleChoices,
}: {
  ruleType: RuleTypes;
  ruleChoices: {
    id: RuleNames;
    name: string;
  }[];
}) => {
  const currentCategory: RuleCategories | 'all' = useWatch({ name: 'category' });
  const currentSubCategory: RuleSubCategories | 'all' = useWatch({ name: 'subCategory' });
  const { setValue } = useFormContext();
  const { permissions } = usePermissions<Actions[]>();

  const relevantSubCategories = useMemo(() => ruleSubCategoriesChoices.filter((choice) => {
    const isPermitted = ruleSubCategoriesPermissions[choice.id]
      .some((action) => hasAccess(permissions, action));
    if (!isPermitted) return false;

    const isRelevant = ruleChoices.some((ruleChoice) => ruleAssociations[ruleChoice.id]
      .includes(choice.id));
    return isRelevant;
  }), [permissions, ruleChoices]);

  const relevantCategories = useMemo(() => ruleCategoriesChoices.filter((choice) => {
    const isPermitted = ruleCategoriesPermissions[choice.id]
      .some((action) => hasAccess(permissions, action));
    if (!isPermitted) return false;

    const isRelevant = relevantSubCategories.some(
      (subCategoryChoice) => ruleCategoriesMapping[choice.id].includes(subCategoryChoice.id),
    );
    return isRelevant;
  }), [permissions, relevantSubCategories]);

  const filteredRuleChoices = useMemo(() => ruleChoices.filter((ruleChoice) => {
    const isFilteredSubCategory = currentSubCategory === 'all'
      ? currentCategory === 'all' || ruleCategoriesMapping[currentCategory]
        .some((subCategory) => ruleAssociations[ruleChoice.id].includes(subCategory))
      : ruleAssociations[ruleChoice.id].includes(currentSubCategory);
    return isFilteredSubCategory;
  }), [currentCategory, currentSubCategory, ruleChoices]);

  const filteredSubCategoryChoices = useMemo(() => relevantSubCategories
    .filter((subCategoryChoice) => {
      const isFilteredCategory = currentCategory === 'all'
        || ruleCategoriesMapping[currentCategory].includes(subCategoryChoice.id);
      return isFilteredCategory;
    }), [currentCategory, relevantSubCategories]);

  useEffect(() => {
    if (currentSubCategory === 'all' || currentCategory === 'all') return;
    if (!ruleCategoriesMapping[currentCategory].includes(currentSubCategory)) {
      setValue('subCategory', 'all');
    }
  }, [currentCategory, currentSubCategory, setValue]);

  return (
    <DialogContent>
      <Box display="flex" flexWrap="wrap" gap={2} justifyContent="center">
        <Box maxHeight={500} display={ruleType === ruleTypes.ONGOING ? 'none' : 'flex'} flexGrow={1}>
          <RulePathList
            choices={relevantCategories}
            source="category"
            showAllOption
          />
        </Box>
        <Box maxHeight={500} display="flex" flexGrow={1}>
          <RulePathList
            choices={filteredSubCategoryChoices}
            source="subCategory"
            showAllOption
          />
        </Box>
        <Box maxHeight={500} display="flex" flexGrow={1}>
          <RulePathList
            choices={filteredRuleChoices}
            source="rule"
          />
        </Box>
      </Box>
    </DialogContent>
  );
};

const RuleSelect = () => {
  const record = useRecordContext();
  const { permissions } = usePermissions<Actions[]>();
  const { setValue, getValues } = useFormContext();

  const {
    open,
    openDialog,
    closeDialog,
  } = useDialogStatus();

  const handleSubmitDialog = useCallback(({
    rule,
  }: {
    rule?: string;
  }) => {
    setValue('name', rule, { shouldValidate: true });
    closeDialog();
  }, [setValue, closeDialog]);

  const handleOpenWithoutPropagation = useCallback((event: React.MouseEvent) => {
    event.stopPropagation();
    openDialog();
  }, [openDialog]);

  const ruleList = record?.ruleType === ruleTypes.REQUEST ? requestRules : ongoingRules;

  const permittedRules = useMemo(() => Object.values(ruleList)
    .filter((rule) => rulePermissions[rule]
      .every((action) => hasAccess(permissions, action))), [permissions, ruleList]);

  const filteredRules = useMemo(() => (record?.parentRule?.requestTypes
    ? permittedRules
      .filter((rule) => requestTypesSupported[rule as RequestRules]
        ?.some((requestType) => record?.parentRule?.requestTypes
          ?.includes(requestType.id)))
    : permittedRules), [permittedRules, record?.parentRule?.requestTypes]);

  const filteredRulesChoices = useMemo(() => filteredRules.map((ruleName) => ({
    id: ruleName,
    name: rulesShortDescription[ruleName],
  })), [filteredRules]);

  return (
    <>
      <Box>
        <AutoCompleteInput
          source="name"
          fullWidth
          label="Search for rule name... 🔍"
          choices={filteredRulesChoices}
          open={open || undefined}
          validate={required()}
          popups={[
            <ReorderIcon key="rule list" onClick={handleOpenWithoutPropagation} />,
          ]}
        />
      </Box>
      <Dialog
        open={open}
        onClose={closeDialog}
        maxWidth="lg"
        fullWidth
      >
        <Form onSubmit={handleSubmitDialog} defaultValues={{ category: 'all', subCategory: 'all', rule: getValues().name }}>
          <RuleSelectDialogContent ruleType={record?.ruleType} ruleChoices={filteredRulesChoices} />
          <DialogActions>
            <Button onClick={closeDialog}>Close</Button>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              startIcon={<DoneIcon />}
            >
              Select
            </Button>
          </DialogActions>
        </Form>
      </Dialog>
    </>
  );
};

export default RuleSelect;
