import React from 'react';

import {
  ArrayInput,
  DateField,
  SelectField,
  TextField,
} from 'react-admin';

import {
  Card,
  CardHeader,
  Divider,
} from '@mui/material';

import { Alpha3Code } from 'i18n-iso-countries';

import Create from './dialog/Create';
import Edit from './dialog/Edit';

import { TransmodeTypes, transmodeTypesChoices } from '../../../../constants/transmodeTypes';
import DatagridIterator from '../../../../customFields/DatagridIterator';
import { FundsTypes } from '../../../../constants/fundsTypes';
import { Currencies } from '../../../../constants/currencies';
import { LegalFormTypes } from '../../../../constants/legalForms';
import { RequestValues } from './dialog/RequestForm';
import entityTypes from '../../../../constants/entityTypes';

type TPerson = {
  first_name: string;
  last_name: string;
  birthdate?: Date;
  ssn?: string;
}

type TPersonMyClient = {
  first_name: string;
  last_name: string;
  birthdate: Date;
  ssn?: string;
}

type TEntity = {
  name: string;
  incorporation_legal_form?: LegalFormTypes;
  incorporation_number?: string;
  incorporation_country_code?: Alpha3Code | 'unknown';
}

type TEntityMyClient = {
  name: string;
  incorporation_legal_form: LegalFormTypes;
  incorporation_number: string;
  incorporation_country_code: Alpha3Code;
}

type GoodsService = {
  item: {
    item_type: 'FORDB';
    description: string;
    registration_number?: string;
    estimated_value?: number;
  };
}

type ReportTransactionFormat = {
  recordId: string;
  transactionnumber: string;
  amount_local: number;
  date_transaction: Date;
  teller?: string;
  transaction_location?: string;
  comment?: string;
  internal_ref_number?: string;
  transmode_code: TransmodeTypes;
  transmode_comment?: string;
  t_from_my_client?: {
    from_funds_code: FundsTypes;
    from_funds_comment?: string;
    from_foreign_currency: Currencies;
    from_person?: TPersonMyClient;
    from_entity?: TEntityMyClient;
    from_country: Alpha3Code;
  };
  t_from?: {
    from_funds_code: FundsTypes;
    from_funds_comment?: string;
    from_foreign_currency: Currencies;
    from_person?: TPerson;
    from_entity?: TEntity;
    from_country: Alpha3Code;
  };
  t_to_my_client?: {
    to_funds_code: FundsTypes;
    to_funds_comment?: string;
    to_foreign_currency: Currencies;
    to_person?: TPersonMyClient;
    to_entity?: TEntityMyClient;
    to_country: Alpha3Code;
  };
  t_to?: {
    to_funds_code: FundsTypes;
    to_funds_comment?: string;
    to_foreign_currency: Currencies;
    to_person?: TPerson;
    to_entity?: TEntity;
    to_country: Alpha3Code;
  };
  goods_services?: GoodsService[];
}

const formatTransaction = (transaction: ReportTransactionFormat): RequestValues => ({
  recordId: transaction.recordId,
  transactionnumber: transaction.transactionnumber,
  amount_local: transaction.amount_local,
  date_transaction: transaction.date_transaction,
  teller: transaction.teller,
  transaction_location: transaction.transaction_location,
  comment: transaction.comment,
  internal_ref_number: transaction.internal_ref_number,
  transmode_code: transaction.transmode_code,
  transmode_comment: transaction.transmode_comment,
  // eslint-disable-next-line no-nested-ternary
  from: transaction.t_from
    ? (transaction.t_from.from_person ? {
      type: entityTypes.INDIVIDUAL,
      isMyClient: false,
      country: transaction.t_from.from_country,
      foreign_currency: transaction.t_from.from_foreign_currency,
      funds_code: transaction.t_from.from_funds_code,
      funds_comment: transaction.t_from.from_funds_comment,
      person: {
        first_name: transaction.t_from.from_person.first_name,
        last_name: transaction.t_from.from_person.last_name,
        ssn: transaction.t_from.from_person.ssn,
        birthdate: transaction.t_from.from_person.birthdate,
      },
    } : {
      type: entityTypes.BUSINESS,
      isMyClient: false,
      country: transaction.t_from.from_country,
      foreign_currency: transaction.t_from.from_foreign_currency,
      funds_code: transaction.t_from.from_funds_code,
      funds_comment: transaction.t_from.from_funds_comment,
      entity: {
        name: transaction.t_from.from_entity!.name,
        incorporation_number: transaction.t_from.from_entity!.incorporation_number,
        incorporation_country_code: transaction.t_from.from_entity!.incorporation_country_code,
        incorporation_legal_form: transaction.t_from.from_entity!.incorporation_legal_form,
      },
    })
    : (transaction.t_from_my_client!.from_person ? {
      type: entityTypes.INDIVIDUAL,
      isMyClient: false,
      country: transaction.t_from_my_client!.from_country,
      foreign_currency: transaction.t_from_my_client!.from_foreign_currency,
      funds_code: transaction.t_from_my_client!.from_funds_code,
      funds_comment: transaction.t_from_my_client!.from_funds_comment,
      person: {
        first_name: transaction.t_from_my_client!.from_person.first_name,
        last_name: transaction.t_from_my_client!.from_person.last_name,
        ssn: transaction.t_from_my_client!.from_person.ssn,
        birthdate: transaction.t_from_my_client!.from_person.birthdate,
      },
    } : {
      type: entityTypes.BUSINESS,
      isMyClient: false,
      country: transaction.t_from_my_client!.from_country,
      foreign_currency: transaction.t_from_my_client!.from_foreign_currency,
      funds_code: transaction.t_from_my_client!.from_funds_code,
      funds_comment: transaction.t_from_my_client!.from_funds_comment,
      entity: {
        name: transaction.t_from_my_client!.from_entity!.name,
        incorporation_number: transaction.t_from_my_client!.from_entity!.incorporation_number,
        incorporation_country_code: transaction.t_from_my_client!.from_entity!
          .incorporation_country_code,
        incorporation_legal_form: transaction.t_from_my_client!.from_entity!
          .incorporation_legal_form,
      },
    }),
  // eslint-disable-next-line no-nested-ternary
  to: transaction.t_to ? (transaction.t_to.to_person ? {
    type: entityTypes.INDIVIDUAL,
    isMyClient: false,
    country: transaction.t_to.to_country,
    foreign_currency: transaction.t_to.to_foreign_currency,
    funds_code: transaction.t_to.to_funds_code,
    funds_comment: transaction.t_to.to_funds_comment,
    person: {
      first_name: transaction.t_to.to_person.first_name,
      last_name: transaction.t_to.to_person.last_name,
      ssn: transaction.t_to.to_person.ssn,
      birthdate: transaction.t_to.to_person.birthdate,
    },
  } : {
    type: entityTypes.BUSINESS,
    isMyClient: false,
    country: transaction.t_to.to_country,
    foreign_currency: transaction.t_to.to_foreign_currency,
    funds_code: transaction.t_to.to_funds_code,
    funds_comment: transaction.t_to.to_funds_comment,
    entity: {
      name: transaction.t_to.to_entity!.name,
      incorporation_number: transaction.t_to.to_entity!.incorporation_number,
      incorporation_country_code: transaction.t_to.to_entity!.incorporation_country_code,
      incorporation_legal_form: transaction.t_to.to_entity!.incorporation_legal_form,
    },
  }) : (transaction.t_to_my_client!.to_person ? {
    type: entityTypes.INDIVIDUAL,
    isMyClient: false,
    country: transaction.t_to_my_client!.to_country,
    foreign_currency: transaction.t_to_my_client!.to_foreign_currency,
    funds_code: transaction.t_to_my_client!.to_funds_code,
    funds_comment: transaction.t_to_my_client!.to_funds_comment,
    person: {
      first_name: transaction.t_to_my_client!.to_person.first_name,
      last_name: transaction.t_to_my_client!.to_person.last_name,
      ssn: transaction.t_to_my_client!.to_person.ssn,
      birthdate: transaction.t_to_my_client!.to_person.birthdate,
    },
  } : {
    type: entityTypes.BUSINESS,
    isMyClient: false,
    country: transaction.t_to_my_client!.to_country,
    foreign_currency: transaction.t_to_my_client!.to_foreign_currency,
    funds_code: transaction.t_to_my_client!.to_funds_code,
    funds_comment: transaction.t_to_my_client!.to_funds_comment,
    entity: {
      name: transaction.t_to_my_client!.to_entity!.name,
      incorporation_number: transaction.t_to_my_client!.to_entity!.incorporation_number,
      incorporation_country_code: transaction.t_to_my_client!.to_entity!
        .incorporation_country_code,
      incorporation_legal_form: transaction.t_to_my_client!.to_entity!
        .incorporation_legal_form,
    },
  }),
  ...((transaction.goods_services?.length ?? 0) > 0 ? {
    includeGoods: true,
    goods_services: transaction.goods_services!,
  } : {
    includeGoods: false,
    goods_services: [],
  }),
});

const parseTransaction = (transaction: RequestValues): ReportTransactionFormat => ({
  recordId: transaction.recordId,
  transactionnumber: transaction.transactionnumber,
  amount_local: transaction.amount_local,
  date_transaction: transaction.date_transaction,
  teller: transaction.teller,
  transaction_location: transaction.transaction_location,
  comment: transaction.comment,
  internal_ref_number: transaction.internal_ref_number,
  transmode_code: transaction.transmode_code,
  transmode_comment: transaction.transmode_comment,
  ...(transaction.from.isMyClient ? {
    t_from_my_client: {
      from_funds_code: transaction.from.funds_code,
      from_funds_comment: transaction.from.funds_comment,
      from_foreign_currency: transaction.from.foreign_currency,
      ...(transaction.from.type === entityTypes.INDIVIDUAL
        ? { from_person: transaction.from.person } : {}),
      ...(transaction.from.type === entityTypes.BUSINESS
        ? { from_entity: transaction.from.entity } : {}),
      from_country: transaction.from.country,
    },
  } : {
    t_from: {
      from_funds_code: transaction.from.funds_code,
      from_funds_comment: transaction.from.funds_comment,
      from_foreign_currency: transaction.from.foreign_currency,
      ...(transaction.from.type === entityTypes.INDIVIDUAL
        ? { from_person: transaction.from.person } : {}),
      ...(transaction.from.type === entityTypes.BUSINESS
        ? { from_entity: transaction.from.entity } : {}),
      from_country: transaction.from.country,
    },
  }),
  ...(transaction.to.isMyClient ? {
    t_to_my_client: {
      to_funds_code: transaction.to.funds_code,
      to_funds_comment: transaction.to.funds_comment,
      to_foreign_currency: transaction.to.foreign_currency,
      ...(transaction.to.type === entityTypes.INDIVIDUAL
        ? { to_person: transaction.to.person } : {}),
      ...(transaction.to.type === entityTypes.BUSINESS
        ? { to_entity: transaction.to.entity } : {}),
      to_country: transaction.to.country,
    },
  } : {
    t_to: {
      to_funds_code: transaction.to.funds_code,
      to_funds_comment: transaction.to.funds_comment,
      to_foreign_currency: transaction.to.foreign_currency,
      ...(transaction.to.type === entityTypes.INDIVIDUAL
        ? { to_person: transaction.to.person } : {}),
      ...(transaction.to.type === entityTypes.BUSINESS
        ? { to_entity: transaction.to.entity } : {}),
      to_country: transaction.to.country,
    },
  }),
  goods_services: transaction.goods_services,
});

const Transactions = () => (
  <ArrayInput source="report.transactions" label={false}>
    {/* Fragment is necessary to avoid react admin overriding child's props */}
    {/* eslint-disable-next-line react/jsx-no-useless-fragment */}
    <>
      <Card variant="outlined">
        <CardHeader title="Transactions" />
        <Divider />
        <DatagridIterator
          rowContent={[
            <TextField source="transactionnumber" label="Transaction number" key="transactionnumber" />,
            <DateField source="date_transaction" label="Transaction Date" key="date_transaction" />,
            <SelectField choices={transmodeTypesChoices} source="transmode_code" label="Transaction channel" key="transmode_code" />,
            <TextField source="amount_local" label="Transaction amount" key="amount_local" />,
          ]}
          addDialogContent={(
            <Create />
          )}
          editDialogContent={(
            <Edit />
          )}
          format={formatTransaction}
          parse={parseTransaction}
        />
      </Card>
    </>
  </ArrayInput>
);

export default Transactions;
