import { get } from 'lodash';
import React, {
  Children,
  Fragment,
  isValidElement,
  ReactElement,
  ReactNode,
} from 'react';
import { ArrayField, TextFieldProps, useRecordContext } from 'react-admin';

const CompositeTextField = ({
  sortable,
  label,
  children,
}: {
  sortable: boolean;
  label?: string;
  children: ReactNode;
}) => {
  const record = useRecordContext();
  if (!record) return null;
  const sourcedChildren = Children.toArray(children)
    .filter((child) => (
      isValidElement<TextFieldProps>(child)
      && child.props.source
      && get(record, child.props.source)));
  return (
    <ArrayField sortable={sortable} label={label}>
      {
        sourcedChildren.map((child, index) => (
          <Fragment key={(child as ReactElement)!.props.source}>
            { child }
            { index < sourcedChildren.length - 1 && ' - '}
          </Fragment>
        ))
      }
    </ArrayField>
  );
};

export default CompositeTextField;
