import { ValueOf } from '../utilities/types';

const reportTypes = {
  FIPO: 'fipo',
  PING_TEST_OVERVIEW: 'pingTestOverview',
  PING_OVERVIEW: 'pingOverview',
  ENTITIES_OVERVIEW: 'entitiesOverview',
  CASE_OVERVIEW: 'caseOverview',
  RISK_DISTRIBUTION: 'riskDistribution',
  SFSA: 'sfsa',
  CUSTOM: 'custom',
} as const;

export const reportTypesChoices = [
  { id: reportTypes.PING_TEST_OVERVIEW, name: 'Ping test overview' },
  { id: reportTypes.PING_OVERVIEW, name: 'Ping overview' },
  { id: reportTypes.ENTITIES_OVERVIEW, name: 'Entities overview' },
  { id: reportTypes.CASE_OVERVIEW, name: 'Case overview' },
  { id: reportTypes.RISK_DISTRIBUTION, name: 'Risk distribution' },
  { id: reportTypes.SFSA, name: 'SFSA regulatory report' },
];

export const reportTypesDisplayChoices = [
  { id: reportTypes.PING_TEST_OVERVIEW, name: 'Ping test overview' },
  { id: reportTypes.PING_OVERVIEW, name: 'Ping overview' },
  { id: reportTypes.ENTITIES_OVERVIEW, name: 'Entities overview' },
  { id: reportTypes.CASE_OVERVIEW, name: 'Case overview' },
  { id: reportTypes.RISK_DISTRIBUTION, name: 'Risk distribution' },
  { id: reportTypes.SFSA, name: 'SFSA regulatory report' },
  { id: reportTypes.CUSTOM, name: 'Custom report' },
];

export type ReportTypes = ValueOf<typeof reportTypes>;
export type SelectableReportTypes = typeof reportTypesChoices[number]['id'];

export default reportTypes;
