import React from 'react';
import { Grid } from '@mui/material';
import {
  NumberInput,
  SelectInput, minValue, required,
} from 'react-admin';

import timePeriods from '../../../../constants/timePeriods';
import Label from '../../../../customFields/Label';
import TransactionTypeConfiguration from './TransactionTypeConfiguration';
import transactionMonitoringModes, { transactionMonitoringModesChoices } from '../../../../constants/transactionMonitoringMode';

const SimpleAnomalyDetection = () => (
  <Grid item xs={12} md={12}>
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <TransactionTypeConfiguration />
      </Grid>
      <Grid item xs={12} md={6}>
        <SelectInput
          source="ruleParameters.transactionMonitoringMode"
          choices={transactionMonitoringModesChoices}
          fullWidth
          label="Transaction monitoring mode"
          validate={[required()]}
          defaultValue={transactionMonitoringModes.ALL_RELATED}
        />
      </Grid>
      <Grid item xs={12} md={12}>
        <NumberInput source="ruleParameters.threshold" fullWidth label="Deviation (%)" />
      </Grid>
      <Grid item xs={12} md={12}>
        <NumberInput
          source="ruleParameters.minimumNumberOfObservations"
          fullWidth
          label="The minimum number of transactions that must have been made by the entity for rule to apply"
        />
      </Grid>

      <Grid item xs={12} md={12}>
        <Label
          variant="label"
          title="The minimum time that must have passed from the first transaction made by the entity for the rule to apply"
          info="The period will determine if a entity have been in the system long enough to be used."
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <NumberInput
          source="ruleParameters.minimumUsageTime.amount"
          fullWidth
          label="Length"
          validate={[required(), minValue(1)]}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <SelectInput
          source="ruleParameters.minimumUsageTime.unit"
          choices={Object.values(timePeriods).map((period) => ({ id: period, name: period }))}
          fullWidth
          label="Period"
          validate={[required(), minValue(1)]}
        />
      </Grid>
      <Grid item xs={12} md={12}>
        <Label
          variant="label"
          title="Determines the total period over which smaller period sums will be computed to form an average."
          info="The period that will be used to determine the start of the history to include."
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <NumberInput
          source="ruleParameters.periodToConsider.amount"
          fullWidth
          label="Length"
          validate={[required(), minValue(1)]}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <SelectInput
          source="ruleParameters.periodToConsider.unit"
          choices={Object.values(timePeriods).map((period) => ({ id: period, name: period }))}
          fullWidth
          label="Period"
          validate={[required(), minValue(1)]}
        />
      </Grid>

      <Grid item xs={12} md={12}>
        <Label
          variant="label"
          title="The smaller period used to compute sums"
          info="The period that will be used to get windows from the history."
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <NumberInput
          source="ruleParameters.windowPeriod.amount"
          fullWidth
          label="Length"
          validate={[required(), minValue(1)]}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <SelectInput
          source="ruleParameters.windowPeriod.unit"
          choices={Object.values(timePeriods).map((period) => ({ id: period, name: period }))}
          fullWidth
          label="Period"
          validate={[required(), minValue(1)]}
        />
      </Grid>
    </Grid>
  </Grid>

);

export default SimpleAnomalyDetection;
