import React, { useEffect } from 'react';

import {
  required,
  minValue,
  useRecordContext,
} from 'react-admin';

import {
  Grid,
  Typography,
} from '@mui/material';

import { useFormContext, useWatch } from 'react-hook-form';

import labels from '../../../../constants/labels';
import timePeriods from '../../../../constants/timePeriods';

import NumberInput from '../../../layout/inputFields/NumberInput';
import InfoPopover from '../../../layout/InfoPopover';
import SelectInput from '../../../layout/inputFields/SelectInput';

import ThresholdInput from './ThresholdInput';
import thresholdVariants from '../../../../constants/thresholdVariant';
import transactionMonitoringModes, { transactionMonitoringModesChoices } from '../../../../constants/transactionMonitoringMode';

const CategorizationFrequency = () => {
  const { setValue } = useFormContext();
  const record = useRecordContext();
  const thresholdVariant = useWatch({
    name: 'ruleParameters.thresholdVariant',
    defaultValue: record?.ruleParameters?.thresholdVariant ?? thresholdVariants.CURRENCY,
  });

  useEffect(() => {
    if (thresholdVariant === 'currency') { setValue('ruleParameters.commonThreshold', undefined); }
    if (thresholdVariant === 'common') {
      setValue('ruleParameters.thresholds', undefined);
      setValue('ruleParameters.parameterPriority', undefined);
    }
  }, [setValue, thresholdVariant]);

  return (
    <Grid item xs={12} md={12}>
      <Grid container spacing={1}>
        <Grid item xs={12} md={6}>
          <SelectInput
            source="ruleParameters.transactionMonitoringMode"
            choices={transactionMonitoringModesChoices}
            fullWidth
            label="Transaction monitoring mode"
            validate={[required()]}
            defaultValue={transactionMonitoringModes.ALL_RELATED}
          />
        </Grid>
        <Grid item xs={12} md={12}>
          <ThresholdInput source="default" thresholdVariant={thresholdVariant} displayText="Frequency" allowCommon />
        </Grid>
        <Grid item xs={12} md={12}>
          <Typography variant="label">Period</Typography>
        </Grid>
        <Grid item xs={12} md={6}>
          <NumberInput
            source="ruleParameters.period.amount"
            fullWidth
            label="Length"
            validate={[required(), minValue(1)]}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <SelectInput
            source="ruleParameters.period.unit"
            choices={Object.values(timePeriods).map((period) => ({ id: period, name: period }))}
            fullWidth
            label="Period"
            validate={[required(), minValue(1)]}
          />
        </Grid>
        <Grid item xs={12} md={12}>
          <Typography variant="label">Category</Typography>
          <SelectInput
            source="ruleParameters.category"
            choices={labels}
            fullWidth
            label="Category"
            validate={required()}
            popups={[<InfoPopover key="category" title="Category" content="The transactions with the selected category that is to be checked against the frequency threshold." />]}
          />
        </Grid>
      </Grid>
    </Grid>

  );
};

export default CategorizationFrequency;
