import React, {
  useCallback, useEffect,
  useMemo,
  useState,
} from 'react';
import {
  Box,
  Link,
  Typography,
} from '@mui/material';

import { useSearchParams } from 'react-router-dom';
import { useDataProvider, useNotify, HttpError } from 'react-admin';
import { useQuery } from 'react-query';
import AuthLayout from '../AuthLayout';
import accountDetailsStore, { AccountDetails } from '../../../utilities/accountDetailsStore';
import AliasPage from '../alias/AliasPage';
import { mapLoginForm } from '../loginMethodsFeatures';
import { LoginMethods } from '../../../constants/loginMethods';
import LoginContext from './LoginContext';
import useRedirectAlreadyAuthenticated from '../useRedirectAlreadyAuthenticated';

type AliasResponse = {
  id: string;
  loginMethods: [
    {
      method: LoginMethods;
      parameters?: object;
    }
  ];
}

const Login = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const notify = useNotify();
  const dataProvider = useDataProvider();

  useEffect(() => {
    const error = searchParams.get('error');
    if (error) {
      notify(error, { type: 'error' });
      searchParams.delete('error');
      setSearchParams(searchParams);
    }
  }, [notify, searchParams, setSearchParams]);

  const [accountDetails, setAccountDetails] = useState<AccountDetails | null>(
    accountDetailsStore.getAccountDetails(),
  );

  const resetAlias = useCallback(() => {
    accountDetailsStore.removeAccountDetails();
    setAccountDetails(null);
  }, []);

  const switchLoginMethod = useCallback((newLoginMethod: LoginMethods) => {
    setAccountDetails((prevState) => {
      if (!prevState) return null;
      const newState = { ...prevState, currentLoginMethod: newLoginMethod };
      if (accountDetailsStore.getAccountDetails()) accountDetailsStore.setAccountDetails(newState);
      return newState;
    });
  }, []);

  const saveAccountAlias = useCallback((alias: string, rememberAlias?: boolean) => {
    if (rememberAlias) accountDetailsStore.setAccountDetails({ alias });
    else accountDetailsStore.removeAccountDetails();
    setAccountDetails({ alias });
  }, []);

  const { data: aliasResponse, error, isLoading } = useQuery(['account-alias', accountDetails?.alias], async () => {
    if (!accountDetails) return undefined;
    const res = await dataProvider.getOne<AliasResponse>('accounts/alias', { id: accountDetails.alias });
    return res.data;
  }, {
    enabled: !!accountDetails?.alias,
    onError: (e) => {
      if (e instanceof HttpError) notify(e.message, { type: 'error' });
      else notify('An unexpected error occurred', { type: 'error' });
      resetAlias();
    },
  });

  const loginContextValue = useMemo(() => ({
    accountDetails,
    loginMethods: aliasResponse?.loginMethods,
    loginMethodsLoading: isLoading,
    loginMethodsError: error,
    resetAlias,
    switchLoginMethod,
    saveAccountAlias,
  }), [
    accountDetails,
    resetAlias,
    switchLoginMethod,
    saveAccountAlias,
    aliasResponse?.loginMethods,
    isLoading,
    error,
  ]);

  useEffect(() => {
    if (!loginContextValue) return;
    const { loginMethods } = aliasResponse ?? {};
    if (loginMethods && loginMethods.length && (
      !accountDetails?.currentLoginMethod
      || !loginMethods.some((method) => method.method === accountDetails.currentLoginMethod)
    )) {
      const { method } = loginMethods[0];
      switchLoginMethod(method);
    }
  }, [switchLoginMethod, loginContextValue, aliasResponse, accountDetails?.currentLoginMethod]);

  useRedirectAlreadyAuthenticated();

  return (
    <LoginContext.Provider value={loginContextValue}>
      <AuthLayout>
        <Typography component="h1" variant="h4" align="center" gutterBottom>
          Welcome
        </Typography>
        <Typography component="h2" variant="body1" align="center">
          Sign in to your account to continue
        </Typography>
        {accountDetails && aliasResponse ? (
          <>
            <Box marginTop={6} marginBottom={6}>
              <Typography variant="h6" color="primary" marginTop={6}>
                Account alias:
                {' '}
                {accountDetails.alias}
              </Typography>
              {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
              <Link
                component="button"
                onClick={resetAlias}
                underline="always"
              >
                Change
              </Link>
            </Box>
            {accountDetails.currentLoginMethod && mapLoginForm[accountDetails.currentLoginMethod]}
          </>
        ) : (
          <AliasPage />
        )}
      </AuthLayout>
    </LoginContext.Provider>
  );
};

export default Login;
