import React, { useState, useCallback } from 'react';

const usePagination = <T extends Array<unknown> | undefined>({
  defaultRowsPerPage = 25,
  data,
}: {defaultRowsPerPage?: number; data: T}) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(defaultRowsPerPage);

  const handleChangePage = useCallback((_event: React.SyntheticEvent | null, newPage: number) => {
    setPage(newPage);
  }, []);

  const handleChangeRowsPerPage = useCallback((
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  }, []);

  const calculatePageView = useCallback((currentPage: number, rowPerPage: number) => {
    const startSlice = (currentPage * rowPerPage);
    const endSlice = (currentPage + 1) * rowPerPage;
    return {
      data: data?.slice(startSlice, endSlice),
    };
  }, [data]);

  return {
    page,
    rowsPerPage,
    handleChangePage,
    handleChangeRowsPerPage,
    calculatePageView,
  };
};

export default usePagination;
