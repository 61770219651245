import React from 'react';

import {
  DateField,
  RecordContextProvider,
} from 'react-admin';

import {
  Box,
  Grid,
  CardContent,
  Typography,
  Card,
  CardHeader,
  Divider,
} from '@mui/material';
import { Alpha3Code } from 'i18n-iso-countries';
import { isEmpty } from 'lodash';

import useEntityData from '../../../../hooks/useEntityData';

import OrgChartTree from '../../../../customFields/OrgChartTree';

import PurchaseButton from '../../../layout/button/PurchaseButton';
import actions from '../../../../constants/actions';
import NoResults from '../../../layout/NoResults';
import { EntityTypes } from '../../../../constants/entityTypes';

type CompanyOwnership = {
  id: string;
  createdAt: string;
  ownerNodes: {
    id: number;
    entityId: string;
    entityType: EntityTypes;
    company: boolean;
    companyId: string;
    controlDate: string;
    controlInterval: string;
    residence: Alpha3Code;
    level: number;
    name: string;
    parentId: number;
    personalNumber: string;
    quota: string;
    shareCount: number;
  }[];
}

export type BeneficialOwners = {
  id: string;
  createdAt: string;
  beneficialOwners: {
    entityId: string;
    entityType: EntityTypes;
    controlTypes: {
      association: {
        companyId: string;
        companyName: string;
        type: {
          code: string;
          description: string;
        };
      };
      code: string;
      description: string;
    }[];
    extentOfControl: {
      code: string;
      description: string;
      ownership?: {
        lowerBound: number;
        upperBound: number;
      };
      vote?: {
        lowerBound: number;
        upperBound: number;
      };
    };
    person: {
      birthDate: string;
      citizenship: {
        code: string;
        description: string;
      };
      citizenships?: Alpha3Code[];
      coordinationNumber: string;
      countryResidence: {
        code: string;
        description: string;
      };
      firstNames: string[];
      gdNumber: string;
      givenName: string;
      middleName: string;
      personalNumber: string;
      surName: string;
      nationalIdentifier?: {
        country: Alpha3Code;
        identifier: string;
      }[];
    };
  }[];
}

export type AltBeneficialOwners = {
  id: string;
  createdAt: string;
  altBeneficialOwners: {
    entityId: string;
    entityType: EntityTypes;
    altSource: string;
    boardMemberInfo: {
      description: string;
      roleCode: string;
    };
    name: string;
    residence: Alpha3Code;
    ownerInfo: {
      shareQuotas: {
        company: string;
        companyId: string;
        path: string;
        quota: string;
      }[];
      totalQuota: string;
    };
    personalNumber: string;
  }[];
}

const useOwnership = () => {
  const {
    data: ownershipData,
    handlePurchase: handlePurchaseOwnership,
    isLoading: ownershipIsLoading,
  } = useEntityData<CompanyOwnership>({
    source: 'company-ownership',
    getAction: actions.BUSINESS_GET_OWNERSHIP,
  });
  const {
    data: beneficialOwnersData,
    handlePurchase: handlePurchaseBenOwners,
    isLoading: benOwnerIsLoading,
  } = useEntityData<BeneficialOwners>({
    resource: 'businesses',
    source: 'beneficial-owners',
    getAction: actions.BUSINESS_GET_OWNERSHIP,
  });
  const {
    data: altBeneficialOwnersData,
    handlePurchase: handlePurchaseAltBenOwners,
    isLoading: altBenOwnerIsLoading,
  } = useEntityData<AltBeneficialOwners>({
    resource: 'businesses',
    source: 'alt-beneficial-owners',
    getAction: actions.BUSINESS_GET_OWNERSHIP,
  });

  return {
    ownershipData,
    beneficialOwnersData,
    altBeneficialOwnersData,
    handlePurchaseBenOwners,
    handlePurchaseOwnership,
    handlePurchaseAltBenOwners,
    isLoading: ownershipIsLoading || benOwnerIsLoading || altBenOwnerIsLoading,
  };
};

const OwnerShip = () => {
  const {
    ownershipData,
    beneficialOwnersData,
    altBeneficialOwnersData,
    handlePurchaseAltBenOwners,
    handlePurchaseBenOwners,
    handlePurchaseOwnership,
    isLoading,
  } = useOwnership();

  const beneficialOwners = beneficialOwnersData?.beneficialOwners;
  const altBeneficialOwners = altBeneficialOwnersData?.altBeneficialOwners;

  return (
    <Grid container spacing={6}>

      {isEmpty(beneficialOwners) && (
        <Grid item xs={12} md={6}>
          <Card variant="outlined">
            <CardHeader
              title="Beneficial Owner"
              subheader={(
                <Box display="flex" alignItems="baseline">
                  <Typography>Latest update:</Typography>
                  <DateField
                    sx={{ marginLeft: 1, fontWeight: 'bold' }}
                    source="updateDate"
                    record={{ updateDate: beneficialOwnersData?.createdAt }}
                    emptyText="-"
                    showTime
                  />
                </Box>
              )}
              action={(
                <PurchaseButton
                  loading={isLoading}
                  label="Get Beneficial Owners"
                  onClick={handlePurchaseBenOwners}
                  action={actions.BUSINESS_CREATE_OWNERSHIP}
                />
              )}
            />
            <Divider />
            <NoResults />
          </Card>
        </Grid>
      )}

      {beneficialOwners?.map((owner) => (
        <Grid item xs={12} md={6} key={`${owner?.entityId}`}>
          <Card variant="outlined">
            <CardHeader
              title="Beneficial Owner"
              subheader={(
                <Box display="flex" alignItems="baseline">
                  <Typography>Latest update:</Typography>
                  <DateField
                    sx={{ marginLeft: 1, fontWeight: 'bold' }}
                    source="updateDate"
                    record={{ updateDate: beneficialOwnersData?.createdAt }}
                    emptyText="-"
                    showTime
                  />
                </Box>
              )}
              action={(
                <PurchaseButton
                  loading={isLoading}
                  label="Get Beneficial Owners"
                  onClick={handlePurchaseBenOwners}
                  action={actions.BUSINESS_CREATE_OWNERSHIP}
                />
              )}
            />
            <Divider />
            <CardContent>
              <Grid container spacing={6}>
                <Grid item xs={3} md={3}>
                  <Box>
                    <Typography variant="smallTitle">First Names</Typography>
                    <Typography>
                      {owner?.person?.firstNames?.join(', ')}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={3} md={3}>
                  <Box>
                    <Typography variant="smallTitle">Sur Name</Typography>
                    <Typography>
                      {owner?.person?.surName}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={3} md={3}>
                  <Box>
                    <Typography variant="smallTitle">Personal Number</Typography>
                    <Typography>
                      {owner?.person?.personalNumber}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={3} md={3} />
                <Grid item xs={8} md={8}>
                  <Typography variant="smallTitle">Citizenship</Typography>
                </Grid>
                <Grid item xs={4} md={4}>
                  <Typography variant="smallTitle">Country of residence</Typography>
                </Grid>
                <Grid item xs={1} md={1}>
                  <Box>
                    <Typography variant="smallTitle">Code</Typography>
                    <Typography>
                      {owner?.person?.citizenship?.code}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={1} md={1}>
                  <Box>
                    <Typography variant="smallTitle">Description</Typography>
                    <Typography>
                      {owner?.person?.citizenship?.description}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={6} md={6} />
                <Grid item xs={1} md={1}>
                  <Box>
                    <Typography variant="smallTitle">Code</Typography>
                    <Typography>
                      {owner?.person?.countryResidence?.code}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={1} md={1}>
                  <Box>
                    <Typography variant="smallTitle">Description</Typography>
                    <Typography>
                      {owner?.person?.countryResidence?.description}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={12} md={12}>
                  <Box>
                    <Typography variant="smallTitle">Control Types</Typography>
                    <ul>
                      {owner?.controlTypes?.map((type) => (
                        <li key={type?.description}>
                          {type?.description}
                        </li>
                      ))}
                    </ul>
                  </Box>
                </Grid>
                <Grid item xs={12} md={12}>
                  <Box>
                    <Typography variant="smallTitle">Extent of Control</Typography>
                    <Typography>
                      {owner?.extentOfControl?.description}
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      ))}

      {isEmpty(altBeneficialOwners) && (
        <Grid item xs={12} md={6}>
          <Card variant="outlined">
            <CardHeader
              title="Alternative Beneficial Owner"
              subheader={(
                <Box display="flex" alignItems="baseline">
                  <Typography>Latest update:</Typography>
                  <DateField
                    sx={{ marginLeft: 1, fontWeight: 'bold' }}
                    source="updateDate"
                    record={{ updateDate: altBeneficialOwnersData?.createdAt }}
                    emptyText="-"
                    showTime
                  />
                </Box>
              )}
              action={(
                <PurchaseButton
                  loading={isLoading}
                  label="Get Alternative Beneficial Owner"
                  onClick={handlePurchaseAltBenOwners}
                  action={actions.BUSINESS_CREATE_OWNERSHIP}
                />
              )}
            />
            <Divider />
            <NoResults />
          </Card>
        </Grid>
      )}

      {altBeneficialOwners?.map((owner) => (
        <Grid item xs={12} md={6} key={`${owner.name}`}>
          <Card variant="outlined">
            <CardHeader
              title="Alternative Beneficial Owner"
              subheader={(
                <Box display="flex" alignItems="baseline">
                  <Typography>Latest update:</Typography>
                  <DateField
                    sx={{ marginLeft: 1, fontWeight: 'bold' }}
                    source="updateDate"
                    record={{ updateDate: altBeneficialOwnersData?.createdAt }}
                    emptyText="-"
                    showTime
                  />
                </Box>
              )}
              action={(
                <PurchaseButton
                  loading={isLoading}
                  label="Get Alternative Beneficial Owner"
                  onClick={handlePurchaseAltBenOwners}
                  action={actions.BUSINESS_CREATE_OWNERSHIP}
                />
              )}
            />
            <Divider />
            <CardContent>
              <Grid container spacing={6}>
                <Grid item xs={6} md={6}>
                  <Box>
                    <Typography variant="smallTitle">Name</Typography>
                    <Typography>
                      {owner.name}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={6} md={6}>
                  <Box>
                    <Typography variant="smallTitle">Personal Number</Typography>
                    <Typography>
                      {owner.personalNumber}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={6} md={6}>
                  <Box>
                    <Typography variant="smallTitle">Description</Typography>
                    <Typography>
                      {owner.boardMemberInfo.description}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={6} md={6}>
                  <Box>
                    <Typography variant="smallTitle">Total Quota</Typography>
                    <Typography>
                      {Number.parseFloat(owner.ownerInfo.totalQuota) * 100}
                      %
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      ))}
      <Grid item xs={12} md={12}>
        <Card variant="outlined">
          <CardHeader
            title="Ownership Tree"
            subheader={(
              <Box display="flex" alignItems="baseline">
                <Typography>Latest update:</Typography>
                <DateField
                  sx={{ marginLeft: 1, fontWeight: 'bold' }}
                  source="updateDate"
                  record={{ updateDate: ownershipData?.createdAt }}
                  emptyText="-"
                  showTime
                />
              </Box>
            )}
            action={(
              <PurchaseButton
                loading={isLoading}
                label="Get Ownership Tree"
                onClick={handlePurchaseOwnership}
                action={actions.BUSINESS_CREATE_OWNERSHIP}
              />
            )}
          />
          <Divider />
          <CardContent>
            <RecordContextProvider value={ownershipData}>
              <OrgChartTree />
            </RecordContextProvider>
          </CardContent>
        </Card>
      </Grid>

    </Grid>
  );
};

export default OwnerShip;
