import { BusinessListBody } from '../components/business/BusinessList';
import { CarListBody } from '../components/car/CarList';
import { IndividualListBody } from '../components/individual/IndividualList';
import entityTypes from './entityTypes';

export const entityListMap = {
  [entityTypes.INDIVIDUAL]: IndividualListBody,
  [entityTypes.BUSINESS]: BusinessListBody,
  [entityTypes.CAR]: CarListBody,
  [entityTypes.ACCOUNT]: null,
  [entityTypes.PRODUCT]: null,
};

export const EntityTargetMap = {
  [entityTypes.INDIVIDUAL]: 'individuals',
  [entityTypes.BUSINESS]: 'businesses',
  [entityTypes.CAR]: 'cars',
  [entityTypes.ACCOUNT]: 'account-entities',
  [entityTypes.PRODUCT]: 'products',
};
