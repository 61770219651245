import { ValueOf } from '../utilities/types';

const transactionMonitoringModes = {
  ALL_RELATED: 'all-related',
  ACCOUNT_PER_ACCOUNT: 'account-per-account',
  PRODUCT_PER_PRODUCT: 'product-per-product',
} as const;

const transactionMonitoringModesLabels = {
  [transactionMonitoringModes.ALL_RELATED]: 'All related',
  [transactionMonitoringModes.ACCOUNT_PER_ACCOUNT]: 'Account per account',
  [transactionMonitoringModes.PRODUCT_PER_PRODUCT]: 'Product per product',
} as const;

export type TimePeriods = ValueOf<typeof transactionMonitoringModes>;

export const transactionMonitoringModesChoices = [
  {
    id: transactionMonitoringModes.ALL_RELATED,
    name: transactionMonitoringModesLabels[transactionMonitoringModes.ALL_RELATED],
  },
  {
    id: transactionMonitoringModes.ACCOUNT_PER_ACCOUNT,
    name: transactionMonitoringModesLabels[transactionMonitoringModes.ACCOUNT_PER_ACCOUNT],
  },
  {
    id: transactionMonitoringModes.PRODUCT_PER_PRODUCT,
    name: transactionMonitoringModesLabels[transactionMonitoringModes.PRODUCT_PER_PRODUCT],
  },
];

export default transactionMonitoringModes;
