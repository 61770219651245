import { isEmpty } from 'lodash';
import React from 'react';
import { Toolbar, SaveButton } from 'react-admin';
import { useFormContext } from 'react-hook-form';

const CustomToolbar = ({
  excludeDirtyFields,
  sx,
}: {
  excludeDirtyFields?: string[];
  sx?: {};
}) => {
  const { formState: { dirtyFields } } = useFormContext();

  const fields = Object.keys(dirtyFields).filter((item) => !item.startsWith('_'));

  if (!isEmpty(excludeDirtyFields)
    && fields.every((field) => excludeDirtyFields!.includes(field))) {
    return null;
  }

  if (isEmpty(fields)) return null;

  return (
    <Toolbar sx={sx}>
      <SaveButton />
    </Toolbar>
  );
};

export default CustomToolbar;
