import React, { useCallback } from 'react';

import {
  ArrayField,
  ArrayInput,
  Datagrid,
  Form,
  SelectField,
  SelectInput,
  SimpleFormIterator,
  required,
  useNotify,
  useRecordContext,
  useUpdate,
} from 'react-admin';

import {
  Card,
  CardHeader,
  Divider,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
} from '@mui/material';

import { FieldValues } from 'react-hook-form';
import { useParams } from 'react-router-dom';

import CreateIcon from '@mui/icons-material/Create';
import useDialogStatus from '../hooks/useDialogStatus';

import { boldDataGridStyle } from '../constants/style/datagridStyles';
import NoResults from '../components/layout/NoResults';
import { entityTypesChoices } from '../constants/entityTypes';
import { productPartyRolesChoices } from '../constants/productPartyRoles';
import ReferenceEntityField from './ReferenceEntityField';
import { IProduct } from '../utilities/schemas/product';
import ReferenceEntityInputV2 from './ReferenceEntityInputV2';

export const ProductPartiesInputFields = ({ source }: { source?: string }) => (
  <ArrayInput source={source ?? 'parties'} label={false} fullWidth defaultValue={[{}]}>
    <SimpleFormIterator inline sx={{ '.RaSimpleFormIterator-form': { m: 2, alignItems: 'center' } }} disableReordering>
      <ReferenceEntityInputV2 source="" validate={[required()]} label="entity" />
      <SelectInput source="role" helperText={false} validate={[required()]} choices={productPartyRolesChoices} />
    </SimpleFormIterator>
  </ArrayInput>
);

const EditDialog = () => {
  const notify = useNotify();
  const record = useRecordContext<IProduct>();
  const { id } = useParams();
  const { open, openDialog, closeDialog } = useDialogStatus();

  const [update] = useUpdate();

  const handelSave = useCallback((data: FieldValues) => {
    update('products', { id, data }, {
      onError: () => notify('Could not update parties', { type: 'error' }),
      onSuccess: closeDialog,
      mutationMode: 'pessimistic',
    });
  }, [closeDialog, id, notify, update]);

  return (
    <>
      <Button startIcon={<CreateIcon color="secondary" />} size="small" onClick={openDialog}>
        Edit
      </Button>
      <Dialog open={open} onClose={closeDialog} maxWidth="lg">
        <Form
          record={{
            parties: record?.parties,
          }}
          onSubmit={handelSave}
        >
          <DialogTitle>Parties</DialogTitle>
          <DialogContent>
            <ProductPartiesInputFields />
          </DialogContent>
          <DialogActions>
            <Button color="error" onClick={closeDialog}>Cancel</Button>
            <Button type="submit" variant="contained">Save</Button>
          </DialogActions>
        </Form>
      </Dialog>
    </>
  );
};

const Actions = ({ showEdit }: { showEdit: boolean }) => {
  if (!showEdit) return null;

  return (
    <EditDialog />
  );
};

export const ProductPartiesDataGrid = () => (
  <Datagrid sx={boldDataGridStyle} bulkActionButtons={false} empty={<NoResults text="No data to display." icon={null} />}>
    <SelectField choices={entityTypesChoices} source="type" />
    <ReferenceEntityField source="" />
    <SelectField choices={productPartyRolesChoices} source="role" />
  </Datagrid>
);

const ProductParties = ({ showEdit }: { showEdit: boolean }) => (
  <Card variant="outlined">
    <CardHeader
      sx={{
        color: 'black',
        fontWeight: 'bold',
        flexWrap: 'wrap',
      }}
      titleTypographyProps={{ variant: 'inherit' }}
      title="Parties"
      action={<Actions showEdit={showEdit} />}
    />
    <Divider />
    <ArrayField source="parties">
      <ProductPartiesDataGrid />
    </ArrayField>
  </Card>
);
export default ProductParties;
