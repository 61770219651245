import React from 'react';
import { useRecordContext } from 'react-admin';
import productTypes, { ProductTypes } from '../../../../constants/productTypes';
import SwishProductInformation from './swishProductInformation';
import { IProduct } from '../../../../utilities/schemas/product';

const productInformationFields: Record<ProductTypes, React.FC<{
  showEdit: boolean;
}>> = {
  [productTypes.SWISH]: SwishProductInformation,
};

const ProductInformationField = ({
  showEdit,
}: {
  showEdit: boolean;
}) => {
  const product = useRecordContext<IProduct>();
  const productType = product?.productType;

  if (!productType) return null;

  const Field = productInformationFields[productType];
  return <Field showEdit={showEdit} />;
};

export default ProductInformationField;
