import React from 'react';
import { ArrayField, SingleFieldList, useRecordContext } from 'react-admin';

import Answer from '../Answer';
import languages, { Languages } from '../../../../constants/languages';
import EnhancedFileField from '../../../../customFields/EnhancedFileField';

const TITLE_SINGLE = {
  [languages.SV]: 'Ladda upp fil',
  [languages.EN]: 'Upload file',
};

const TITLE_MULTIPLE = {
  [languages.SV]: 'Ladda upp filer',
  [languages.EN]: 'Upload files',
};

const ValueField = ({
  formId,
}: {
  formId?: string;
}) => {
  if (!formId) return null;

  return (
    <ArrayField source="value" emptyText="-">
      <SingleFieldList linkType={false} direction="column" gap={0} sx={{ marginTop: 1 }}>
        <EnhancedFileField source="_id" title="name" target="_blank" parentId={formId} resource="form-answers" />
      </SingleFieldList>
    </ArrayField>
  );
};

const FileUpload = ({ extraParameters }: {
  extraParameters?: {
    multiple?: boolean;
    accept?: string;
    id?: string;
    label?: string;
  };
}) => {
  const record = useRecordContext();

  if (!extraParameters?.id) return null;

  const language = record?.language as Languages;
  const defaultLabel = extraParameters.multiple
    ? TITLE_MULTIPLE[language]
    : TITLE_SINGLE[language];
  const formId = record?.id;
  if (!formId || typeof formId !== 'string') return null;

  return (
    <Answer
      label={extraParameters.label ?? defaultLabel}
      source={`fileUpload.${extraParameters.id}`}
      datapoint={null}
    >
      <ValueField formId={formId} />
    </Answer>
  );
};

FileUpload.ValueField = ValueField;

export default FileUpload;
