import React, { useCallback, useState } from 'react';

import {
  List,
  usePermissions,
  useRecordContext,
  useRefresh,
  useUpdate,
} from 'react-admin';

import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Dialog,
  IconButton,
  Tab,
  Tabs,
} from '@mui/material';

import CloseIcon from '@mui/icons-material/Close';
import { Add, AddCircle } from '@mui/icons-material';

import { useParams } from 'react-router-dom';
import useDialogStatus from '../../../hooks/useDialogStatus';

import Pagination from '../../layout/Pagination';

import { AccountEntityListBody, AccountEntityFilters } from '../../accountEntity/AccountEntityList';
import { IndividualFilters, IndividualListBody } from '../../individual/IndividualList';
import { BusinessFilters, BusinessListBody } from '../../business/BusinessList';
import { CarFilters, CarListBody } from '../../car/CarList';
import { ProductFilters, ProductListBody } from '../../product/ProductList';

import hasAccess from '../../../utilities/hasAccess';

import entityTypes, { EntityTypes, resourceFromEntityTypes } from '../../../constants/entityTypes';
import actions, { Actions } from '../../../constants/actions';
import FilterActions from '../../filters/FilterActions';

const LIST_BODY = {
  [entityTypes.INDIVIDUAL]: IndividualListBody,
  [entityTypes.BUSINESS]: BusinessListBody,
  [entityTypes.CAR]: CarListBody,
  [entityTypes.ACCOUNT]: AccountEntityListBody,
  [entityTypes.PRODUCT]: ProductListBody,
} as const;

const LIST_FILTERS = {
  [entityTypes.INDIVIDUAL]: IndividualFilters,
  [entityTypes.BUSINESS]: BusinessFilters,
  [entityTypes.CAR]: CarFilters,
  [entityTypes.ACCOUNT]: AccountEntityFilters,
  [entityTypes.PRODUCT]: ProductFilters,
} as const;

const AddButton = ({
  closeDialog,
  entityType,
  attachedEntities,
}: {
  closeDialog: () => void;
  entityType: EntityTypes;
  attachedEntities: {id: string; type: EntityTypes}[];
}) => {
  const { id } = useParams();
  const record = useRecordContext();
  const [update] = useUpdate();
  const refetch = useRefresh();

  const handleClick = useCallback(() => {
    update('cases', {
      id: `${id}/attach-entity`,
      data: {
        entityId: record?.id,
        entityType,
      },
    }, {
      mutationMode: 'pessimistic',
      onSuccess: () => {
        closeDialog();
        refetch();
      },
    });
  }, [closeDialog, entityType, id, record?.id, refetch, update]);

  return (
    <Button
      startIcon={<AddCircle />}
      onClick={handleClick}
      disabled={attachedEntities.some((entity) => entity.id === record?.id)}
    >
      Add
    </Button>
  );
};

const AddEntities = () => {
  const record = useRecordContext();
  const { permissions } = usePermissions<Actions[]>();
  const [value, setValue] = useState<EntityTypes>(entityTypes.INDIVIDUAL);
  const { open, openDialog, closeDialog } = useDialogStatus();

  const ListBody = LIST_BODY[value];
  const resource = resourceFromEntityTypes[value];
  const filters = LIST_FILTERS[value];

  const onChange = useCallback((e: React.SyntheticEvent, newValue: EntityTypes) => {
    setValue(newValue);
  }, []);

  return (
    <>
      <Button
        sx={{ width: '100%' }}
        startIcon={<Add />}
        onClick={openDialog}
      >
        Attach Entities
      </Button>
      <Dialog scroll="body" transitionDuration={500} open={open} fullWidth maxWidth="xl">
        <Card style={{ margin: 0 }}>
          <CardHeader
            title="Attach Entities"
            action={(
              <IconButton onClick={closeDialog}>
                <CloseIcon />
              </IconButton>
            )}
          />
          <CardContent>
            <Box sx={{ borderBottom: 1, borderColor: 'divider', mb: 4 }}>
              <Tabs value={value} onChange={onChange}>
                <Tab label="Individual" value={entityTypes.INDIVIDUAL} />
                <Tab label="Business" value={entityTypes.BUSINESS} />
                <Tab label="Account" value={entityTypes.ACCOUNT} />
                {hasAccess(permissions, actions.CAR_LIST) && <Tab label="Car" value={entityTypes.CAR} />}
              </Tabs>
            </Box>
            <List
              disableSyncWithLocation
              actions={<FilterActions />}
              resource={resource}
              filters={filters}
              empty={false}
              pagination={<Pagination disableEmptyText />}
            >
              <Card sx={{ margin: 0 }} variant="outlined">
                <ListBody>
                  <AddButton
                    entityType={value}
                    closeDialog={closeDialog}
                    attachedEntities={record?.entities}
                  />
                </ListBody>
              </Card>
            </List>
          </CardContent>
        </Card>
      </Dialog>
    </>
  );
};

export default AddEntities;
