import React from 'react';
import { NavLink } from 'react-router-dom';

import {
  Chip,
  Collapse,
  ListItemButton,
  ListItemText,
  styled,
  lighten,
} from '@mui/material';
import { rgba } from 'polished';

import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { ResourceDefinition } from 'react-admin';
import theme from '../../../theme';

const Title = styled(ListItemText)(({ depth }: { depth: number }) => ({
  margin: 0,
  '& span': {
    color: rgba(theme.sidebar.color, depth && depth > 0 ? 0.7 : 1),
    fontSize: theme.typography.body1.fontSize,
    padding: `0 ${theme.spacing(4)}`,
  },
}));

const Badge = styled(Chip)(() => ({
  fontWeight: theme.typography.fontWeightBold,
  height: 20,
  position: 'absolute',
  right: 26,
  top: 12,
  background: theme.sidebar.badge.background,
  zIndex: 1,
  '& span.MuiChip-label, span.MuiChip-label:hover': {
    fontSize: 11,
    cursor: 'pointer',
    color: theme.sidebar.badge.color,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
}));

const ExpandLessIcon = styled(ExpandLess)`
  color: ${(props) => rgba(props.theme.sidebar.color, 0.5)};
`;

const ExpandMoreIcon = styled(ExpandMore)`
  color: ${(props) => rgba(props.theme.sidebar.color, 0.5)};
`;

const SidebarNavListItem = ({
  title,
  href,
  depth = 0,
  children,
  icon: Icon,
  badge,
  exact,
  open: openProp = false,
  onClose,
}: {
  title: string;
  href: string;
  depth: number;
  children?: React.ReactNode;
  icon: React.ElementType;
  badge: ResourceDefinition['icon'];
  exact: boolean;
  open?: boolean;
  onClose?: () => void;
}) => {
  const [open, setOpen] = React.useState(openProp);

  const handleToggle = () => {
    setOpen((state) => !state);
  };

  if (children) {
    return (
      <>
        <ListItemButton
          component={NavLink}
          to={href}
          onClick={handleToggle}
          sx={{
            paddingTop: theme.spacing(depth && depth > 0 ? 2 : 3),
            paddingBottom: theme.spacing(depth && depth > 0 ? 2 : 3),
            paddingLeft: theme.spacing(depth && depth > 0 ? 14 : 8),
            paddingRight: theme.spacing(depth && depth > 0 ? 4 : 7),
            fontWeight: theme.typography.fontWeightRegular,
            '& svg': {
              color: theme.sidebar.color,
              fontSize: 20,
              width: 25,
              height: 25,
              opacity: 0.5,
            },
            ':hover': {
              background: lighten(theme.sidebar.background, 0.05),
              color: theme.sidebar.color,
            },
            '&.active': {
              backgroundColor: lighten(theme.sidebar.background, 0.10),
              '& span': {
                color: theme.sidebar.color,
              },
            },
          }}
        >
          {Icon && <Icon />}
          <Title depth={depth}>
            {title}
            {badge && <Badge label={badge} />}
          </Title>
          {open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        </ListItemButton>
        <Collapse in={open}>{children}</Collapse>
      </>
    );
  }

  return (
    <ListItemButton
      component={NavLink}
      to={href}
      end={exact}
      onClick={onClose}
      sx={{
        paddingTop: theme.spacing(depth && depth > 0 ? 2 : 3),
        paddingBottom: theme.spacing(depth && depth > 0 ? 2 : 3),
        paddingLeft: theme.spacing(depth && depth > 0 ? 14 : 8),
        paddingRight: theme.spacing(depth && depth > 0 ? 4 : 7),
        fontWeight: theme.typography.fontWeightRegular,
        '& svg': {
          color: theme.sidebar.color,
          fontSize: 20,
          width: 25,
          height: 25,
          opacity: 0.5,
        },
        ':hover': {
          background: lighten(theme.sidebar.background, 0.05),
          color: theme.sidebar.color,
        },
        '&.active': {
          backgroundColor: lighten(theme.sidebar.background, 0.10),
          '& span': {
            color: theme.sidebar.color,
          },
        },
      }}
    >
      {Icon && <Icon />}
      <Title depth={depth}>
        {title}
        {badge && <Badge label={badge} />}
      </Title>
    </ListItemButton>
  );
};

export default SidebarNavListItem;
