import React from 'react';
import { useMutation } from 'react-query';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import {
  Box,
  IconButton,
  CircularProgress,
  Typography,
} from '@mui/material';
import {
  useDataProvider, useNotify, useRecordContext,
} from 'react-admin';
import ErrorIcon from '@mui/icons-material/Error';
import { IReport } from '../utilities/schemas/report';

const DownloadFileButton = ({
  recordId,
  fileId,
  fileName,
}: {
  recordId: string;
  fileId: string;
  fileName: string;
}) => {
  const dataProvider = useDataProvider();
  const notify = useNotify();

  const {
    mutate: handleDownloadClick,
    isLoading,
  } = useMutation(async () => {
    try {
      const response = await dataProvider.getOne('reports/download', { id: `${recordId}/${fileId}` });
      window.open(response.data.downloadUrl, '_blank');
    } catch (e) {
      notify('Error: Could not download report', { type: 'warning' });
    }
  });

  return (
    <Box display="flex" alignItems="center">
      <Typography margin={2}>
        {fileName}
      </Typography>
      <IconButton
        onClick={() => handleDownloadClick()}
        color="secondary"
        disabled={isLoading}
      >
        <CloudDownloadIcon />
      </IconButton>
    </Box>
  );
};

const DownloadReports = () => {
  const record = useRecordContext<IReport>();

  switch (record?.status) {
    case 'loading':
      return <CircularProgress size="1rem" />;
    case 'ready':
      return (
        <Box>
          {record?.files.map((file) => (
            <DownloadFileButton
              key={file.id}
              recordId={record?.id}
              fileId={file.id}
              fileName={file.fileName}
            />
          ))}
        </Box>
      );
    default:
      return <ErrorIcon />;
  }
};

export default DownloadReports;
