import React from 'react';

import {
  Show,
  TextField,
  DateField,
  TabbedShowLayout,
  Tab,
  ReferenceManyField,
  Datagrid,
  ReferenceField,
  ArrayField,
  useRedirect,
  Pagination,
  usePermissions,
  useRecordContext,
  RaRecord,
  ChipField,
  SelectField,
} from 'react-admin';

import {
  Box,
  Card,
  Grid,
  Typography,
  IconButton,
  CardHeader,
  CardContent,
  Divider,
} from '@mui/material';

import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ScienceIcon from '@mui/icons-material/Science';

import ReferenceEntityField from '../../customFields/ReferenceEntityField';
import ReferenceMemberField from '../../customFields/ReferenceMemberField';
import StatusField from '../../customFields/StatusField';
import ReferenceScoreField from '../../customFields/ReferenceScoreField';
import ColoredScoreField from '../../customFields/ColoredScoreField';
import ReferenceRiskClassField from '../../customFields/ReferenceRiskClassField';

import hasAccess from '../../utilities/hasAccess';

import { boldDataGridStyle } from '../../constants/style/datagridStyles';

import PingRequestList from './components/PingRequestList';
import UpdatePingStatus from './components/UpdatePingStatus';
import PingBreadcrumb from './PingBreadcrumb';

import NoResults from '../layout/NoResults';
import CommentList from '../comment/CommentList';
import actions, { Actions } from '../../constants/actions';
import { ruleNameChoices } from '../rules/ruleFeatures';
import useValidateId from '../../hooks/useValidateId';
import resourceTypes from '../../constants/resourceTypes';
import CompositeTextField from '../layout/CompositeTextField';

const PingRule = () => {
  const ping = useRecordContext();

  if (!ping?.rule) return null;

  return (
    <Grid item xs={12} md={12}>
      <Box display="flex" flexDirection="column">
        <Typography variant="smallTitle">Rule</Typography>
        <ReferenceField source="rule" reference="rules" link={(record: RaRecord) => `/rules/${record.id}/summary`}>
          <CompositeTextField sortable={false}>
            <SelectField sx={{ textDecoration: 'underline' }} source="name" choices={ruleNameChoices} />
            <TextField sx={{ textDecoration: 'underline' }} source="customName" />
          </CompositeTextField>
        </ReferenceField>
      </Box>
    </Grid>
  );
};

const IsTestPing = () => {
  const record = useRecordContext();

  if (!record?.isTestPing) return null;

  return (
    <ChipField icon={<ScienceIcon />} record={{ value: 'Test Ping' }} color="primary" source="value" />
  );
};

const PingShow = () => {
  const { permissions } = usePermissions<Actions[]>();
  const redirect = useRedirect();
  const validatedId = useValidateId();
  if (!validatedId.isValid) return null;

  return (
    <Show actions={(
      <Box display="flex" justifyContent="flex-end">
        <IsTestPing />
      </Box>
    )}
    >
      <PingBreadcrumb />
      <TabbedShowLayout>
        <Tab label="Information">
          <Grid container spacing={6}>
            <Grid item xs={12} md={12} />
            <Grid item xs={12} md={12} display="flex" justifyContent="center">
              <Typography variant="largeTitle" color="primary">Ping Detailed View</Typography>
            </Grid>
            <Grid item xs={12} md={12}>
              <Box display="flex" justifyContent="space-between">
                <IconButton onClick={() => redirect('list', 'pings')} size="small">
                  <ArrowBackIosIcon color="primary" fontSize="inherit" />
                </IconButton>
                {hasAccess(permissions, 'PingUpdateStatus') && <UpdatePingStatus />}
              </Box>
            </Grid>
            <Grid item xs={12} lg={6}>
              <Card variant="outlined">
                <CardHeader title="Ping Details" />
                <Divider />
                <CardContent>
                  <Grid container spacing={6}>
                    <Grid item xs={12} md={6}>
                      <Box display="flex" flexDirection="column">
                        <Typography variant="smallTitle">ID</Typography>
                        <TextField source="id" />
                      </Box>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Box display="flex" flexDirection="column">
                        <Typography variant="smallTitle">Status</Typography>
                        <StatusField source="status" />
                      </Box>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Box display="flex" flexDirection="column">
                        <Typography variant="smallTitle">Score</Typography>
                        <ColoredScoreField source="score" />
                      </Box>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Box display="flex" flexDirection="column">
                        <Typography
                          variant="smallTitle"
                        >
                          Score expiry date
                        </Typography>
                        <DateField source="riskExpiryDate" showTime emptyText="-" />
                      </Box>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Box display="flex" flexDirection="column">
                        <Typography variant="smallTitle">Created at</Typography>
                        <DateField source="createdAt" showTime />
                      </Box>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Box display="flex" flexDirection="column">
                        <Typography variant="smallTitle">Expiry date</Typography>
                        <DateField source="expiryDate" showTime emptyText="-" />
                      </Box>
                    </Grid>
                    <Grid item xs={12} md={12}>
                      <Box display="flex" flexDirection="column">
                        <Typography variant="smallTitle">Description</Typography>
                        <TextField source="description" emptyText="-" />
                      </Box>
                    </Grid>
                    <PingRule />
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} lg={6}>
              <CommentList action={actions.PING_ADD_COMMENT} source="comments" />
            </Grid>
            <Grid item xs={12} md={12}>
              <Card variant="outlined">
                <CardHeader title="Entities" />
                <Divider />
                <ArrayField source="entities">
                  <Datagrid sx={boldDataGridStyle} empty={<NoResults />} bulkActionButtons={false}>
                    <TextField sortable={false} source="id" />
                    <ReferenceEntityField source="" label="Display name" />
                    <TextField sortable={false} source="type" />
                    <ReferenceScoreField sortable label="Risk score" />
                    <ReferenceRiskClassField label="Risk class" />
                  </Datagrid>
                </ArrayField>
              </Card>
            </Grid>
            <Grid item xs={12} md={12}>
              <Card variant="outlined">
                <CardHeader title="Request" />
                <Divider />
                <PingRequestList />
              </Card>
            </Grid>
          </Grid>
        </Tab>
        {hasAccess(permissions, 'AuditTrackList') && (
          <Tab label="Audit Tracks">
            <Grid container>
              <Grid item xs={12} md={12}>
                <Card variant="outlined">
                  <ReferenceManyField
                    reference="audit-tracks"
                    target="resource.id"
                    filter={{ 'resource.type': resourceTypes.PING }}
                    sort={{ field: 'date', order: 'DESC' }}
                    pagination={<Pagination />}
                  >
                    <Datagrid sx={boldDataGridStyle} bulkActionButtons={false}>
                      <TextField sortable={false} source="id" />
                      <ReferenceMemberField label="User" />
                      <TextField sortable={false} source="description" />
                      <DateField sortable source="date" showTime />
                    </Datagrid>
                  </ReferenceManyField>
                </Card>
              </Grid>
            </Grid>
          </Tab>
        )}
      </TabbedShowLayout>
    </Show>
  );
};

export default PingShow;
