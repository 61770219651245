import React, { useCallback, useContext } from 'react';
import {
  Button,
  CircularProgress,
  Box,
} from '@mui/material';
import {
  Form,
  TextInput,
  required,
  BooleanInput,
  regex,
} from 'react-admin';

import InputGroup from '../InputGroup';
import ButtonGroup from '../ButtonGroup';

import LoginContext from '../login/LoginContext';

type AliasFormFields = {
  alias?: string;
  rememberAlias?: boolean;
}

const AliasPage = () => {
  const loginContext = useContext(LoginContext);
  const { saveAccountAlias, loginMethodsLoading } = loginContext ?? {};

  const submit = useCallback(async (data: AliasFormFields) => {
    if (!saveAccountAlias || !data.alias) return;
    const { alias, rememberAlias } = data;

    saveAccountAlias(alias, rememberAlias);
  }, [saveAccountAlias]);

  return (
    <Form onSubmit={submit}>
      {loginMethodsLoading
        ? (
          <Box display="flex" justifyContent="center" margin={4}>
            <CircularProgress />
          </Box>
        ) : (
          <InputGroup>
            <TextInput
              source="alias"
              label="Account alias"
              validate={[required(), regex(/^[A-Za-z0-9_-]+$/, 'Invalid account alias format. Only letters, numbers, underscores (_) and dashes (-) are allowed. Check that there are no trailing spaces for example.')]}
              fullWidth
            />
            <BooleanInput
              source="rememberAlias"
              label="Remember my account alias"
              defaultValue
            />
          </InputGroup>
        )}
      <ButtonGroup>
        <Button
          variant="contained"
          type="submit"
          color="primary"
          disabled={loginContext?.loginMethodsLoading}
        >
          Next
        </Button>
      </ButtonGroup>
    </Form>
  );
};

export default AliasPage;
