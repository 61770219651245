import { useCallback } from 'react';

import {
  useCreate,
  useGetOne,
  useNotify,
  usePermissions,
  useRefresh,
  useResourceContext,
} from 'react-admin';

import { useParams } from 'react-router-dom';

import errorNotification from '../utilities/errorNotification';
import hasAccess from '../utilities/hasAccess';

import { Actions } from '../constants/actions';

const useEntityData = <T extends { id: string }>({
  source,
  getAction,
  resource,
}: {
  source: string;
  getAction: Actions;
  resource?: string;
}) => {
  const { id } = useParams();
  const { permissions } = usePermissions<Actions[]>();
  const resourceParam = useResourceContext();
  const refresh = useRefresh();
  const notify = useNotify();

  const [purchase, { isLoading }] = useCreate(
    `${resource ?? resourceParam}/${source}/${id}`,
    undefined,
    {
      onSuccess: () => {
        refresh();
        notify('Successful', { type: 'success' });
      },
      onError: (error) => {
        notify(errorNotification(error), { type: 'error' });
      },
    },
  );

  const { data } = useGetOne<T>(
    `${resource ?? resourceParam}/${source}`,
    { id: id! },
    { enabled: !!id && hasAccess(permissions, getAction) },
  );

  const handlePurchase = useCallback(() => {
    purchase();
  }, [purchase]);

  return { handlePurchase, isLoading, data };
};

export default useEntityData;
