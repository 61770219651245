import {
  Identifier,
  useGetIdentity,
  useGetOne,
  useRefresh,
} from 'react-admin';

type Member = {
  id: Identifier;
  firstName?: string;
  lastName?: string;
  email?: string;
  accountName?: string;
  accountId?: string;
  occupation?: string;
  birthDate?: Date;
  swedishSocialSecurityNumber?: string;
  residence?: string;
  foreignId?: string;
  foreignIdCountry?: string;
}

const useMember = () => {
  const refresh = useRefresh();
  const { identity } = useGetIdentity();

  const { data } = useGetOne<Member>('members', { id: identity?.id! }, { enabled: !!identity?.id });

  return {
    member: data,
    refreshMember: refresh,
  };
};

export default useMember;
