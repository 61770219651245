import React from 'react';

import {
  Create,
  SimpleForm,
  TextInput,
  required,
  ReferenceArrayInput,
  SelectArrayInput,
  SelectInput,
  DateInput,
} from 'react-admin';

import {
  Card, CardContent, CardHeader, Divider, Grid,
} from '@mui/material';
import validateSsn from '../../utilities/validators/ssn';
import cleaner from '../../utilities/dataProvider/cleaner';

import countries from '../../constants/countries';
import { NationalIdentifierInputFields } from '../../customFields/NationalIdentifier';

const IndividualCreate = () => (
  <Create transform={cleaner}>
    <SimpleForm mode="all" validate={validateSsn}>
      <Grid container spacing={6}>
        <Grid item xs={12} md={4}>
          <TextInput fullWidth validate={required()} source="customId" label="Custom id" />
        </Grid>
        <Grid item xs={12} md={4}>
          <TextInput source="firstName" fullWidth />
        </Grid>
        <Grid item xs={12} md={4}>
          <TextInput source="lastName" fullWidth />
        </Grid>
        <Grid item xs={12} md={4}>
          <SelectInput source="residence" choices={countries} fullWidth />
        </Grid>
        <Grid item xs={12} md={4}>
          <ReferenceArrayInput label="Groups" source="groups" reference="groups" fullWidth>
            <SelectArrayInput optionText="name" fullWidth />
          </ReferenceArrayInput>
        </Grid>
        <Grid item xs={12} md={4}>
          <TextInput source="email" fullWidth />
        </Grid>
        <Grid item xs={12} md={4}>
          <TextInput source="phoneNumber" fullWidth />
        </Grid>
        <Grid item xs={12} md={4}>
          <DateInput source="birthDate" fullWidth />
        </Grid>
        <Grid item xs={12} md={8}>
          <Card variant="outlined">
            <CardHeader
              sx={{
                color: 'black',
                fontWeight: 'bold',
                flexWrap: 'wrap',
              }}
              titleTypographyProps={{ variant: 'inherit' }}
              title="National identifiers"
            />
            <Divider />
            <CardContent>
              <NationalIdentifierInputFields />
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </SimpleForm>
  </Create>
);

export default IndividualCreate;
