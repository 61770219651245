import React, { useState, useCallback } from 'react';

import {
  Button,
  Box,
  Typography,
} from '@mui/material';

import {
  ChipField,
  FormDataConsumer,
  Labeled,
  ReferenceArrayField,
  ReferenceArrayInput,
  SelectArrayInput,
  SingleFieldList,
  UpdateMutationFunction,
  UpdateParams,
  useRegisterMutationMiddleware,
  UseUpdateOptions,
} from 'react-admin';

import CreateIcon from '@mui/icons-material/Create';
import CancelIcon from '@mui/icons-material/Cancel';

import ColoredChipField from './ColoredChipField';

const ReferenceManyEditField = ({
  withColor,
  showEdit,
  label,
  source,
  reference,
  title,
} : {
  withColor?: boolean;
  showEdit: boolean;
  label: string;
  source: string;
  reference: string;
  title?: string;
}) => {
  const [editable, setEditable] = useState(false);
  const handleChange = () => {
    setEditable(!editable);
  };

  const callback = useCallback(async (
    resource: string | undefined,
    params: Partial<UpdateParams> | undefined,
    options: UseUpdateOptions | undefined,
    next: UpdateMutationFunction,
  ) => {
    const existingSuccessCallback = options?.onSuccess;
    const newSuccessCallback: UseUpdateOptions['onSuccess'] = existingSuccessCallback ? (...args) => {
      setEditable(false);
      return existingSuccessCallback(...args);
    } : () => {
      setEditable(false);
    };
    const newOptions = {
      ...options,
      onSuccess: newSuccessCallback,
    };
    return next(resource, params, newOptions);
  }, []);

  useRegisterMutationMiddleware<UpdateMutationFunction>(callback);

  if (!editable) {
    return (
      <Labeled label={title ?? label}>
        <Box pt={1} display="flex" alignItems="center" justifyContent="space-between" flexWrap="wrap">
          <ReferenceArrayField
            page={1}
            perPage={200}
            label={label}
            reference={reference}
            source={source}
            filter={{ active: true }}
          >
            <FormDataConsumer>
              {({ formData }) => (formData?.[reference]?.length > 0 ? (
                <SingleFieldList>
                  {withColor ? <ColoredChipField /> : <ChipField source="name" />}
                </SingleFieldList>
              ) : <Typography>None</Typography>)}
            </FormDataConsumer>
          </ReferenceArrayField>
          {showEdit && (
            <Button startIcon={<CreateIcon color="secondary" />} size="small" onClick={handleChange}>
              Edit
            </Button>
          )}
        </Box>
      </Labeled>
    );
  }
  return (
    <Labeled label={title ?? label}>
      <Box display="flex" alignItems="center" justifyContent="space-between" flexWrap="wrap">
        <ReferenceArrayInput
          page={1}
          perPage={200}
          label={label}
          source={source}
          reference={reference}
          filter={{ active: true }}
        >
          <SelectArrayInput optionText="name" />
        </ReferenceArrayInput>
        <Button startIcon={<CancelIcon color="error" />} size="small" onClick={handleChange}>
          Cancel
        </Button>
      </Box>
    </Labeled>
  );
};

export default ReferenceManyEditField;
