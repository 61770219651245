import React from 'react';
import { Grid } from '@mui/material';

import Car, { prefillCar } from './Car';
import entityTypes, { EntityTypes } from '../../../../../../constants/entityTypes';
import { ICar } from '../../../../../../utilities/schemas/car';

export const prefillGoodsGetter = (type: EntityTypes) => (record: ICar) => {
  if (type === entityTypes.CAR) {
    return prefillCar(record);
  }
  throw new Error('Unsupported entity type');
};

const GoodsForm = ({
  type,
}: {
  type: EntityTypes;
}) => (
  <Grid>
    {type === entityTypes.CAR && <Car />}
  </Grid>
);
export default GoodsForm;
