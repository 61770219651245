import React, { useState, useCallback, useEffect } from 'react';

import {
  useRefresh,
  useNotify,
  useRecordContext,
  useUpdate,
  TextInput,
  required,
  SelectInput,
  NumberInput,
  minValue,
  Form,
  useUpdateMany,
  useUnselectAll,
  useListContext,
} from 'react-admin';

import {
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  Grid,
  DialogActions,
  Button,
  Typography,
  Slide,
  Fade,
} from '@mui/material';

import { SubmitHandler, useFormContext } from 'react-hook-form';
import useDialogStatus from '../../../hooks/useDialogStatus';

import { recommendationsChoices } from '../../../constants/recommendations';
import InputSlider from '../../layout/inputFields/InputSlider';
import pingStatuses from '../../../constants/pingStatuses';

type HandlePingForm = {
  status: string;
  comment: string;
  overrideRecommendation?: string;
  numberOfDaysIgnoreRule?: number;
  isBinaryRule: boolean;
};

const UpdatePingDialog = ({
  closeDialog,
  updateType,
  onSubmit,
  variant,
}: {
  closeDialog: () => void;
  updateType: string;
  onSubmit: SubmitHandler<HandlePingForm>;
  variant: 'bulk' | 'single';
}) => {
  const { handleSubmit } = useFormContext<HandlePingForm>();

  const record = useRecordContext();

  return (

    <>
      <DialogContent>
        <Grid container spacing={6}>
          <Grid item xs={12} md={12}>
            <Grid container spacing={1}>
              <Grid item xs={12} md={12}>
                <Typography variant="label">
                  {variant === 'bulk' && `Comment on the reason for why you want to bulk ${updateType.toLowerCase()} the pings`}
                  {variant === 'single' && `Comment on the reason for why you want to ${updateType.toLowerCase()} the ping`}
                </Typography>
              </Grid>
              <Grid item xs={12} md={12}>
                <TextInput fullWidth source="comment" multiline minRows={3} validate={required()} />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={12}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={12}>
                <Typography variant="label">
                  What should be the recommendation for future
                  requests with the same underlying rule?
                </Typography>
              </Grid>
              <Grid item xs={12} md={12}>
                <SelectInput
                  defaultValue="proceed"
                  label="Future recommendation"
                  choices={recommendationsChoices}
                  fullWidth
                  source="overrideRecommendation"
                  validate={required()}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={12}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={12}>
                <Typography variant="label">
                  For how long should the system use this decision for future requests?
                  (During that time pings won&apos;t be created for that rule(s) and
                  recommendation will be inferred from the decision)
                </Typography>
              </Grid>
              <Grid item xs={12} md={12}>
                <NumberInput fullWidth source="numberOfDaysIgnoreRule" validate={[minValue(0), required()]} />
              </Grid>
            </Grid>
          </Grid>
          {record && record.status === pingStatuses.CONFIRMED && (
            <Grid item xs={12} md={12}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={12}>
                  <Typography variant="label">
                    What score should be applied to the ping?
                  </Typography>
                </Grid>
                <Grid item xs={12} md={12}>
                  <InputSlider source="score" required />
                </Grid>
              </Grid>
            </Grid>
          )}
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={closeDialog} color="error">
          Cancel
        </Button>
        <Button variant="contained" onClick={handleSubmit(onSubmit)} color="primary">
          Save
        </Button>
      </DialogActions>
    </>
  );
};

const UpdateButtonGroup = ({
  handleClick,
}: {
  handleClick: React.MouseEventHandler<HTMLButtonElement & { }>;
}) => (
  <Box display="flex" gap={2}>
    <Button
      style={{ backgroundColor: '#880808', color: '#FFFFFF' }}
      variant="contained"
      onClick={handleClick}
      value="confirmed"
    >
      Confirm
    </Button>
    <Button
      variant="contained"
      color="primary"
      onClick={handleClick}
      value="resolved"
    >
      Resolve
    </Button>
    <Button
      variant="contained"
      color="inherit"
      onClick={handleClick}
      value="ignored"
    >
      Ignore
    </Button>
  </Box>
);

export const BulkUpdate = ({
  open,
  closeDialog,
}: {
  open: boolean;
  closeDialog: () => void;
}) => {
  const refresh = useRefresh();
  const notify = useNotify();
  const [update] = useUpdateMany();
  const unselectAll = useUnselectAll('cases');

  const [updateType, setUpdateType] = useState<string | null>(null);
  const [status, setStatus] = useState<string | null>(null);

  const { selectedIds } = useListContext();

  const onSubmit = useCallback((data: HandlePingForm) => {
    update(
      'pings',
      {
        ids: selectedIds.map((id) => `${id}/status`),
        data,
      },
      {
        onSuccess: () => {
          refresh();
          closeDialog();
          unselectAll();
        },
        onError: () => {
          notify('Could not update ping status', { type: 'error' });
        },
      },
    );
  }, [closeDialog, notify, refresh, selectedIds, unselectAll, update]);

  useEffect(() => {
    if (!open) { setUpdateType(null); }
  }, [open]);

  const handleClick = useCallback(({
    currentTarget: { innerText, value },
  }: React.MouseEvent<HTMLButtonElement>) => {
    setUpdateType(innerText);
    setStatus(value);
  }, []);

  return (
    <Dialog
      BackdropProps={{
        style: {
          backgroundColor: 'rgba(0, 0, 0, 0.2)',
        },
      }}
      open={open}
      onClose={closeDialog}
      transitionDuration={0}
    >
      <DialogTitle variant="h4">
        Bulk handle the Pings
      </DialogTitle>
      <Box display={updateType ? 'none' : 'block'}>
        <DialogContent>
          <Fade in>
            <Box>
              <UpdateButtonGroup handleClick={handleClick} />
            </Box>
          </Fade>
        </DialogContent>
      </Box>
      <Slide in={!!updateType} appear={false} direction="right">
        <Box display={!updateType ? 'none' : 'block'}>
          <Form mode="all" record={{ status, isBinaryRule: true }}>
            <UpdatePingDialog
              closeDialog={closeDialog}
              onSubmit={onSubmit}
              updateType={updateType ?? ''}
              variant="bulk"
            />
          </Form>
        </Box>
      </Slide>

    </Dialog>
  );
};

const UpdatePingStatus = ({ resource = 'pings' }: { resource?: string; label?: string; source?: string }) => {
  const refresh = useRefresh();
  const notify = useNotify();
  const record = useRecordContext();
  const { open, openDialog, closeDialog } = useDialogStatus();
  const [update] = useUpdate();

  const [updateType, setUpdateType] = useState<string | null>(null);
  const [status, setStatus] = useState<string | null>(null);

  const handleClick = useCallback(({
    currentTarget: { value, innerText },
  }: React.MouseEvent<HTMLButtonElement>) => {
    setStatus(value);
    openDialog();
    setUpdateType(innerText);
  }, [openDialog]);

  const onSubmit = useCallback((data: HandlePingForm) => {
    update(
      'pings',
      {
        id: `${record?.id}/status`,
        data,
      },
      {
        onSuccess: () => {
          closeDialog();
          refresh();
        },
        onError: () => {
          notify('Could not update ping status', { type: 'error' });
        },
      },
    );
  }, [closeDialog, notify, record?.id, refresh, update]);

  return (
    <Box display="flex">
      <UpdateButtonGroup handleClick={handleClick} />
      <Dialog open={open} onClose={closeDialog} fullWidth maxWidth="md">
        <DialogTitle variant="h4">
          {`${updateType} the Ping`}
        </DialogTitle>
        <Form mode="all" resource={resource} record={{ ...record, status }}>
          <UpdatePingDialog
            closeDialog={closeDialog}
            onSubmit={onSubmit}
            updateType={updateType ?? ''}
            variant="single"
          />
        </Form>
      </Dialog>
    </Box>
  );
};

export default UpdatePingStatus;
