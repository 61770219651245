import React from 'react';

import {
  ReferenceField,
  useRecordContext,
  TextField,
  FunctionField,
  TextFieldProps,
} from 'react-admin';

import LinkField from '../components/layout/LinkField';

const ReferenceMemberField = ({ ...props }: TextFieldProps) => {
  const record = useRecordContext(props);

  if (record?.authenticator.type === 'ApiKey') {
    return (
      <ReferenceField label="Member" source="authenticator.id" reference="api-keys" link="show">
        <LinkField source="clientId" />
      </ReferenceField>
    );
  }

  if (record?.authenticator.type === 'Member') {
    return (
      <ReferenceField label="Member" source="authenticator.id" reference="members" link="edit">
        <FunctionField
          sx={{ textDecoration: 'underline' }}
          render={(member: {firstName: string; lastName: string}) => `${member.firstName} ${member.lastName}`}
        />
      </ReferenceField>
    );
  }

  return (
    <TextField emptyText="System" />
  );
};

ReferenceMemberField.defaultProps = {
  addLabel: true,
};
export default ReferenceMemberField;
