import React from 'react';

import { isArray, isEmpty } from 'lodash';

import {
  ArrayField,
  DateField,
  RecordContextProvider,
  useRecordContext,
  SelectField,
  FunctionField,
  useRedirect,
  DatagridConfigurable,
  SelectColumnsButton,
} from 'react-admin';

import {
  Box,
  Card,
  CardHeader,
  Divider,
  Grid,
  IconButton,
  Slide,
  Typography,
} from '@mui/material';

import { ArrowForwardIosOutlined } from '@mui/icons-material';

import useEntityData from '../../../../../hooks/useEntityData';

import actions from '../../../../../constants/actions';
import { boldDataGridStyle } from '../../../../../constants/style/datagridStyles';

import ImageField from '../../../../../customFields/ImageField';
import { ViewJSONDialog } from '../../../../../customFields/ViewJSON';

import NoResults from '../../../../layout/NoResults';
import PurchaseButton from '../../../../layout/button/PurchaseButton';

import EntityData from '../EntityData';

import person from '../../../../../assets/exposure/person.png';
import { PepMatches } from '../../../../../utilities/schemas/pepMatches';
import { relatedEntityRelationsText } from '../../../../../constants/relatedEntityRelations';
import ColoredScoreField from '../../../../../customFields/ColoredScoreField';
import { countryChoices } from '../../../../../constants/countries';
import languages from '../../../../../constants/languages';

const RedirectToEntityButton = ({ label }: { label?: string }) => {
  const redirect = useRedirect();
  const record = useRecordContext<NonNullable<PepMatches['potentialMatches'][number]>>();
  if (isEmpty(record)) return null;
  return (
    <IconButton onClick={() => redirect(`${record._id}`)} aria-label={label}>
      <ArrowForwardIosOutlined />
    </IconButton>
  );
};

const PepBody = ({
  data,
  handlePurchase,
  isLoading,
}: {
  data: PepMatches | undefined;
  handlePurchase: () => void;
  isLoading: boolean;
}) => (
  <RecordContextProvider value={data}>
    <Card variant="outlined">
      <CardHeader
        title="Politically Exposed Person (PEP) Records"
        subheader={(
          <Box display="flex" alignItems="baseline">
            <Typography>Latest update:</Typography>
            <DateField sx={{ marginLeft: 1, fontWeight: 'bold' }} source="createdAt" emptyText="-" showTime />
          </Box>
        )}
        action={(
          <Box display="flex" gap={4}>
            <SelectColumnsButton color="secondary" />
            <ViewJSONDialog color="secondary" title="Inspect" />
            <PurchaseButton
              loading={isLoading}
              label="Get PEP Records"
              onClick={handlePurchase}
              empty={!isArray(data?.potentialMatches)}
              action={actions.INDIVIDUAL_CREATE_PEP}
              icon={null}
              color="secondary"
            />
          </Box>
        )}
      />
      <Divider />
      <ArrayField source="potentialMatches">
        <DatagridConfigurable empty={<NoResults variant="h6" />} sx={{ ...boldDataGridStyle, width: '100%' }} bulkActionButtons={false}>
          <FunctionField
            label="Name"
            sx={{ whiteSpace: 'pre' }}
            render={(record: PepMatches['potentialMatches'][number]) => {
              if (!isEmpty(record.fullName)) return record.fullName;
              const name = `${record.firstName ?? ''} ${record.lastName ?? ''}`;
              return isEmpty(name.trim()) ? '-' : name;
            }}
          />
          <ColoredScoreField source="matchProbability" label="Match %" />
          <DateField source="birthDate" label="Birth date" />
          <SelectField source="address.country" choices={countryChoices(languages.EN)} label="Country" />
          <FunctionField
            label="Role description"
            sx={{ whiteSpace: 'pre' }}
            render={(record: PepMatches['potentialMatches'][number]) => {
              if (record.pepRoles.length === 0) return '-';
              return record.pepRoles
                .map((role) => role.roleDescription ?? role.role)
                .slice(0, 5).join('\n');
            }}
          />
          <FunctionField
            label="Relations"
            sx={{ whiteSpace: 'pre' }}
            render={(record: PepMatches['potentialMatches'][number]) => {
              if (record.relatedEntities.length === 0) return '-';
              return record.relatedEntities
                .map((relatedEntity) => [
                  relatedEntity.entityName,
                  relatedEntity.relationshipType
                    ? relatedEntityRelationsText[relatedEntity.relationshipType] : undefined,
                ].filter((text) => !!text).join(' - '))
                .slice(0, 5).join('\n');
            }}
          />
          <ImageField label="Image" source="profileImages[0]" emptyImage={person} />
          <RedirectToEntityButton />
        </DatagridConfigurable>
      </ArrayField>
    </Card>
  </RecordContextProvider>
);

const Pep = () => {
  const {
    data,
    handlePurchase,
    isLoading,
  } = useEntityData<PepMatches>({
    source: 'pep',
    getAction: actions.INDIVIDUAL_GET_PEP,
  });

  return (
    <Slide in direction="right" appear={false}>
      <Grid container spacing={3}>
        <Grid item xs={12} md={12}>
          <EntityData />
        </Grid>
        <Grid item xs={12} md={12}>
          <PepBody data={data} handlePurchase={handlePurchase} isLoading={isLoading} />
        </Grid>
      </Grid>
    </Slide>
  );
};

export default Pep;
