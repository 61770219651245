import React, {
  useCallback, useEffect, useMemo, useRef, useState,
} from 'react';

import {
  DateField,
  RaRecord,
  useInfiniteGetList,
} from 'react-admin';

import {
  Typography,
  Box,
  MenuItem,
  Select,
  ToggleButton,
  ToggleButtonGroup,
  Button,
  SelectChangeEvent,
  Toolbar,
  AppBar,
} from '@mui/material';

import RefreshIcon from '@mui/icons-material/Refresh';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import CircleIcon from '@mui/icons-material/Circle';
import ScienceIcon from '@mui/icons-material/Science';
import EditIcon from '@mui/icons-material/Edit';

import {
  isBoolean,
  isEmpty,
  isString,
  uniqBy,
} from 'lodash';

import { RuleTypes } from '../../../../constants/ruleTypes';
import NoResults from '../../../layout/NoResults';

const Custom = ({ vers }: { vers: RaRecord }) => (
  <Box display="flex">
    <Typography>
      {`Version ${vers?.number}, \xa0`}
    </Typography>
    <DateField record={vers} source="date" showTime />
    <Typography>
      {`,\xa0 ${vers?.description}`}
    </Typography>
  </Box>
);

const VersionSelect = ({
  version,
  changeVersion,
  ruleType,
}: {
  version: string;
  changeVersion: (x: string) => void;
  ruleType: RuleTypes;
}) => {
  const [isTest, setIsTest] = useState<boolean>(false);
  const [open, setOpen] = useState(false);
  const [choices, setChoices] = useState<{
    id: string;
    name: string;
    number: number;
    custom: React.JSX.Element;
  }[]>([]);

  const ref = useRef<Element>(null);

  const {
    data, hasNextPage, fetchNextPage, refetch,
  } = useInfiniteGetList<{ id: string; number: number }>('rule-version', { filter: { ruleType, isTestVersion: isTest } });

  useEffect(() => {
    setChoices([]);
  }, [isTest, ruleType]);

  const pages = useMemo(() => data?.pages.map((item) => item.data).flat(), [data?.pages]);

  useEffect(() => {
    if (pages) {
      setChoices((prev) => uniqBy([
        ...prev,
        ...(pages?.map((vers) => ({
          id: vers.id,
          name: `Version ${vers.number}`,
          custom: <Custom vers={vers} />,
          number: vers.number,
        })) ?? []),
      ], 'id').sort((a, b) => b.number - a.number));
    }
  }, [pages]);

  const handelChangeOpen = useCallback(() => {
    refetch();
    setOpen((prev) => !prev);
  }, [refetch]);

  const handleLoadMore = useCallback((e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    e.stopPropagation();
    fetchNextPage();
  }, [fetchNextPage]);

  const handelChange = useCallback((event: SelectChangeEvent) => {
    if (isString(event.target.value)) changeVersion(event.target.value as string);
  }, [changeVersion]);

  const handelChangeIsTestVersion = useCallback((
    e: React.MouseEvent<HTMLElement, MouseEvent>,
    newValue: boolean,
  ) => {
    e.stopPropagation();
    if (isBoolean(newValue)) setIsTest(newValue);
  }, [setIsTest]);

  const CurrentChoice = useMemo(
    () => choices.find((choice) => choice.id === version),
    [choices, version],
  );

  if (!pages) {
    return null;
  }

  return (
    <Box>
      <Box ref={ref} height="100%">
        <Button
          sx={{ height: 'inherit' }}
          startIcon={!open ? <ArrowDropDownIcon /> : <ArrowDropUpIcon />}
          variant="text"
          color="primary"
          size="small"
          onClick={handelChangeOpen}
          fullWidth
        >
          {CurrentChoice?.name ?? 'Draft'}
        </Button>
      </Box>
      <Select
        MenuProps={{
          anchorEl: () => ref.current!,
          MenuListProps: {
            disablePadding: true,
            sx: {
              minWidth: 300,
            },
          },
        }}
        style={{ display: 'none' }}
        open={open}
        onClose={handelChangeOpen}
        onOpen={handelChangeOpen}
        value={!CurrentChoice ? '' : version}
        onChange={handelChange}
        fullWidth
        color="primary"
      >
        <AppBar position="sticky" color="inherit">
          <Toolbar>
            <Box display="flex" flexDirection="column" width="100%" gap={2} py={2}>
              <ToggleButtonGroup
                fullWidth
                color="primary"
                value={isTest}
                exclusive
                onChange={handelChangeIsTestVersion}
              >
                <ToggleButton value={false}>
                  <Box display="flex" alignItems="center" gap={1}>
                    <CircleIcon color={isTest ? 'inherit' : 'red'} sx={{ fontSize: 10 }} />
                    <Typography>Live</Typography>
                  </Box>
                </ToggleButton>
                <ToggleButton value>
                  <Box display="flex" alignItems="center" gap={1}>
                    <ScienceIcon />
                    <Typography>Test</Typography>
                  </Box>
                </ToggleButton>
              </ToggleButtonGroup>
              <ToggleButton
                value="draft"
                color="primary"
                selected={version === 'draft'}
                onChange={() => changeVersion('draft')}
              >
                <Box display="flex" alignItems="center" gap={1}>
                  <EditIcon />
                  <Typography>Draft</Typography>
                </Box>
              </ToggleButton>
            </Box>
          </Toolbar>
        </AppBar>
        {choices.map((choice) => (
          <MenuItem
            key={choice.id}
            value={choice.id}
          >
            {choice.custom}
          </MenuItem>
        ))}
        {isEmpty(choices) && <NoResults variant="body1" />}
        {(hasNextPage) && (
          <Button
            onClickCapture={handleLoadMore}
            fullWidth
            startIcon={<RefreshIcon />}
          >
            Load more
          </Button>
        )}
      </Select>
    </Box>
  );
};

export default VersionSelect;
