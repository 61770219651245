import React, { useCallback } from 'react';

import {
  Form,
  RadioButtonGroupInput,
  required,
  TextInput,
  useCreate,
  useListContext,
  useNotify,
} from 'react-admin';

import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Typography,
} from '@mui/material';

import { FieldValues } from 'react-hook-form';

import PublishIcon from '@mui/icons-material/Publish';

import useDialogStatus from '../../../../hooks/useDialogStatus';

import { RuleTypes } from '../../../../constants/ruleTypes';

const Publish = ({ disabled, ruleType } : { disabled: boolean; ruleType: RuleTypes }) => {
  const { refetch } = useListContext();
  const notify = useNotify();

  const { open, openDialog, closeDialog } = useDialogStatus();
  const [publish] = useCreate();

  const handelSave = useCallback((data: FieldValues) => {
    publish('rules/publish', { data: { ...data, ruleType } }, {
      onSuccess: () => {
        notify('Rule draft was published', { type: 'success' });
        closeDialog();
        refetch();
      },
      onError: () => {
        notify('Rule draft could not be published', { type: 'error' });
        refetch();
      },
    });
  }, [closeDialog, notify, publish, refetch, ruleType]);

  return (
    <>
      <Button
        color="primary"
        startIcon={<PublishIcon />}
        onClick={openDialog}
        disabled={disabled}
      >
        Publish
      </Button>
      <Dialog onClose={closeDialog} open={open} fullWidth maxWidth="sm">
        <Form
          onSubmit={handelSave}
        >
          <DialogTitle>Publish Rule Tree Draft</DialogTitle>
          <DialogContent>
            <Box display="flex" flexDirection="column" gap={4}>
              <Box>
                <Typography>Should the rule tree be published as a test version?</Typography>
                <RadioButtonGroupInput
                  source="isTestVersion"
                  label=""
                  choices={[{ id: true, name: 'Yes' }, { id: false, name: 'No' }]}
                  validate={[required()]}
                />
              </Box>
              <TextInput
                multiline
                maxRows={4}
                source="description"
                fullWidth
                sx={{ marginTop: '1%' }}
                label="Description of version"
                validate={[required()]}
              />
              <Box paddingTop="5%" gap={2} display="flex" justifyContent="flex-end">
                <Button onClick={closeDialog} color="error" variant="contained">Close</Button>
                <Button type="submit" variant="contained">Confirm</Button>
              </Box>
            </Box>
          </DialogContent>
        </Form>
      </Dialog>
    </>
  );
};

export default Publish;
