import React, { useMemo } from 'react';

import {
  RecordContextProvider,
  RaRecord,
  useRecordContext,
} from 'react-admin';
import { get, isEqual } from 'lodash';

import { Box, Grid, Typography } from '@mui/material';

import { Condition, FormElement, IQuestionElement } from '../types';

import elementTypes from '../../../constants/elementTypes';
import answersComponents from './answersComponents';
import { Languages } from '../../../constants/languages';
import formConditionTypes from '../../../constants/formConditionTypes';
import getQuestionPathMap from '../getQuestionPathMap';

const isQuestionElement = (
  element: FormElement,
): element is IQuestionElement => element.type === elementTypes.QUESTION
&& Object.keys(answersComponents).includes(element.id);

const ConditionWrapper = ({
  children,
  condition,
}: {
  children: React.ReactNode;
  condition: Condition | undefined;
}) => {
  const kycForm = useRecordContext();

  if (!condition) return children;

  const isValid = condition.or.some(({ and }) => and.every(({
    otherQuestion,
    otherQuestionExtraParameters,
    type,
    conditionParameters,
  }) => {
    const otherQuestionPath = getQuestionPathMap[otherQuestion](otherQuestionExtraParameters);
    if (!otherQuestionPath) return true;

    const otherQuestionValue = get(kycForm, otherQuestionPath);

    if (type === formConditionTypes.EQUALITY) {
      return isEqual(otherQuestionValue, conditionParameters.value);
    }

    return false;
  }));

  return isValid ? children : null;
};

const RenderElements = ({
  elements,
  title,
  language,
  kycForm,
}: {
  elements: FormElement[];
  title: string;
  language: Languages;
  kycForm: RaRecord;
}) => {
  const questions = useMemo(
    () => elements
      .filter(isQuestionElement),
    [elements],
  );

  return (
    <Box display="flex" flexDirection="column" gap={4} my="1%">
      <Typography variant="h5" color="primary">{title}</Typography>
      <RecordContextProvider value={{ language, ...kycForm }}>
        <Grid container spacing={6}>
          {questions.map((question) => (
            <ConditionWrapper condition={question.condition} key={`${question.id}-${question?.extraParameters?.id ?? 'default'}`}>
              {React.createElement(answersComponents[question.id], {
                extraParameters: question?.extraParameters,
                question: question.id,
              })}
            </ConditionWrapper>
          ))}
        </Grid>
      </RecordContextProvider>
    </Box>
  );
};

export default RenderElements;
