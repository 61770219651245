import React from 'react';

import {
  ArrayField,
  ChipField,
  Datagrid,
  DateField,
  SingleFieldList,
  TextField,
} from 'react-admin';
import { Alpha3Code } from 'i18n-iso-countries';

import {
  Box,
  Card,
  CardHeader,
  Grid,
  CardContent,
  Typography,
  Divider,
} from '@mui/material';

import { isEmpty } from 'lodash';
import useEntityData from '../../../../hooks/useEntityData';

import PurchaseButton from '../../../layout/button/PurchaseButton';
import NoResults from '../../../layout/NoResults';

import { boldDataGridStyle } from '../../../../constants/style/datagridStyles';
import actions from '../../../../constants/actions';
import { EntityTypes } from '../../../../constants/entityTypes';

export type BoardMembers = {
  id: string;
  createdAt: string;
  boardMembers: {
    entityId: string;
    entityType: EntityTypes;
    firstName: string;
    surName: string;
    fullName: string;
    nationalIdentifier?: {
      country: Alpha3Code;
      identifier: string;
    }[];
    birthDate?: string;
    company: boolean;
    residence: Alpha3Code;
    citizenship: Alpha3Code[];
    id: string;
    roles: {
      fromDate: string;
      toDate: string;
      roleCode: string;
      roleName: string;
    }[];
    type: string;
  }[];
}

type Signatories = {
  id: string;
  createdAt: string;
  signatory: {
    records: {
      changeDate: string;
      companyId: string;
      companySignatory: string;
    }[];
  };
}

type SigningCombinations = {
  id: string;
  createdAt: string;
  signingCombinations: {
    records: {
      adminSign:{
        anomalies: {
          anomalyCode: number;
          anomalyDescription: string;
        }[];
        name: string;
        personalNumber: string;
        positions:{
          roleCode: number;
          roleName: string;
        }[];
      }[];
      changeDate: string;
      combinations:{
        anomalies: {
          anomalyCode: number;
          anomalyDescription: string;
        }[];
        name: string;
        personalNumber: string;
        positions: {
          roleCode: number;
          roleName: string;
        }[];
      }[][];
      companyId: string;
      coverage: string;
    }[];
  };
}

const useCompanyRepresentation = () => {
  const {
    data: boardMembersData,
    handlePurchase: handlePurchaseBoardMembers,
    isLoading: isLoadingBoardMembers,
  } = useEntityData<BoardMembers>({
    resource: 'businesses',
    source: 'board-members',
    getAction: actions.BUSINESS_GET_COMPANY_REP,
  });
  const {
    data: signatoryData,
    handlePurchase: handlePurchaseSignatory,
    isLoading: isLoadingSignatory,
  } = useEntityData<Signatories>({
    resource: 'businesses',
    source: 'signatories',
    getAction: actions.BUSINESS_GET_OWNERSHIP,
  });
  const {
    data: signingCombinationsData,
    handlePurchase: handlePurchaseSigningCombinations,
    isLoading: isLoadingSigningCombinations,
  } = useEntityData<SigningCombinations>({
    resource: 'businesses',
    source: 'signing-combinations',
    getAction: actions.BUSINESS_GET_OWNERSHIP,
  });

  return {
    boardMembersData,
    signatoryData,
    signingCombinationsData,
    handlePurchaseSigningCombinations,
    handlePurchaseBoardMembers,
    handlePurchaseSignatory,
    isLoading: isLoadingBoardMembers || isLoadingSignatory || isLoadingSigningCombinations,
  };
};

const CompanyRepresentation = () => {
  const {
    boardMembersData,
    signatoryData,
    signingCombinationsData,
    handlePurchaseBoardMembers,
    handlePurchaseSignatory,
    handlePurchaseSigningCombinations,
    isLoading,
  } = useCompanyRepresentation();

  const boardMembers = boardMembersData?.boardMembers
    ?.map((member) => ({
      ...member,
      roles: member?.roles.map((role, index) => ({
        name: `${role?.roleName} ${role?.fromDate ? `(${role?.fromDate}${role.toDate ? ` -> ${role.toDate}` : ''})` : ''}`,
        id: index,
      })),
    }));

  const signatoryCombinations = signingCombinationsData?.signingCombinations
    ?.records?.filter((record) => record)[0]
    ?.combinations
    ?.map((combination, index) => ({
      combination: combination.map((person) => ({ info: `${person.name} (${person.personalNumber})`, id: person.personalNumber })),
      index: index + 1,
    }));

  return (
    <Grid container spacing={6}>
      <Grid item xs={12} md={12}>
        <Card variant="outlined">
          <CardHeader
            title="Board Members"
            subheader={(
              <Box display="flex" alignItems="baseline">
                <Typography>Latest update:</Typography>
                <DateField
                  record={{
                    createdAt: boardMembersData?.createdAt,
                  }}
                  sx={{ marginLeft: 1, fontWeight: 'bold' }}
                  source="createdAt"
                  emptyText="-"
                  showTime
                />
              </Box>
            )}
            action={(
              <PurchaseButton
                loading={isLoading}
                label="Get Board Members"
                onClick={handlePurchaseBoardMembers}
                action={actions.BUSINESS_CREATE_COMPANY_REP}
              />
            )}
          />
          <Divider />
          <ArrayField record={{ boardMembers }} source="boardMembers">
            <Datagrid sx={boldDataGridStyle} empty={<NoResults />} bulkActionButtons={false}>
              <TextField sortable={false} source="fullName" />
              <TextField sortable={false} source="id" label="Id" />
              <ArrayField sortable={false} source="roles" label="Roles (from date -> to date)">
                <SingleFieldList linkType={false}>
                  <ChipField source="name" />
                </SingleFieldList>
              </ArrayField>
            </Datagrid>
          </ArrayField>
        </Card>
      </Grid>
      <Grid item xs={12} md={12}>
        <Card variant="outlined">
          <CardHeader
            title="Company Signatories"
            subheader={(
              <Box display="flex" alignItems="baseline">
                <Typography>Latest update:</Typography>
                <DateField
                  record={{
                    createdAt: signatoryData?.createdAt,
                  }}
                  sx={{ marginLeft: 1, fontWeight: 'bold' }}
                  source="createdAt"
                  emptyText="-"
                  showTime
                />
              </Box>
            )}
            action={(
              <PurchaseButton
                loading={isLoading}
                label="Get Signatories"
                onClick={handlePurchaseSignatory}
                action={actions.BUSINESS_CREATE_COMPANY_REP}
              />
            )}
          />
          <Divider />
          {isEmpty(signatoryData?.signatory?.records) && <NoResults />}
          {!isEmpty(signatoryData?.signatory?.records) && (
            <CardContent>
              {signatoryData?.signatory?.records?.map((record) => (
                <Grid container spacing={6} key={`${record.companySignatory}-${record.changeDate}`}>
                  <Grid item xs={6} md={4}>
                    <Box>
                      <Typography variant="smallTitle">Change Date</Typography>
                      <Typography>
                        {record.changeDate ?? '-'}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={6} md={7}>
                    <Box>
                      <Typography variant="smallTitle">Signatory</Typography>
                      <Typography>
                        {record.companySignatory}
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
              ))}
            </CardContent>
          )}
          <Divider />
          <CardHeader
            title="Signatories Combinations"
            subheader={(
              <Box display="flex" alignItems="baseline">
                <Typography>Latest update:</Typography>
                <DateField
                  record={{
                    createdAt: signingCombinationsData?.createdAt,
                  }}
                  sx={{ marginLeft: 1, fontWeight: 'bold' }}
                  source="createdAt"
                  emptyText="-"
                  showTime
                />
              </Box>
            )}
            action={(
              <PurchaseButton
                loading={isLoading}
                label="Get Signatories Combinations"
                onClick={handlePurchaseSigningCombinations}
                action={actions.BUSINESS_CREATE_COMPANY_REP}
              />
            )}
          />
          <Divider />
          <ArrayField record={{ signatoryCombinations }} source="signatoryCombinations">
            <Datagrid empty={<NoResults />} sx={boldDataGridStyle} bulkActionButtons={false}>
              <TextField source="index" />
              <ArrayField source="combination" label="Name (SNN)">
                <SingleFieldList linkType={false}>
                  <ChipField source="info" />
                </SingleFieldList>
              </ArrayField>
            </Datagrid>
          </ArrayField>
        </Card>
      </Grid>
    </Grid>
  );
};

export default CompanyRepresentation;
