import React, { useCallback, useState } from 'react';
import {
  Control,
  FieldValues,
  useFieldArray,
  useFormContext,
} from 'react-hook-form';
import { isUndefined } from 'lodash';

import {
  Dialog,
  Box,
  Typography,
  IconButton,
  DialogContent,
  Grid,
  Accordion,
  AccordionSummary,
  Button,
  DialogActions,
  AccordionDetails,
  AccordionActions,
} from '@mui/material';

import CancelIcon from '@mui/icons-material/Cancel';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AddIcon from '@mui/icons-material/Add';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import EditIcon from '@mui/icons-material/Edit';
import {
  Form,
  RecordContextProvider,
  ValidateForm,
  ValidationError,
  Validator,
  composeValidators,
} from 'react-admin';
import CircleContainer from '../../../layout/CircleContainer';

const ArrayInput = ({
  DialogBody,
  AccordionBody,
  title,
  itemNameSource,
  ItemLabel,
  transformItemData,
  rootField = 'value',
  validate,
  validateItem,
}: {
  DialogBody: ({ ...params }: { control: Control<any>; index: number }) => React.JSX.Element;
  AccordionBody?: ({ ...params }: { index: number }) => React.JSX.Element | null;
  title: string;
  itemNameSource: string;
  ItemLabel?: ({ ...params }: { value: Record<string, string> }) => React.JSX.Element | null;
  transformItemData?: (data: any) => any;
  rootField?: string;
  validate?: Validator | Validator[];
  validateItem?: ValidateForm;
}) => {
  const { getValues, getFieldState } = useFormContext();

  const [open, setOpen] = useState(false);
  const [currentIndex, setCurrentIndex] = useState<number | undefined>(undefined);

  const handleClose = useCallback(() => {
    setOpen(false);
  }, []);

  const sanitizedValidate = Array.isArray(validate)
    ? composeValidators(validate)
    : validate;

  const {
    fields,
    remove,
    append,
    update,
  } = useFieldArray({
    name: rootField,
    rules: {
      validate: async (value, values) => {
        if (!sanitizedValidate) return true;
        const error = await sanitizedValidate(value, values, {});

        if (!error) return true;
        return `@@react-admin@@${JSON.stringify(error)}`;
      },
    },
  });

  const handleSubmitItem = useCallback((data: FieldValues) => {
    const transformedData = transformItemData ? transformItemData(data) : data;
    if (!isUndefined(currentIndex)) {
      update(currentIndex, transformedData);
    } else {
      append(transformedData);
    }
    setOpen(false);
  }, [append, currentIndex, transformItemData, update]);

  const handleEdit = useCallback((index: number | undefined) => () => {
    setCurrentIndex(index);
    setOpen(true);
  }, []);

  const currentData = !isUndefined(currentIndex) ? getValues(rootField)?.at(currentIndex) : {};

  const fieldState = getFieldState(rootField);

  return (
    <Grid container>
      <Grid item xs={12} md={12}>
        <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
          <Box display="flex" justifyContent="space-between" p="3%">
            <Typography variant="h6" alignItems="center">{title}</Typography>
            <IconButton sx={{ p: 0 }} onClick={handleClose}>
              <CancelIcon />
            </IconButton>
          </Box>
          <Form
            defaultValues={currentData}
            onSubmit={handleSubmitItem}
            validate={validateItem}
            sanitizeEmptyValues
          >
            <DialogContent>
              {React.createElement(DialogBody)}
            </DialogContent>
            <DialogActions sx={{ display: 'flex', justifyContent: 'center', p: '2%' }}>
              <Button variant="contained" color="primary" type="submit">
                Save
              </Button>
            </DialogActions>
          </Form>
        </Dialog>
      </Grid>
      <Grid item xs={12} md={12}>
        <Grid container spacing={4}>
          {(fields as Record<string, any>[]).map((field, index) => (
            <Grid item xs={12} md={12} sx={{ p: 0 }} key={`array-accordion-item-${index + 1}`}>
              <Box display="flex" alignItems="center" gap={2} p={0} bgcolor="#F4F4F4">
                {AccordionBody && (
                  <Accordion
                    elevation={0}
                    sx={{
                      width: '100%',
                      px: 4,
                      py: 4,
                    }}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      sx={{ pr: 3 }}
                    >
                      {!ItemLabel && (
                        <Box display="flex" alignItems="center" gap={5}>
                          <CircleContainer p={0} bgcolor="primary.main" size={37}>
                            <Typography color="background.paper" variant="h6">{field[itemNameSource]?.at(0) ?? ''}</Typography>
                          </CircleContainer>
                          <Typography
                            variant="h6"
                            color="primary.main"
                          >
                            {field[itemNameSource]}
                          </Typography>
                        </Box>
                      )}
                      {ItemLabel && React.createElement(
                        ItemLabel,
                        { value: getValues(rootField)?.at(index) },
                      )}
                    </AccordionSummary>
                    <AccordionDetails>
                      <RecordContextProvider value={getValues(rootField)}>
                        {React.createElement(AccordionBody, { index })}
                      </RecordContextProvider>
                    </AccordionDetails>
                    <AccordionActions sx={{ pr: 0 }}>
                      <IconButton disableRipple onClick={handleEdit(index)}>
                        <EditIcon />
                      </IconButton>
                    </AccordionActions>
                  </Accordion>
                )}
                {(!AccordionBody && ItemLabel) && (
                  <Box display="flex" p={6} width="100%" justifyContent="space-between">
                    {React.createElement(ItemLabel, { value: field })}
                    <IconButton disableRipple onClick={handleEdit(index)}>
                      <EditIcon />
                    </IconButton>
                  </Box>
                )}
                <IconButton disableRipple onClick={() => remove(index)}>
                  <RemoveCircleOutlineIcon />
                </IconButton>
              </Box>
            </Grid>
          ))}
          {fieldState.error?.root?.message && (
            <Grid item xs={12}>
              <Typography color="error">
                <ValidationError error={fieldState.error?.root?.message} />
              </Typography>
            </Grid>
          )}
          <Grid item xs={12} md={12}>
            <Button onClick={handleEdit(undefined)} color="primary" endIcon={<AddIcon />}>
              Add
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ArrayInput;
