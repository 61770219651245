import React, { useState } from 'react';

import {
  useGetList,
  useRecordContext,
  ArrayField,
} from 'react-admin';

import {
  Box,
  Button,
  Dialog,
  Divider,
  CardHeader,
  Card,
  DialogActions,
  IconButton,
} from '@mui/material';

import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import CloseIcon from '@mui/icons-material/Close';
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';

import useDialogStatus from '../../hooks/useDialogStatus';
import useListFilter from '../../hooks/useListFilter';

import { RuleVersion } from '../dashboard/components/RuleTable';
import SelectInputButton from '../layout/inputFields/SelectInputButton';
import RuleDatagrid from '../rules/components/RuleDatagrid';

import ruleTypes from '../../constants/ruleTypes';
import { boldDataGridStyle } from '../../constants/style/datagridStyles';
import Pagination from '../layout/Pagination';

const SelectButton = ({
  handelChangeFilter,
  filterValues = [],
  source,
}: {
  handelChangeFilter: (filterValue: Record<string, any>) => void;
  filterValues: string[];
  source: string;
  label?: boolean | string;
}) => {
  const record = useRecordContext();

  if (!record) return null;

  const contains = filterValues.includes(record.id as string);

  if (contains) {
    return (
      <IconButton
        color="error"
        onClick={() => handelChangeFilter(
          { [source]: filterValues.filter((id) => id.toString() !== record.id.toString()) },
        )}
      >
        <RemoveCircleOutlineIcon />
      </IconButton>
    );
  }

  return (
    <IconButton
      color="primary"
      onClick={() => handelChangeFilter(
        { [source]: [...filterValues, record.id] },
      )}
    >
      <AddCircleOutlineIcon />
    </IconButton>
  );
};

const RuleFilter = ({ source } : { source: string; label: string }) => {
  const [ruleType, setRuleType] = useState(ruleTypes.REQUEST);
  const [version, setVersion] = useState('');

  const { data } = useGetList('rules', {
    filter: { ruleType, version },
    pagination: { page: 1, perPage: 500 },
  });

  const { open, openDialog, closeDialog } = useDialogStatus();
  const { handelChangeFilter, filterValues } = useListFilter({ source });

  return (
    <>
      <Button
        sx={{ width: '100%' }}
        startIcon={<PlaylistAddIcon />}
        variant="contained"
        onClick={openDialog}
      >
        {`Filter Rules (${filterValues[source]?.length ?? 0})`}
      </Button>
      <Dialog scroll="body" transitionDuration={500} open={open} fullWidth maxWidth="xl">
        <Card style={{ margin: 0 }}>
          <CardHeader
            title="Select Rules"
            action={(
              <Box display="flex" gap={2}>
                <SelectInputButton
                  choices={[
                    { id: 'request', name: 'Request rule' },
                    { id: 'ongoing', name: 'Ongoing rule' },
                  ]}
                  value={ruleType}
                  setValue={setRuleType}
                />
                <RuleVersion
                  value={version}
                  setValue={setVersion}
                  ruleType={ruleType}
                />
              </Box>
            )}

          />
          <Divider />
          {data && (
            <ArrayField source="data" record={{ data }} perPage={15}>
              <RuleDatagrid sx={{ ...boldDataGridStyle }} ruleType={ruleType}>
                <SelectButton
                  handelChangeFilter={handelChangeFilter}
                  filterValues={filterValues[source]}
                  source={source}
                  label={false}
                />
              </RuleDatagrid>
              <Pagination disableEmptyText />
            </ArrayField>
          )}
        </Card>
        <DialogActions>
          <Button startIcon={<CloseIcon />} onClick={closeDialog} color="error" variant="outlined">Close</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default RuleFilter;
