import React, { useCallback } from 'react';

import {
  Button,
  useRedirect,
} from 'react-admin';

import {
  Box,
  Grid,
  Card,
  Typography,
} from '@mui/material';

import ExitToAppIcon from '@mui/icons-material/ExitToApp';

import { Route, Routes } from 'react-router-dom';

import useFormWizardNav from './useFormWizardNav';

import FormWizardActions from './FormWizardActions';
import useRule from '../../../hooks/useRule';
import useRuleVersion from '../../../hooks/useRuleVersion';

export type FormPathProps = {
  path: string;
  children: React.ReactElement;
  label: string;
  hidden?: boolean;
}

export type FormWizardProps = {
  children: React.ReactElement<FormPathProps>[];
  edit: boolean;
}

export const FormPath = ({ children }: FormPathProps) => <Box>{children}</Box>;

const FormWizard = ({ children, edit }: FormWizardProps) => {
  const redirect = useRedirect();
  const {
    ruleType,
    view,
  } = useRule();
  const { version } = useRuleVersion();

  const {
    currentChild,
    next,
    previous,
    isFirstPage,
    isLastPage,
  } = useFormWizardNav(children);

  const handleExitClick = useCallback(() => {
    redirect('list', 'rules', undefined, {}, { record: { ruleType, view, version } });
  }, [redirect, ruleType, version, view]);

  return (
    <Grid container>
      <Grid item xs={12} md={12}>
        <Button size="medium" startIcon={<ExitToAppIcon />} onClick={handleExitClick} label="Exit" />
      </Grid>
      <Grid item xs={12} md={12}>
        <Card variant="outlined">
          <Grid container spacing={6} padding="2%" display="flex" justifyContent="center">
            <Grid item xs={12} md={12} lg={7} xl={5}>
              <Grid container spacing={6}>
                <Grid item xs={12} md={12}>
                  <Typography fontWeight="bold" align="center" color="primary" variant="h6">{currentChild?.props.label}</Typography>
                </Grid>
                <Grid item xs={12} md={12}>
                  <Routes>
                    {children.map((child) => <Route key={`${child.props.path}-create`} path={child.props.path} element={child} />)}
                    {children.map((child) => <Route key={`${child.props.path}-edit`} path={`/:id${child.props.path}`} element={child} />)}
                  </Routes>
                </Grid>
                <Grid item xs={12} md={12} display="flex" justifyContent="end">
                  <FormWizardActions
                    next={next}
                    isLastPage={isLastPage}
                    previous={previous}
                    isFirstPage={isFirstPage}
                    edit={edit}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Card>
      </Grid>
    </Grid>
  );
};

export default FormWizard;
