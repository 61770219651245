import React, { useState } from 'react';

import {
  ReferenceManyField,
} from 'react-admin';

import {
  Grid,
  Card,
  CardHeader,
  Divider,
} from '@mui/material';

import useRequestTypesChoices from '../hooks/useRequestTypesChoices';

import SelectInputButton from '../components/layout/inputFields/SelectInputButton';
import Pagination from '../components/layout/Pagination';

import { requestListMap } from '../constants/requestFeatures';
import { RequestTypes, requestTypes } from '../constants/requestTypes';

const LatestRelatedRequests = ({
  defaultType = requestTypes.TRANSACTION,
}: {
  defaultType?: RequestTypes;
}) => {
  const [requestType, setRequestType] = useState(defaultType);
  const { choices } = useRequestTypesChoices();

  const RequestListComponent = requestListMap[requestType];

  return (
    <Grid item xs={12} md={12}>
      <Card variant="outlined">
        <CardHeader
          title="Related Requests"
          action={(
            <SelectInputButton
              choices={choices}
              value={requestType}
              setValue={setRequestType}
            />
          )}
        />
        <Divider />
        <ReferenceManyField
          filter={{ requestType }}
          perPage={5}
          pagination={<Pagination disableEmptyText rowsPerPageOptions={[5, 10, 20]} />}
          reference="requests"
          target="custom.entity"
          sort={{ field: 'createdAt', order: 'DESC' }}
        >
          <RequestListComponent />
        </ReferenceManyField>
      </Card>
    </Grid>
  );
};

export default LatestRelatedRequests;
