import React, { useCallback } from 'react';

import {
  Form,
  required,
  TextInput,
  useCreate,
  useGetOne,
  useListContext,
  useNotify,
} from 'react-admin';

import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Typography,
} from '@mui/material';

import CloudQueueIcon from '@mui/icons-material/CloudQueue';

import useDialogStatus from '../../../../hooks/useDialogStatus';

const Release = ({
  disabled,
  version,
}: {
  disabled: boolean;
  version: string;
}) => {
  const { refetch } = useListContext();
  const { open, openDialog, closeDialog } = useDialogStatus();

  const { data: ruleVersion, isLoading } = useGetOne('rule-version', { id: version }, { enabled: !disabled });

  const notify = useNotify();
  const [release] = useCreate();

  const handleRelease = useCallback(({ description }: { description?: string }) => {
    release(
      'rules/release',
      { data: { description, ruleVersion: version } },
      {
        onSuccess: () => {
          notify('Rule tree was released', { type: 'success' });
          closeDialog();
          refetch();
        },
        onError: () => {
          notify('Rule tree could not be released', { type: 'error' });
        },
      },
    );
  }, [release, version, notify, closeDialog, refetch]);

  if (isLoading) return null;

  if (!ruleVersion?.isTestVersion) return null;

  if (ruleVersion?.isActive) return null;

  return (
    <>
      <Button
        color="primary"
        startIcon={<CloudQueueIcon />}
        onClick={openDialog}
        disabled={disabled}
      >
        Release
      </Button>
      <Dialog onClose={closeDialog} open={open} fullWidth maxWidth="sm">
        <Form onSubmit={handleRelease}>
          <DialogTitle>Go live with test rule tree </DialogTitle>
          <DialogContent>
            <Typography>
              The current test version will be released as a live version.
            </Typography>
            <Box display="flex" flexDirection="column" gap={4}>
              <TextInput
                source="description"
                multiline
                maxRows={4}
                fullWidth
                sx={{ marginTop: '1%' }}
                validate={required()}
                label="Description of release"
              />

              <Box paddingTop="5%" gap={2} display="flex" justifyContent="flex-end">
                <Button onClick={closeDialog} color="error" variant="contained">Close</Button>
                <Button type="submit" variant="contained">Release</Button>
              </Box>
            </Box>
          </DialogContent>
        </Form>
      </Dialog>
    </>
  );
};

export default Release;
