import React, { useEffect, useState } from 'react';

import { useGetOne } from 'react-admin';

import {
  Box,
  Card,
  CardHeader,
  CircularProgress,
  Typography,
} from '@mui/material';

import ReactApexChart from 'react-apexcharts';

import { isEmpty, startCase } from 'lodash';

import chroma from 'chroma-js';

import EntityType from './EntityType';
import theme from '../../../theme';

const useGetGraphData = (
  rawData: {
    riskDistribution: {
      riskClass: string;
      count: number;
    }[];
  },
) => {
  const [hasData, setHasData] = useState(false);
  const [options, setOptions] = useState<ReactApexChart['props']['options']>();
  const [series, setSeries] = useState<ReactApexChart['props']['series']>();

  useEffect(() => {
    if (isEmpty(rawData?.riskDistribution)) return;

    const newSeries = rawData.riskDistribution.map((dist) => dist.count) as ReactApexChart['props']['series'];
    const labels = rawData.riskDistribution.map((dist) => startCase(dist.riskClass));

    const newOptions: ApexCharts.ApexOptions = {
      labels,
      colors: chroma.scale([theme.palette.error.main, '#F3B61F', theme.palette.success.main]).mode('lch').colors(labels.length).reverse(),
      chart: {
        redrawOnParentResize: true,
        stacked: false,
        height: 400,
        zoom: {
          enabled: false,
        },
        toolbar: {
          show: false,
        },
      },
      legend: {
        offsetY: 200,
      },
      yaxis: {
        floating: false,
        decimalsInFloat: 0,
      },
      xaxis: {
        type: 'category',
        range: 10,

      },
    };

    setHasData(true);
    setOptions(newOptions);
    setSeries(newSeries);
  }, [rawData]);

  return {
    hasData,
    options,
    series,
  };
};

const RiskClassDistribution = () => {
  const [type, setType] = useState('All');
  const { data, isLoading } = useGetOne('dashboard', { id: `risk-dist/${type}` });
  const { hasData, options, series } = useGetGraphData(data);

  return (
    <Card style={{ margin: 0 }}>
      <CardHeader
        title="Risk class distribution"
        action={<EntityType type={type} setType={setType} />}
      />
      {hasData && <ReactApexChart options={options} series={series} type="pie" height={350} />}
      {isLoading && (
        <Box margin="4rem" display="flex" justifyContent="center">
          <CircularProgress />
        </Box>
      )}
      {(!hasData && !isLoading) && (
        <Box margin="4rem">
          <Typography align="center"> No Risk classes available </Typography>
        </Box>
      )}
    </Card>

  );
};

export default RiskClassDistribution;
