import React from 'react';
import {
  ReferenceArrayField,
  SingleFieldList,
  RaRecord,
  RecordContextProvider,
  SelectField,
  TextField,
} from 'react-admin';

import { isEmpty } from 'lodash';

import { Box, Typography } from '@mui/material';

import CheckCircleIcon from '@mui/icons-material/CheckCircle';

import { ruleNameChoices } from '../../ruleFeatures';
import TeamChip from '../../../../customFields/ColoredChipField';
import { Rule } from '../../../../utilities/schemas/rules';
import CompositeTextField from '../../../layout/CompositeTextField';

const Title = ({ rule }: { rule: Rule }) => {
  const isRiskBased = rule?.ruleParameters?.thresholds
    ?.some((threshold: RaRecord) => !isEmpty(threshold.riskBased));
  return (
    <Box gap={2} display="flex" flexDirection="row" alignItems="center">
      <RecordContextProvider
        value={rule}
      >
        <CompositeTextField sortable={false}>
          <SelectField sx={{ fontWeight: 'bold' }} source="name" choices={ruleNameChoices} />
          <TextField sx={{ fontWeight: 'bold' }} source="customName" />
        </CompositeTextField>
      </RecordContextProvider>
      {isRiskBased && (
        <Box display="flex" flexDirection="row">
          <CheckCircleIcon color="secondary" />
          <Typography>Risk-Based</Typography>
        </Box>
      )}
      <RecordContextProvider
        value={rule}
      >
        <ReferenceArrayField source="tags" reference="tags" label="Tags" emptyText="-">
          <SingleFieldList placeholder="-">
            <TeamChip small />
          </SingleFieldList>
        </ReferenceArrayField>
      </RecordContextProvider>
    </Box>
  );
};

export default Title;
