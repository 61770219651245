import React from 'react';
import {
  RecordContextProvider,
  SelectField,
  TextField,
  useRecordContext,
} from 'react-admin';
import { get, keyBy } from 'lodash';

import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import Answer from '../Answer';

type CustomExtraParameter = {
  label?: string;
  inputLabel?: string;
  id?: string;
  options?: {
    id: string;
    label: string;
  }[];
  summaryField?: string;
  multiple?: boolean;
  subDocumentSpecification?: CustomExtraParameter[];
}

const ValueField = ({
  extraParameters,
}: {
  extraParameters?: CustomExtraParameter;
}) => {
  const record = useRecordContext();

  const value = get(record, 'value');
  const sx = get(record, 'sx', {});

  if (extraParameters?.subDocumentSpecification) {
    if (extraParameters.multiple) {
      if (!Array.isArray(value)) return null;
      if (value.length === 0) return '-';

      const summarySpec = extraParameters.summaryField
        ? extraParameters.subDocumentSpecification
          .find(({ id }) => id === extraParameters.summaryField)
        : undefined;

      return (
        <Card variant="outlined">
          <Grid container bgcolor="background.paper">
            {value.map((item, index) => (
              <Grid key={`${extraParameters.id}-${index + 1}`} item xs={12} md={12}>
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ChevronRightIcon />}
                    sx={{
                      flexDirection: 'row-reverse',
                      '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
                        transform: 'rotate(90deg)',
                      },
                    }}
                  >
                    <Typography variant="h6">
                      {summarySpec ? (
                        <RecordContextProvider value={{ value: get(item, summarySpec.id ?? ''), sx: { fontWeight: 'normal' } }}>
                          <ValueField extraParameters={summarySpec} />
                        </RecordContextProvider>
                      ) : `Item #${index + 1}`}
                    </Typography>
                  </AccordionSummary>
                  <Divider />
                  <AccordionDetails>
                    <Grid container spacing={6} padding={2}>
                      {extraParameters.subDocumentSpecification?.map((spec) => (
                        <RecordContextProvider value={{ value: get(item, spec.id ?? '') }} key={spec.id}>
                          <Grid item xs={12} md={6}>
                            <Box display="flex" flexDirection="column">
                              <Typography variant="label">{spec.label}</Typography>
                              <ValueField extraParameters={spec} />
                            </Box>
                          </Grid>
                        </RecordContextProvider>
                      ))}
                    </Grid>
                  </AccordionDetails>
                </Accordion>
                {index + 1 !== value.length && <Divider />}
              </Grid>
            ))}
          </Grid>
        </Card>
      );
    }

    return (
      <Card variant="outlined">
        <Grid container spacing={6} padding={2}>
          {extraParameters.subDocumentSpecification?.map((spec) => (
            <RecordContextProvider value={{ value: get(value, spec.id ?? '') }} key={spec.id}>
              <Grid item xs={12} md={6}>
                <Box display="flex" flexDirection="column">
                  <Typography variant="label">{spec.label}</Typography>
                  <ValueField extraParameters={spec} />
                </Box>
              </Grid>
            </RecordContextProvider>
          ))}
        </Grid>
      </Card>
    );
  }

  if (extraParameters?.multiple) {
    if (extraParameters.options) {
      const mapFields = keyBy(
        extraParameters?.options?.map((option) => ({ id: option.id, name: option.label })),
        'id',
      );
      const mappedValues = value?.map((v: string) => get(mapFields, v, { name: v }).name);
      return (
        <TextField
          record={{ value: mappedValues?.join(', ') }}
          sx={sx}
          source="value"
          emptyText="-"
          fontWeight="bold"
        />
      );
    }

    return (
      <TextField
        record={{ value: value?.join(', ') }}
        sx={sx}
        source="value"
        emptyText="-"
        fontWeight="bold"
      />
    );
  }

  return extraParameters?.options ? (
    <SelectField
      choices={extraParameters?.options.map(({ id, label }) => ({ id, name: label }))}
      record={{ value }}
      sx={sx}
      source="value"
      emptyText="-"
      fontWeight="bold"
    />
  ) : (
    <TextField
      record={{ value }}
      sx={sx}
      source="value"
      emptyText="-"
      fontWeight="bold"
    />
  );
};

const Custom = ({ extraParameters }: {
  extraParameters?: CustomExtraParameter;
}) => {
  if (!extraParameters?.label) return null;
  if (!extraParameters.id) return null;

  return (
    <Answer
      label={extraParameters.label}
      source={`custom.${extraParameters.id}`}
      datapoint={null}
      isComplexType={!!extraParameters?.subDocumentSpecification}
    >
      <ValueField extraParameters={extraParameters} />
    </Answer>
  );
};

Custom.ValueField = ValueField;

export default Custom;
