import React, { useCallback } from 'react';

import {
  Datagrid,
  Form,
  ListContextProvider,
  useArrayInput,
  useList,
  useRecordContext,
} from 'react-admin';

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from '@mui/material';

import DeleteIcon from '@mui/icons-material/Delete';
import CancelIcon from '@mui/icons-material/Cancel';
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';

import { FieldValues, SubmitHandler } from 'react-hook-form';

import useDialogStatus from '../hooks/useDialogStatus';
import { boldDataGridStyle } from '../constants/style/datagridStyles';

const DeleteButton = () => {
  const { fields, remove } = useArrayInput();
  const record = useRecordContext<Record<'id', string>>();

  const handleRemove = useCallback(() => {
    if (!record?.id) return;

    const index = fields.findIndex((field) => field.id === record.id);
    remove(index);
  }, [fields, record?.id, remove]);

  return (
    <IconButton onClick={handleRemove}>
      <DeleteIcon />
    </IconButton>
  );
};

const EditDialogButton = <FormDataFormat, ModalDataFormat extends FieldValues>({
  editDialogContent,
  parse,
  format,
}: {
  editDialogContent: React.ReactNode;
  format?: (data: FormDataFormat) => ModalDataFormat;
  parse?: (data: ModalDataFormat) => FormDataFormat;
}) => {
  const { open, closeDialog, openDialog } = useDialogStatus();
  const { update, fields } = useArrayInput();
  const record = useRecordContext<FormDataFormat & {
    id: string;
  }>();

  const handleSubmit = useCallback((data: ModalDataFormat) => {
    if (!record?.id) return;

    const index = fields.findIndex((field) => field.id === record.id);
    update(index, parse ? parse(data) : data);
    closeDialog();
  }, [closeDialog, fields, parse, record?.id, update]);

  return (
    <>
      <Button startIcon={<EditIcon />} onClick={openDialog}>Edit</Button>
      <Dialog
        open={open}
        fullWidth
        maxWidth="xl"
        onClose={closeDialog}
      >
        <Form
          record={format && record ? format(record) : record}
          onSubmit={handleSubmit as SubmitHandler<FieldValues>}
        >
          <DialogTitle color="primary">
            <Box display="flex" alignItems="center" justifyContent="space-between">
              <Typography>Add</Typography>
              <IconButton onClick={closeDialog}>
                <CancelIcon />
              </IconButton>
            </Box>
          </DialogTitle>
          <DialogContent>
            {editDialogContent}
          </DialogContent>
          <DialogActions
            sx={{
              paddingBottom: 4,
              paddingX: 6,
            }}
          >
            <Button
              startIcon={<EditIcon />}
              color="primary"
              variant="text"
              type="submit"
            >
              Edit
            </Button>
          </DialogActions>
        </Form>
      </Dialog>
    </>
  );
};

const AddDialogButton = <FormDataFormat, ModalDataFormat extends FieldValues>({
  addDialogContent,
  parse,
}: {
  addDialogContent: React.ReactNode;
  parse?: (data: ModalDataFormat) => FormDataFormat;
}) => {
  const { append } = useArrayInput();
  const { open, closeDialog, openDialog } = useDialogStatus();

  const handleSubmit = useCallback((data: ModalDataFormat) => {
    append(parse ? parse(data) : data);
    closeDialog();
  }, [append, closeDialog, parse]);

  return (
    <>
      <Button startIcon={<AddIcon />} onClick={openDialog} variant="text">Add</Button>
      <Dialog
        open={open}
        fullWidth
        maxWidth="xl"
        onClose={closeDialog}
      >
        <Form onSubmit={handleSubmit as SubmitHandler<FieldValues>}>
          <DialogTitle color="primary">
            <Box display="flex" alignItems="center" justifyContent="space-between">
              <Typography>Add</Typography>
              <IconButton onClick={closeDialog}>
                <CancelIcon />
              </IconButton>
            </Box>
          </DialogTitle>
          <DialogContent>
            {addDialogContent}
          </DialogContent>
          <DialogActions
            sx={{
              paddingBottom: 4,
              paddingX: 6,
            }}
          >
            <Button
              startIcon={<AddIcon />}
              color="primary"
              variant="text"
              type="submit"
            >
              Add
            </Button>
          </DialogActions>
        </Form>
      </Dialog>
    </>
  );
};

const Empty = ({
  children,
}: {
  children: React.ReactNode;
}) => (
  <Box
    display="flex"
    flexDirection="column"
    justifyContent="center"
    alignItems="center"
    padding={4}
  >
    <Typography>No data</Typography>
    {children}
  </Box>
);

const DatagridIterator = <FormDataFormat, ModalDataFormat extends FieldValues>({
  editDialogContent,
  addDialogContent,
  rowContent,
  format,
  parse,
}: {
  editDialogContent?: React.ReactNode;
  addDialogContent: React.ReactNode;
  rowContent: React.ReactNode[];
  format?: (data: FormDataFormat) => ModalDataFormat;
  parse?: (data: ModalDataFormat) => FormDataFormat;
}) => {
  const { fields } = useArrayInput();
  const listContext = useList({
    data: fields,
  });

  return (
    <ListContextProvider value={listContext}>
      <Datagrid
        sx={boldDataGridStyle}
        empty={(
          <Empty>
            <AddDialogButton addDialogContent={addDialogContent} parse={parse} />
          </Empty>
        )}
        bulkActionButtons={false}
      >
        {rowContent}
        <Box display="flex" justifyContent="flex-end">
          <EditDialogButton
            editDialogContent={editDialogContent ?? addDialogContent}
            format={format}
            parse={parse}
          />
          <DeleteButton />
        </Box>
      </Datagrid>
      <Box display={fields.length > 0 ? 'flex' : 'none'}>
        <AddDialogButton addDialogContent={addDialogContent} parse={parse} />
      </Box>
    </ListContextProvider>
  );
};

export default DatagridIterator;
