import React from 'react';

import {
  RecordContextProvider,
  useRecordContext,
} from 'react-admin';

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Card,
  Divider,
  Grid,
  Typography,
} from '@mui/material';

import ChevronRightIcon from '@mui/icons-material/ChevronRight';

import { get, isEmpty } from 'lodash';

import FirstName from './FirstName';
import LastName from './LastName';
import TaxResidency from './TaxResidency';
import ControlType from './ControlType';
import FamilyControl from './FamilyControl';
import Citizenship from './Citizenship';
import Pep from './Pep';

import languages, { Languages } from '../../../../constants/languages';
import Answer from '../Answer';
import DateOfBirth from './DateOfBirth';
import NationalIdentifier from './NationalIdentifier';
import Address from './Address';
import City from './City';
import PostalCode from './PostalCode';
import Residence from './Residence';
import conflictFields from '../../../../constants/conflictFields';

const LABEL = {
  [languages.EN]: 'Beneficial owners',
  [languages.SV]: 'Verklig huvudman',
};

const ValueField = ({ language }: {language?: Languages}) => {
  const record = useRecordContext();

  const value = get(record, 'value') as {firstName: string; lastName: string}[] | undefined;

  const sx = get(record, 'sx', {});

  if (isEmpty(value)) return null;

  return (
    <Card
      variant="outlined"
      square
      sx={{
        m: 0,
        py: 0,
        width: '100%',
      }}
    >
      <Grid container bgcolor="background.paper">
        {value?.map((beneficialOwner, index) => (
          <Grid key={`beneficialOwner-${index + 1}`} item xs={12} md={12}>
            <Accordion key={`${beneficialOwner.firstName}  ${beneficialOwner.lastName}`} sx={{ maxWidth: null, backgroundColor: 'transparent' }}>
              <AccordionSummary
                expandIcon={<ChevronRightIcon />}
                sx={{
                  flexDirection: 'row-reverse',
                  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
                    transform: 'rotate(90deg)',
                  },
                }}
              >
                <Typography
                  sx={{
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    display: '-webkit-box',
                    WebkitLineClamp: '1',
                    WebkitBoxOrient: 'vertical',
                    ml: 4,
                    ...sx,
                  }}
                  variant="h6"
                >
                  {`${beneficialOwner.firstName} ${beneficialOwner.lastName}`}
                </Typography>
              </AccordionSummary>
              <Divider />
              <AccordionDetails>
                <RecordContextProvider value={{ language, ...beneficialOwner, sx }}>
                  <Grid container spacing={6} py="1%" px="1%">
                    <FirstName.Nested />
                    <LastName.Nested />
                    <TaxResidency.Nested />
                    <DateOfBirth.Nested />
                    <Address.Nested />
                    <PostalCode.Nested />
                    <City.Nested />
                    <Residence.Nested />
                    <NationalIdentifier.Nested />
                    <Citizenship.Nested />
                    <ControlType.Nested />
                    <FamilyControl.Nested />
                    <Pep.Nested />
                  </Grid>
                </RecordContextProvider>
              </AccordionDetails>
            </Accordion>
            {index + 1 !== value.length && <Divider />}
          </Grid>
        ))}
      </Grid>
    </Card>
  );
};

const BeneficialOwners = () => {
  const record = useRecordContext();

  const language = record?.language as Languages;

  const hasBeneficialOwners = get(record, 'beneficialOwner') === 'yes';
  if (!hasBeneficialOwners) return null;

  return (
    <Answer
      label={LABEL[language]}
      isComplexType
      datapoint={conflictFields.BENEFICIAL_OWNERS}
      source="beneficialOwners"
    >
      <ValueField language={language} />
    </Answer>
  );
};

BeneficialOwners.ValueField = ValueField;

export default BeneficialOwners;
