import React, {
  useCallback, useMemo,
} from 'react';

import {
  EditBase,
  Form,
  Labeled,
  RecordContextProvider,
  ReferenceField,
  TextField,
  useGetOne,
  usePermissions,
  useRecordContext,
  useResourceContext,
} from 'react-admin';

import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  List,
  ListItem,
  ListItemButton,
  Typography,
} from '@mui/material';

import ArrowBackIosFilled from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosFilled from '@mui/icons-material/ArrowForwardIos';

import { Link, useNavigate, useParams } from 'react-router-dom';

import RenderElements from './RenderElements';
import UploadFiles from './UploadFiles';

import { Page } from '../types';
import { kycFormStatusesChoices } from '../../../constants/kycFormStatuses';
import ReferenceEntityField from '../../../customFields/ReferenceEntityField';
import EditSelectInput from '../../../customFields/EditSelectInput';
import hasAccess from '../../../utilities/hasAccess';
import actions, { Actions } from '../../../constants/actions';

const FormNavigation = ({
  pages,
  currentPage,
  handleNavigation,
}: {
  pages: Page[];
  currentPage?: string;
  handleNavigation: (page: string) => void;
}) => (
  <Box>
    <List disablePadding>
      {pages.map((page, i) => (
        <ListItem
          disablePadding
          key={page.url}
        >
          <ListItemButton
            selected={page.url === currentPage}
            onClick={() => handleNavigation(page.url)}
            sx={{
              '&.Mui-selected': {
                backgroundColor: '#2380611F',
              },
              '&.Mui-selected:hover': {
                backgroundColor: '#2380611F',
              },
              '&:hover': {
                backgroundColor: '#2380611F',
              },
            }}
          >
            <Box display="flex" gap={2} py={2}>
              <Typography fontWeight="bold" variant="body2">
                {`${i + 1}. ${page.title}`}
              </Typography>
            </Box>
          </ListItemButton>
        </ListItem>
      ))}
      <ListItem
        disablePadding
      >
        <ListItemButton
          selected={currentPage === 'upload-files'}
          onClick={() => handleNavigation('upload-files')}
          sx={{
            '&.Mui-selected': {
              backgroundColor: '#2380611F',
            },
            '&.Mui-selected:hover': {
              backgroundColor: '#2380611F',
            },
            '&:hover': {
              backgroundColor: '#2380611F',
            },
          }}
        >
          <Box display="flex" gap={2} py={2}>
            <Typography fontWeight="bold" variant="body2">
              {`${pages.length + 1}. Upload files`}
            </Typography>
          </Box>
        </ListItemButton>
      </ListItem>
    </List>
  </Box>
);

const KycFormView = () => {
  const record = useRecordContext();
  const redirect = useNavigate();
  const resource = useResourceContext();
  const { permissions } = usePermissions<Actions[]>();

  const {
    formId,
    formPage,
    id,
    kycFormId,
  } = useParams();

  const { data: form } = useGetOne('forms', { id: formId }, { enabled: !!formId });
  const { data: kycForm } = useGetOne('form-answers', { id: kycFormId }, { enabled: !!kycFormId });

  const currentPageObj = useMemo(
    () => form?.pages?.find((page: Page) => formPage === page.url),
    [form?.pages, formPage],
  );

  const currentPageNumber = useMemo(
    () => (form?.pages?.findIndex((page: Page) => formPage === page.url) ?? 0) + 1,
    [form?.pages, formPage],
  );

  const handleNavigation = useCallback((pageUrl: string) => {
    redirect(`/${resource}/${id}/form-answers/${formId}/${kycFormId}/${pageUrl}`);
  }, [formId, id, kycFormId, redirect, resource]);

  const handleForward = useCallback(() => {
    // current page already have + 1 to the index
    const nextUrl = form?.pages?.at(currentPageNumber)?.url;
    if (!nextUrl) {
      handleNavigation('upload-files');
    } else {
      handleNavigation(nextUrl);
    }
  }, [currentPageNumber, form?.pages, handleNavigation]);

  const handleBack = useCallback(() => {
    // current page already have + 1 to the index
    const backUrl = form?.pages?.at(currentPageNumber - 2)?.url;

    if (formPage === 'upload-files') {
      handleNavigation(form?.pages?.at(-1).url);
    } else {
      handleNavigation(backUrl);
    }
  }, [currentPageNumber, form?.pages, formPage, handleNavigation]);

  if (!record || !form) return null;

  if (!record) return null;

  return (
    <>
      <Box display="flex" alignItems="center">
        <Button
          size="medium"
          component={Link}
          to={`/${resource}/${id}/form-answers`}
          startIcon={<ArrowBackIosFilled fontSize="medium" color="primary" />}
          variant="text"
        >
          <Typography variant="h5">All forms</Typography>
        </Button>
      </Box>
      <Grid container spacing={2} columns={100}>
        <Grid item xs={100}>
          <Card variant="outlined">
            <CardContent>
              <EditBase id={kycFormId} resource="form-answers" mutationMode="pessimistic" redirect={false}>
                <Form>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6} md={4}>
                      <Labeled label="Form">
                        <ReferenceField source="formId" reference="forms">
                          <TextField source="title" />
                        </ReferenceField>
                      </Labeled>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <Labeled label="Entity">
                        <ReferenceEntityField source="entity" />
                      </Labeled>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <EditSelectInput
                        label="Status"
                        source="status"
                        choices={kycFormStatusesChoices}
                        withSaveButton
                        showEdit={hasAccess(permissions, actions.FORM_ANSWER_UPDATE)}
                      />
                    </Grid>
                  </Grid>
                </Form>
              </EditBase>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={100} md={20}>
          <Card
            variant="outlined"
            sx={{
              margin: 0,
              height: '100%',
            }}
          >
            <FormNavigation
              pages={form.pages}
              currentPage={formPage}
              handleNavigation={handleNavigation}
            />
          </Card>
        </Grid>
        <Grid item xs={100} md={80}>
          <Card
            variant="outlined"
            sx={{
              margin: 0,
              minHeight: 900,
              height: '100%',
              backgroundColor: 'background.grey',
            }}
          >
            <CardContent>
              {currentPageObj && (
                <RenderElements
                  kycForm={kycForm}
                  language={form.language}
                  elements={currentPageObj.elements}
                  title={`${currentPageNumber}. ${currentPageObj.title}`}
                />
              )}
              {formPage === 'upload-files' && (
                <RecordContextProvider value={kycForm}>
                  <UploadFiles pageNumber={(form?.pages?.length ?? 0) + 1} />
                </RecordContextProvider>
              )}
              <Box display="flex" justifyContent="space-between" px={8} py={2}>
                <Button onClick={handleBack} startIcon={<ArrowBackIosFilled />} sx={{ display: currentPageNumber === 1 ? 'none' : '' }}>
                  <Typography fontSize={14} fontWeight="bold">Previous</Typography>
                </Button>
                <Box />
                <Button
                  onClick={handleForward}
                  endIcon={<ArrowForwardIosFilled />}
                  sx={{ display: formPage === 'upload-files' ? 'none' : '' }}
                >
                  <Typography fontSize={14} fontWeight="bold">Next</Typography>
                </Button>
              </Box>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </>
  );
};

export default KycFormView;
