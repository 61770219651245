import React from 'react';
import { useWatch } from 'react-hook-form';
import productTypes, { ProductTypes } from '../../../../constants/productTypes';
import SwishProductInformation from './swishProductInformation';

const productInformationInputs: Record<ProductTypes, React.FC> = {
  [productTypes.SWISH]: SwishProductInformation,
};

const ProductInformationInput = () => {
  const productType = useWatch<{
    productType?: ProductTypes;
  }>({ name: 'productType' });

  if (!productType) return null;

  const Input = productInformationInputs[productType];
  return <Input />;
};

export default ProductInformationInput;
