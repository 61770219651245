import React, { useCallback } from 'react';

import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  IconButton,
  Typography,
} from '@mui/material';

import ArrowBackIosFilled from '@mui/icons-material/ArrowBackIos';

import {
  Form,
  useCreate,
  useNotify,
} from 'react-admin';

import { FieldValues } from 'react-hook-form';

import conflictFieldsMap from './conflictFieldsMap';
import { ConflictFields } from '../../constants/conflictFields';
import { EntityTypes } from '../../constants/entityTypes';
import conflictFieldsValidationMap from './conflictFieldsValidationMap';
import cleaner from '../../utilities/dataProvider/cleaner';

const ConflictResolutionManualInput = ({
  entityId,
  entityType,
  handleClose,
  name,
  identificationInfo,
}: {
  entityId: string;
  entityType: EntityTypes;
  handleClose: () => void;
  name: ConflictFields;
  identificationInfo?: {};
}) => {
  const [create] = useCreate();

  const notify = useNotify();

  const handleSubmit = useCallback((data: FieldValues) => {
    create('data-points', {
      data: {
        entity: {
          id: entityId,
          type: entityType,
        },
        name,
        value: cleaner(data.value),
        comment: data.comment,
        type: 'manual',
        identificationInfo,
        selected: true,
      },
    }, {
      onSuccess: () => {
        handleClose();
      },
      onError: () => {
        notify('Could not save manual input. If the problem persists, reach out to our support.', { type: 'error' });
      },
    });
  }, [create, handleClose, entityId, name, identificationInfo, notify, entityType]);

  return (
    <Grid container gap={1}>
      <Grid item xs={12} md={12} mb={3}>
        <Box display="flex" alignItems="center">
          <IconButton size="medium" onClick={handleClose}>
            <ArrowBackIosFilled fontSize="medium" color="primary" />
          </IconButton>
          <Typography color="primary" variant="h5">Back</Typography>
        </Box>
      </Grid>
      <Grid item xs={12} md={12}>
        <Grid container>
          <Grid item xs={12} md={12} sx={{ backgroundColor: 'background.paper' }}>
            <Card
              sx={{ m: 0 }}
              variant="outlined"
            >
              <CardContent>
                <Form
                  onSubmit={handleSubmit}
                  resolver={conflictFieldsValidationMap[name]}
                  record={{}}
                >
                  <Grid container spacing={6}>
                    <Grid item xs={12} md={12}>
                      {React.createElement(conflictFieldsMap[name])}
                    </Grid>
                    <Grid item xs={12} md={12} display="flex" justifyContent="center">
                      <Box display="flex" gap={8}>
                        <Button onClick={handleClose}>Cancel</Button>
                        <Button variant="contained" type="submit">Save</Button>
                      </Box>
                    </Grid>
                  </Grid>
                </Form>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ConflictResolutionManualInput;
