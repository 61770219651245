import React from 'react';
import EditField from '../../../../customFields/EditField';

const SwishProductInformation = ({
  showEdit,
}: {
  showEdit: boolean;
}) => (
  <EditField
    source="productInformation.phoneNumber"
    label="Swish phone number"
    showEdit={showEdit}
  />
);

export default SwishProductInformation;
