import React, { useState } from 'react';

import {
  ReferenceManyField,
  Datagrid,
  DateField,
  usePermissions,
  ReferenceField,
  TextField,
  SelectField,
  useResourceContext,
} from 'react-admin';

import {
  Card,
  Grid,
  CardHeader,
  Divider,
  Box,
  Slide,

} from '@mui/material';

import hasAccess from '../../utilities/hasAccess';

import NoResults from '../layout/NoResults';
import GetFormLink from './GetFormLink';
// import CreateManualKyc from './CreateManualKyc';
import Pagination from '../layout/Pagination';

import { boldDataGridStyle } from '../../constants/style/datagridStyles';
import actions, { Actions } from '../../constants/actions';
import KycFormView from './KycFormView';
import { kycFormStatusesChoices } from '../../constants/kycFormStatuses';
import SelectInputButton from '../layout/inputFields/SelectInputButton';
import entityTypes, { entityTypesFromResource } from '../../constants/entityTypes';
import formRelatedEntities, { FormRelatedEntities } from '../../constants/formRelatedEntities';
import ReferenceEntityField from '../../customFields/ReferenceEntityField';

const KycForm = () => {
  const { permissions } = usePermissions<Actions[]>();
  const [
    relatedEntities,
    setRelatedEntities,
  ] = useState<FormRelatedEntities>(formRelatedEntities.ALL);

  const resource = useResourceContext();

  const entityType = entityTypesFromResource[resource];

  const relatedEntitiesChoices: {
    id: FormRelatedEntities;
    name: string;
  }[] = [
    { id: formRelatedEntities.ALL, name: 'All' },
    { id: formRelatedEntities.SELF, name: entityType },
  ];

  if (entityType === entityTypes.ACCOUNT) {
    relatedEntitiesChoices.push({ id: formRelatedEntities.RELATED_PRODUCTS, name: 'Related products' });
  } else if (entityType === entityTypes.INDIVIDUAL || entityType === entityTypes.BUSINESS) {
    relatedEntitiesChoices.push(
      { id: formRelatedEntities.RELATED_ACCOUNTS, name: 'Related accounts' },
      { id: formRelatedEntities.RELATED_PRODUCTS, name: 'Related products' },
    );
  }

  return (
    <Slide in direction="right" appear={false}>
      <Grid container>
        <Grid item xs={12} md={12}>
          <Card variant="outlined">
            <CardHeader
              title="KYC Forms"
              action={(
                <Box display="flex" flexWrap="wrap" gap={2}>
                  {(
                    entityType === entityTypes.INDIVIDUAL
                    || entityType === entityTypes.BUSINESS
                    || entityType === entityTypes.ACCOUNT
                  ) && (
                    <SelectInputButton
                      choices={relatedEntitiesChoices}
                      value={relatedEntities}
                      setValue={setRelatedEntities}
                    />
                  )}
                  {hasAccess(permissions, actions.FORM_GET_LINK) && <GetFormLink />}
                  {/* {hasAccess(permissions, actions.FORM_CREATE) && <CreateManualKyc />} */}
                </Box>
              )}
            />
            <Divider />
            <ReferenceManyField
              reference="form-answers"
              target="entity.id"
              perPage={50}
              pagination={<Pagination disableEmptyText />}
              filter={{
                relatedEntities: entityType === entityTypes.PRODUCT
                  ? formRelatedEntities.SELF : relatedEntities,
                'entity.type': entityType,
              }}
            >
              <Datagrid
                sx={boldDataGridStyle}
                bulkActionButtons={false}
                empty={<NoResults />}
              >
                <ReferenceField emptyText="Manual" source="formId" reference="forms" label="Name">
                  <TextField source="title" />
                </ReferenceField>
                <ReferenceEntityField source="entity" sortable={false} label="Entity" />
                <DateField source="createdAt" showTime label="Timestamp" />
                <SelectField source="status" label="Status" choices={kycFormStatusesChoices} emptyText="-" />
                <KycFormView />
              </Datagrid>
            </ReferenceManyField>
          </Card>
        </Grid>
      </Grid>
    </Slide>
  );
};

export default KycForm;
