import React from 'react';

import { ToggleButton } from '@mui/material';

import ListIcon from '@mui/icons-material/List';
import AccountTreeIcon from '@mui/icons-material/AccountTree';

import ruleViews, { RuleViews } from '../../../../constants/ruleView';

import StyledToggleButtonGroup from '../../../layout/button/StyledToggleButtonGroup';

const View = ({ changeView, view }: {
  view: RuleViews;
  changeView: (x: RuleViews) => void;
}) => {
  const handleChange = (
    event: React.MouseEvent<HTMLElement>,
    value: RuleViews,
  ) => {
    if (value !== null) { changeView(value); }
  };
  return (
    <StyledToggleButtonGroup
      size="small"
      value={view}
      exclusive
      onChange={handleChange}
      aria-label="text alignment"
    >
      <ToggleButton color="primary" value={ruleViews.LIST}>
        <ListIcon />
      </ToggleButton>
      <ToggleButton color="primary" value={ruleViews.TREE}>
        <AccountTreeIcon />
      </ToggleButton>
    </StyledToggleButtonGroup>
  );
};

export default View;
