import React from 'react';

import {
  TextField,
  useRecordContext,
  SelectField,
  Labeled,
} from 'react-admin';

import {
  Card,
  CardContent,
  CardHeader,
  Grid,
  Box,
  Divider,
} from '@mui/material';

import { countryChoices } from '../../../../constants/countries';
import { IBusiness } from '../../../../utilities/schemas/business';

const EntityData = () => {
  const record = useRecordContext<IBusiness>();

  return (
    <Grid item xs={12} md={12}>
      <Card variant="outlined">
        <CardHeader title="Entity Data" />
        <Divider />
        <CardContent>
          <Box display="flex" justifyContent="space-between">
            <Box>
              <Labeled label="Name">
                <TextField record={record} source="name" emptyText="-" />
              </Labeled>
            </Box>
            <Box>
              <Labeled label="Residence">
                <SelectField choices={countryChoices('en')} record={record} source="residence" emptyText="-" />
              </Labeled>
            </Box>
            <Box>
              <Labeled label="Registration number">
                <TextField record={record} source="registrationNumber" emptyText="-" />
              </Labeled>
            </Box>
          </Box>
        </CardContent>
      </Card>
    </Grid>
  );
};

export default EntityData;
