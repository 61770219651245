import React, { useCallback, useMemo } from 'react';

import {
  Box,
  Button,
} from '@mui/material';

import {
  AutocompleteInput,
  ReferenceInput,
  useGetIdentity,
} from 'react-admin';

import { useFormContext } from 'react-hook-form';
import ExtendedChoiceContext from '../utils/ExtendedChoiceContext';

const UNASSIGNED_FILTER_VALUE = 'unassigned';

const enableGetReference = (currentValue?: string | null) => currentValue !== null && currentValue !== '' && currentValue !== UNASSIGNED_FILTER_VALUE;

const CaseAssigneeFilter = ({ source }: {source: string}) => {
  const { setValue } = useFormContext();
  const { identity } = useGetIdentity();
  const handleAssignMe = useCallback(() => {
    if (identity?.id) {
      setValue(source, identity?.id);
    }
  }, [identity?.id, setValue, source]);

  const handleUnassigned = useCallback(() => {
    setValue(source, UNASSIGNED_FILTER_VALUE);
  }, [setValue, source]);

  const extraChoices = useMemo(() => [{
    name: 'Unassigned',
    id: UNASSIGNED_FILTER_VALUE,
  }], []);

  return (
    <Box
      display="flex"
      alignItems="flex-end"
      flexDirection="column"
    >
      <Box display="flex" gap={1}>
        <Button onClick={handleUnassigned} size="small">Unassigned</Button>
        <Button onClick={handleAssignMe} size="small">Assigned to me</Button>
      </Box>
      <ReferenceInput
        source={source}
        reference="members"
        label="Member"
        filter={{ active: true }}
        enableGetReference={enableGetReference}
      >
        <ExtendedChoiceContext extraChoices={extraChoices}>
          <AutocompleteInput
            fullWidth
            filterSelectedOptions={false}
            filterToQuery={(searchText) => ({ name: searchText })}
            optionText={(item: { name?: string; firstName: string; lastName: string}) => item.name ?? `${item.firstName} ${item.lastName}`}
          />
        </ExtendedChoiceContext>
      </ReferenceInput>
    </Box>
  );
};

export default CaseAssigneeFilter;
