import React from 'react';

import {
  Grid,
  Typography,
} from '@mui/material';
import { useWatch } from 'react-hook-form';

import Entity, { EntityFormValues } from './Entity';
import Person, { PersonFormValues } from './Person';
import entityTypes from '../../../../../../../constants/entityTypes';
import Accordion from '../../../../../../layout/Accordion';
import ToggleButton from './ToggleButton';

export type TransactionPartyFormValues = (
  { type: typeof entityTypes.INDIVIDUAL } & PersonFormValues)
| ({ type: typeof entityTypes.BUSINESS } & EntityFormValues);

const TransactionPartyForm = ({
  direction,
  title,
}: {
  direction: string;
  title: string;
}) => {
  const entityType = useWatch({ name: `${direction}.type` });

  return (
    <Accordion
      label={title}
      actions={(
        <ToggleButton direction={direction} />
      )}
    >
      <Grid>
        {entityType === entityTypes.INDIVIDUAL && (
          <Person direction={direction} />
        )}
        {entityType === entityTypes.BUSINESS && (
          <Entity direction={direction} />
        )}
        {!entityType && <Typography>Select a type</Typography>}
      </Grid>
    </Accordion>
  );
};

export default TransactionPartyForm;
