import React from 'react';
import AssignmentReturnedIcon from '@mui/icons-material/AssignmentReturned';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import { ButtonProps } from '@mui/material';
import { usePermissions } from 'react-admin';
import hasAccess from '../../../utilities/hasAccess';
import { Actions } from '../../../constants/actions';
import ButtonLoading from './ButtonLoading';

type Input = {
  label: string;
  loading: boolean;
  onClick: ButtonProps['onClick'];
  empty?: boolean;
  variant?: ButtonProps['variant'];
  action: Actions;
  icon?: React.ReactNode;
  color?: ButtonProps['color'];
}

const PurchaseButton = ({
  action,
  empty,
  label,
  loading,
  onClick,
  variant = 'contained',
  icon = empty ? <AssignmentReturnedIcon /> : <AutorenewIcon />,
  color = 'secondary',
}: Input) => {
  const { permissions } = usePermissions<Actions[]>();
  const isAllowed = hasAccess(permissions, action);

  if (!!action && !isAllowed) return null;

  return (
    <ButtonLoading
      variant={variant}
      label={label}
      startIcon={icon}
      loading={loading}
      onClick={onClick}
      color={color}
    />
  );
};

export default PurchaseButton;
