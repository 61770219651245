import React, { useCallback, useMemo } from 'react';

import { useRecordContext } from 'react-admin';

import {
  Box, IconButton, Typography,
} from '@mui/material';

import { TreeView, TreeItem } from '@mui/x-tree-view';

import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import BusinessIcon from '@mui/icons-material/Business';
import PersonIcon from '@mui/icons-material/Person';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';

import { isNumber } from 'lodash';
import { EntityTypes, resourceFromEntityTypes } from '../constants/entityTypes';
import NoResults from '../components/layout/NoResults';

const getIcon = (entityType: EntityTypes) => (entityType === 'Business' ? <BusinessIcon /> : <PersonIcon />);

type Node = {
  id: string;
  quota: string;
  shareCount: number;
  name: string;
  entityType: EntityTypes;
  entityId: string;
  children?: Node[]; // Added to represent child nodes
  parentId?: string; // Added to represent parent node ID
};

const NodeCard = ({
  node,
}: {
  node: Node;
}) => {
  const handelOnclick = useCallback(() => {
    const resource = resourceFromEntityTypes[node.entityType as EntityTypes];
    window.open(`/${resource}/${node.entityId}`, '_blank', 'noreferrer');
  }, [node.entityId, node.entityType]);

  return (
    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
      <Box display="flex" alignItems="center">
        {getIcon(node.entityType)}
        <Box sx={{ marginLeft: '8px' }}>
          <Typography sx={{ fontWeight: 'bold' }}>{node.name}</Typography>
          {node?.quota && (
            <Typography>
              {`Ownership: ${parseFloat(node.quota.replace(',', '.')) * 100}%`}
            </Typography>
          )}
          {isNumber(node?.shareCount) && (
            <Typography>
              {`Share count: ${node.shareCount}`}
            </Typography>
          )}
        </Box>
      </Box>
      <IconButton onClick={handelOnclick}>
        <OpenInNewIcon />
      </IconButton>
    </Box>
  );
};

const OrgChartTree = () => {
  const record = useRecordContext();
  const nodes = record?.ownerNodes as Node[];

  // Function to build a tree structure from the flat array
  const treeData = useMemo(() => {
    if (!nodes) return null;

    const idMapping = nodes?.reduce((acc: Record<string, number>, el, i) => {
      acc[el.id] = i;
      return acc;
    }, {});

    let root;
    nodes.forEach((el) => {
      // Handle the root element
      if (!el.parentId) {
        root = el;
        return;
      }
      // Use our mapping to locate the parent element in our data array
      const parentEl = nodes[idMapping[el.parentId]];
      // Add our current el to its parent's `children` array
      parentEl.children = [...(parentEl.children || []), el];
    });
    return root;
  }, [nodes]);

  // Recursive component to render tree items
  const renderTree = (node: Node) => (
    <TreeItem
      key={node.id}
      nodeId={node.id.toString()}
      label={<NodeCard node={node} />}
      sx={{
        '& .MuiTreeItem-content': {
          padding: '4px',
          alignItems: 'center',
        },
      }}
    >
      {Array.isArray(node.children) ? node.children.map((child) => renderTree(child)) : null}
    </TreeItem>
  );

  if (!treeData) return <NoResults />;

  return (
    <TreeView
      aria-label="organization chart"
      defaultCollapseIcon={<RemoveCircleOutlineIcon />}
      defaultExpandIcon={<AddCircleOutlineIcon />}
    >
      {renderTree(treeData)}
    </TreeView>
  );
};

export default OrgChartTree;
