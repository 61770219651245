import reportTypes, { SelectableReportTypes } from './reportTypes';

import EntitiesOverview from '../components/reports/reportTypesForm/create/EntitiesOverview';
import PingOverview from '../components/reports/reportTypesForm/create/PingOverview';
import CaseOverview from '../components/reports/reportTypesForm/create/CaseOverview';
import RiskDistribution from '../components/reports/reportTypesForm/create/RiskDistribution';
import SfsaReport from '../components/reports/reportTypesForm/create/SfsaReport';
import PingTestOverview from '../components/reports/reportTypesForm/create/PingTestOverview';

const reportCreateMap = {
  [reportTypes.PING_TEST_OVERVIEW]: PingTestOverview,
  [reportTypes.PING_OVERVIEW]: PingOverview,
  [reportTypes.ENTITIES_OVERVIEW]: EntitiesOverview,
  [reportTypes.CASE_OVERVIEW]: CaseOverview,
  [reportTypes.RISK_DISTRIBUTION]: RiskDistribution,
  [reportTypes.SFSA]: SfsaReport,
} satisfies Record<SelectableReportTypes, React.FC>;

export default reportCreateMap;
