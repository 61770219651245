import React from 'react';

import {
  ReferenceField,
  useRecordContext,
} from 'react-admin';

import get from 'lodash/get';

import RiskClassField from './RiskClassField';

const ReferenceRiskClassField = ({ source }: {source?: string; label?: string}) => {
  const record = useRecordContext();
  const value = source ? get(record, source, {}) : record;
  const idSource = source ? `${source}.id` : 'id';
  if (value.type === 'Individual') {
    return (
      <ReferenceField label="Individual" source={idSource} reference="individuals" link={false}>
        <RiskClassField source="riskScore" />
      </ReferenceField>
    );
  }
  if (value.type === 'Business') {
    return (
      <ReferenceField label="Business" source={idSource} reference="businesses" link={false}>
        <RiskClassField source="riskScore" />
      </ReferenceField>
    );
  }
  if (value.type === 'Car') {
    return (
      <ReferenceField label="Car" source={idSource} reference="cars" link={false}>
        <RiskClassField source="riskScore" />
      </ReferenceField>
    );
  }
  return null;
};

export default ReferenceRiskClassField;
