import React, { useCallback } from 'react';

import { Box, Button } from '@mui/material';

import ArrowBackIcon from '@mui/icons-material/ArrowBackIosNew';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

import { useRedirect } from 'react-admin';
import { useFormState, useWatch } from 'react-hook-form';
import { useParams } from 'react-router-dom';

const FormNavigationActions = ({
  steps,
}: {
  steps: string[];
}) => {
  const redirect = useRedirect();
  const { path, id } = useParams();
  const { isValid } = useFormState();

  const transactions = useWatch({ name: 'report.transactions' });
  const parties = useWatch({ name: 'report.activity.report_parties' });

  const currentPage = steps.findIndex((step) => step === path);
  const isLastPage = currentPage === steps.length - 1;
  const isFirstPage = currentPage === 0;

  const isValidForm = (() => {
    switch (path) {
      case 'transactions':
        return transactions?.length > 0;
      case 'parties':
        return parties?.length > 0;
      default:
        return isValid;
    }
  })();

  const previous = useCallback(() => {
    if (isFirstPage) {
      redirect(`/fipo/${id}`);
    } else {
      redirect(steps.at(currentPage - 1)!);
    }
  }, [currentPage, id, isFirstPage, redirect, steps]);

  const next = useCallback(() => {
    redirect(steps.at(currentPage + 1)!);
  }, [currentPage, redirect, steps]);

  const exit = useCallback(() => {
    redirect(`/cases/${id}`);
  }, [id, redirect]);

  if (isLastPage) {
    return (
      <Box display="flex" justifyContent="space-between" width="100%">
        <Button
          startIcon={<ArrowBackIcon />}
          onClick={previous}
        >
          Previous
        </Button>
        <Button
          endIcon={<ExitToAppIcon />}
          onClick={exit}
        >
          Close
        </Button>
      </Box>
    );
  }

  return (
    <Box display="flex" justifyContent="space-between" width="100%">
      <Button
        startIcon={<ArrowBackIcon />}
        onClick={previous}
      >
        Previous
      </Button>
      <Button endIcon={<ArrowForwardIosIcon />} variant="contained" disabled={!isValidForm} onClick={next}>Next</Button>
    </Box>
  );
};

export default FormNavigationActions;
