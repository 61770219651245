import React from 'react';

import { Typography, styled } from '@mui/material';

import SidebarNavList, { Page } from './SidebarNavList';

const Title = styled(Typography)(({ theme }) => ({
  color: theme.sidebar.color,
  fontSize: theme.typography.caption.fontSize,
  padding: `${theme.spacing(4)} ${theme.spacing(7)} ${theme.spacing(1)}`,
  opacity: 0.4,
  textTransform: 'uppercase',
  display: 'block',
}));

const SidebarNavSection = ({
  title,
  pages,
  onClose,
}: {
  title: string;
  pages: Page[];
  onClose?: () => void;
}) => (
  <div>
    {title && <Title variant="subtitle2">{title}</Title>}
    <SidebarNavList onClose={onClose} pages={pages} depth={0} />
  </div>
);

export default SidebarNavSection;
