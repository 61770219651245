import React, { useState } from 'react';

import {
  ListBase,
} from 'react-admin';

import {
  Card,
} from '@mui/material';

import Filter, { FilterType } from './Filter';

import PrefillButton from '../../../utils/PrefillButton';
import Pagination from '../../../../layout/Pagination';

import { entityListMap } from '../../../../../constants/entityFeatures';
import { EntityTypes } from '../../../../../constants/entityTypes';
import { prefillGoodsGetter } from './forms';

const Goods = ({
  type,
  handleOpenChange,
}: {
  type: EntityTypes;
  handleOpenChange: () => void;
}) => {
  const [filter, setFilter] = useState<FilterType>();

  const EntityListBody = entityListMap[type];
  if (!EntityListBody) return null;

  return (
    <ListBase
      perPage={10}
      filter={filter}
      resource="cars"
      disableSyncWithLocation
      storeKey="fipo-goods"
      sort={{ field: 'createdAt', order: 'DESC' }}
    >
      <Filter filter={filter} setFilter={setFilter} />
      <Card variant="outlined">
        <EntityListBody>
          <PrefillButton
            handleOpenChange={handleOpenChange}
            prefillForm={prefillGoodsGetter(type)}
          />
        </EntityListBody>
      </Card>
      <Pagination rowsPerPageOptions={[10, 15, 20]} disableEmptyText />
    </ListBase>
  );
};

export default Goods;
