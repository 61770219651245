import { useState, useEffect } from 'react';

import { groupBy, map } from 'lodash';

import actions from '../constants/actions';

import useEntityData from './useEntityData';
import { BoardMembers } from '../components/business/editTabs/kyc/CompanyRepresentation';
import { AltBeneficialOwners, BeneficialOwners } from '../components/business/editTabs/kyc/OwnerShip';

type RelatedIndividual = {
  relation: string;
  entityId: string;
  entityType: string;
  name: string;
}

const useRelatedEntities = () => {
  const [entities, setEntities] = useState<RelatedIndividual[]>([]);
  const {
    data: boardMembersData,
    isLoading: boardMembersIsLoading,
  } = useEntityData<BoardMembers>({
    resource: 'businesses',
    source: 'board-members',
    getAction: actions.BUSINESS_GET_OWNERSHIP,
  });
  const {
    data: beneficialOwnersData,
    isLoading: benOwnerIsLoading,
  } = useEntityData<BeneficialOwners>({
    resource: 'businesses',
    source: 'beneficial-owners',
    getAction: actions.BUSINESS_GET_OWNERSHIP,
  });
  const {
    data: altBeneficialOwnersData,
    isLoading: altBenOwnerIsLoading,
  } = useEntityData<AltBeneficialOwners>({
    resource: 'businesses',
    source: 'alt-beneficial-owners',
    getAction: actions.BUSINESS_GET_OWNERSHIP,
  });

  const updateDate = boardMembersData?.createdAt
    || beneficialOwnersData?.createdAt
    || altBeneficialOwnersData?.createdAt;

  useEffect(() => {
    const allEntities: RelatedIndividual[] = [
      ...(boardMembersData?.boardMembers?.map((
        member: { fullName: string; entityId: string; entityType: string },
      ) => ({
        entityId: member.entityId,
        entityType: member.entityType,
        name: member.fullName,
        relation: 'Board member',
      })) ?? []),
      ...(beneficialOwnersData?.beneficialOwners?.map((owner) => ({
        name: `${owner.person.firstNames.join(' ')} ${owner.person.surName}`,
        entityId: owner.entityId,
        entityType: owner.entityType,
        relation: 'Beneficial owner',
      })) ?? []),
      ...(altBeneficialOwnersData?.altBeneficialOwners?.map((owner: {name: string;
        entityId: string;
        entityType: string;}) => ({
        name: owner.name,
        entityId: owner.entityId,
        entityType: owner.entityType,
        relation: 'Alternative beneficial owner',
      })) ?? []),
    ];

    // Group by entityId
    const groupedByEntityId: { [key: string]: RelatedIndividual[] } = groupBy(allEntities, 'entityId');

    // Merge relations for each entityId
    const mergedEntities: RelatedIndividual[] = map(
      groupedByEntityId,
      (grpEntities): RelatedIndividual => {
      // Use the first entity as the base for the merged entity
        const baseEntity = grpEntities[0];

        // Concatenate all relation strings, ensuring no duplicates
        const relations = grpEntities
          .map((e) => e.relation)
          .filter((value, index, self) => self.indexOf(value) === index)
          .join(', ');

        return {
          ...baseEntity,
          relation: relations,
        };
      },
    );

    setEntities(mergedEntities);
  }, [boardMembersData, beneficialOwnersData, altBeneficialOwnersData]);

  return {
    boardMembersData,
    entities,
    beneficialOwners: beneficialOwnersData?.beneficialOwners,
    altBeneficialOwners: altBeneficialOwnersData?.altBeneficialOwners,
    isLoading: boardMembersIsLoading || benOwnerIsLoading || altBenOwnerIsLoading,
    updateDate,
  };
};

export default useRelatedEntities;
