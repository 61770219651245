import React, {
  useCallback, useEffect, useState,
} from 'react';

import {
  List,
  Datagrid,
  TextField,
  DateField,
  SingleFieldList,
  ReferenceArrayField,
  ReferenceArrayInput,
  AutocompleteArrayInput,
  useGetList,
  useListFilterContext,
  Identifier,
  FilterButton,
  ArrayField,
} from 'react-admin';

import {
  Box,
  Card,
  CircularProgress,
  Divider,
  Typography,
} from '@mui/material';

import ReferenceEntityField from '../../customFields/ReferenceEntityField';
import StatusField from '../../customFields/StatusField';
import TeamChip from '../../customFields/ColoredChipField';
import ColoredScoreField from '../../customFields/ColoredScoreField';

import { SubListNavigation } from '../layout/SubNavigation';
import { StyledTab, StyledTabs } from '../layout/Tab';
import NoResults from '../layout/NoResults';
import Pagination from '../layout/Pagination';

import { boldDataGridStyle } from '../../constants/style/datagridStyles';

import EntityFilter from '../filters/EntityFilter';
import RuleFilter from '../filters/RuleFilter';
import RuleVersion from './components/actions/RuleVersion';
import LinkField from '../layout/LinkField';

export const PingListBody = () => (
  <Datagrid sx={boldDataGridStyle} bulkActionButtons={false} empty={<NoResults variant="h6" />}>
    <LinkField sortable={false} source="id" linkType="show" />
    <DateField sortable source="createdAt" showTime />
    <TextField sortable={false} source="description" />
    <ArrayField sortable={false} source="entities" label="Entities">
      <SingleFieldList linkType={false}>
        <ReferenceEntityField source="" chip />
      </SingleFieldList>
    </ArrayField>
    <ReferenceArrayField sortable={false} source="tags" reference="tags" label="Tags" emptyText="-">
      <SingleFieldList placeholder="-">
        <TeamChip />
      </SingleFieldList>
    </ReferenceArrayField>
    <StatusField sortable={false} source="status" />
    <ColoredScoreField source="score" />
  </Datagrid>
);

const PingCountLabel = ({
  count,
  label,
  countIsLoading,
  shouldCount,
}: {
  count?: number;
  label: string;
  countIsLoading: boolean;
  shouldCount: boolean;
}) => {
  if (!shouldCount) return <Typography>{label}</Typography>;

  if (countIsLoading) {
    return (
      <Box display="flex" alignItems="center" gap={2}>
        <Typography>{label}</Typography>
        <CircularProgress size={11} />
      </Box>
    );
  }

  return <Typography>{`${label} (${count ?? 0})`}</Typography>;
};

const PingCountProvider = ({
  setCount,
  setCountIsLoading,
  shouldCount,
  setShouldCount,
  children,
  isTest,
  versions,

}: {
  setCountIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
  shouldCount: boolean;
  setShouldCount: React.Dispatch<React.SetStateAction<boolean>>;
  setCount: React.Dispatch<React.SetStateAction<{
    id: Identifier;
    all: number;
    confirmed: number;
    pending: number;
    ignored: number;
    resolved: number;
  } | undefined>>;
  children: React.ReactElement;
  isTest: boolean;
  versions: string[];

}) => {
  const { filterValues } = useListFilterContext();

  useEffect(
    () => {
      setShouldCount(Object.keys(filterValues).every((key) => ['status', 'rule'].includes(key)));
    },
    [filterValues, setShouldCount],
  );

  const { data, isLoading } = useGetList<{
    id: Identifier;
    all: number;
    confirmed: number;
    pending: number;
    ignored: number;
    resolved: number;
  }>('pings/count', {
    filter: {
      ...filterValues,
      isTestPingCount: isTest,
      ruleVersion: versions,
    },
  }, { enabled: shouldCount });

  useEffect(() => {
    setCountIsLoading(isLoading);
  }, [isLoading, setCountIsLoading]);

  useEffect(() => {
    setCount(data?.at(0));
  }, [data, setCount]);

  return children;
};

const PingActions = ({
  isTest,
  versions,
  setIsTest,
  setVersions,
}: {
  isTest: boolean;
  versions: string[];
  setIsTest: React.Dispatch<React.SetStateAction<boolean>>;
  setVersions: React.Dispatch<React.SetStateAction<string[]>>;
}) => (
  <Box display="flex" gap={2} minWidth="250px" alignItems="center" justifyContent="flex-end">
    <FilterButton />
    <RuleVersion
      versions={versions}
      setVersions={setVersions}
      isTest={isTest}
      setIsTest={setIsTest}
    />
  </Box>
);

const PingList = () => {
  const [statusTab, setStatusTab] = useState(0);
  const [status, setStatusFilter] = useState<string | undefined>(undefined);
  const [versions, setVersions] = useState<string[]>([]);
  const [isTest, setIsTest] = useState<boolean>(false);

  const [countIsLoading, setCountIsLoading] = useState(false);
  const [shouldCount, setShouldCount] = useState(true);
  const [count, setCount] = useState<{
    id: Identifier;
    all: number;
    confirmed: number;
    pending: number;
    ignored: number;
    resolved: number;
  }>();

  const handleStatusChange = useCallback((
    _event: React.SyntheticEvent<Element, Event>,
    newValue: number,
  ) => {
    setStatusTab(newValue);
    if (newValue === 0) setStatusFilter(undefined);
    if (newValue === 1) setStatusFilter('confirmed');
    if (newValue === 2) setStatusFilter('resolved');
    if (newValue === 3) setStatusFilter('pending');
    if (newValue === 4) setStatusFilter('ignored');
  }, []);

  return (
    <List
      filter={{ status, isTestPing: isTest, ruleVersion: versions }}
      empty={false}
      pagination={<Pagination disableEmptyText />}
      filters={[
        <ReferenceArrayInput source="tags" reference="tags" filter={{ active: true }}>
          <AutocompleteArrayInput
            label="Tag"
            filterToQuery={(searchText: string) => ({ name: searchText })}
            optionText="name"
          />
        </ReferenceArrayInput>,
        <EntityFilter label="Entities" source="entities.id" />,
        <RuleFilter source="rule" label="Rules" />,
      ]}
      actions={(
        <PingActions
          isTest={isTest}
          versions={versions}
          setIsTest={setIsTest}
          setVersions={setVersions}
        />
      )}
      sort={{ field: 'createdAt', order: 'DESC' }}
      exporter={false}
    >
      <Card style={{ margin: 0 }} variant="outlined">
        <PingCountProvider
          isTest={isTest}
          versions={versions}
          shouldCount={shouldCount}
          setCount={setCount}
          setShouldCount={setShouldCount}
          setCountIsLoading={setCountIsLoading}
        >
          <Card style={{ margin: 0 }}>
            <SubListNavigation>
              <StyledTabs value={statusTab} onChange={handleStatusChange}>
                <StyledTab
                  label={React.createElement(PingCountLabel, {
                    count: count?.all,
                    label: 'All',
                    countIsLoading,
                    shouldCount,
                  })}
                />
                <StyledTab
                  label={React.createElement(PingCountLabel, {
                    count: count?.confirmed,
                    label: 'Confirmed',
                    countIsLoading,
                    shouldCount,
                  })}
                />
                <StyledTab
                  label={React.createElement(PingCountLabel, {
                    count: count?.resolved,
                    label: 'Resolved',
                    countIsLoading,
                    shouldCount,
                  })}
                />
                <StyledTab
                  label={React.createElement(PingCountLabel, {
                    count: count?.pending,
                    label: 'Pending',
                    countIsLoading,
                    shouldCount,
                  })}
                />
                <StyledTab
                  label={React.createElement(PingCountLabel, {
                    count: count?.ignored,
                    label: 'Ignored',
                    countIsLoading,
                    shouldCount,
                  })}
                />
              </StyledTabs>
            </SubListNavigation>
            <Divider />
          </Card>
        </PingCountProvider>
        <PingListBody />
      </Card>
    </List>
  );
};

export default PingList;
