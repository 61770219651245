import React, { useCallback, useState } from 'react';

import {
  Button,
  Box,
} from '@mui/material';

import {
  Labeled,
  SaveButton,
  SelectInput,
  UpdateMutationFunction,
  UpdateParams,
  useRegisterMutationMiddleware,
  UseUpdateOptions,
} from 'react-admin';

import CreateIcon from '@mui/icons-material/Create';
import CancelIcon from '@mui/icons-material/Cancel';
import StatusField from './StatusField';

const EditStatus = ({
  source,
  label,
  showEdit,
  withSaveButton = false,
}: {
  source: string;
  label: string;
  showEdit: boolean;
  withSaveButton?: boolean;
}) => {
  const [editable, setEditable] = useState(false);

  const handleChange = () => {
    setEditable(!editable);
  };

  const callback = useCallback(async (
    resource: string | undefined,
    params: Partial<UpdateParams> | undefined,
    options: UseUpdateOptions | undefined,
    next: UpdateMutationFunction,
  ) => {
    const existingSuccessCallback = options?.onSuccess;
    const newSuccessCallback: UseUpdateOptions['onSuccess'] = existingSuccessCallback ? (...args) => {
      setEditable(false);
      return existingSuccessCallback(...args);
    } : () => {
      setEditable(false);
    };
    const newOptions = {
      ...options,
      onSuccess: newSuccessCallback,
    };
    return next(resource, params, newOptions);
  }, []);

  useRegisterMutationMiddleware<UpdateMutationFunction>(callback);

  if (!editable) {
    return (
      <Labeled label={label}>
        <Box display="flex" alignItems="center" justifyContent="space-between" flexWrap="wrap">
          <StatusField source={source} />
          {showEdit && (
            <Button startIcon={<CreateIcon color="secondary" />} size="small" onClick={handleChange}>
              Edit
            </Button>
          )}
        </Box>
      </Labeled>
    );
  }
  return (
    <Labeled label={label}>
      <Box display="flex" alignItems="baseline" justifyContent="space-between" flexWrap="wrap">
        <SelectInput
          source="status"
          choices={[
            { id: 'Blocked', name: 'Blocked' },
            { id: 'Available', name: 'Available' },
          ]}
          label="Select status"
        />
        <Box display="flex" flexDirection="row" flexWrap="wrap" gap={2}>
          {withSaveButton && (
            <SaveButton variant="text" />
          )}
          <Button startIcon={<CancelIcon color="error" />} size="small" onClick={handleChange}>
            Cancel
          </Button>
        </Box>
      </Box>
    </Labeled>
  );
};

export default EditStatus;
