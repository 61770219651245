const typography = {
  fontFamily: [
    'Inter',
    '-apple-system',
    'BlinkMacSystemFont',
    '"Segoe UI"',
    'Roboto',
    '"Helvetica Neue"',
    'Arial',
    'sans-serif',
    '"Apple Color Emoji"',
    '"Segoe UI Emoji"',
    '"Segoe UI Symbol"',
  ].join(','),
  fontSize: 13,
  fontWeightLight: 300,
  fontWeightRegular: 400,
  fontWeightMedium: 500,
  fontWeightBold: 600,
  h1: {
    fontSize: '2rem',
    fontWeight: 600,
    lineHeight: 1.25,
  },
  h2: {
    fontSize: '1.75rem',
    fontWeight: 600,
    lineHeight: 1.25,
  },
  h3: {
    fontSize: '1.5rem',
    fontWeight: 600,
    lineHeight: 1.25,
  },
  h4: {
    fontSize: '1.125rem',
    fontWeight: 500,
    lineHeight: 1.25,
  },
  h5: {
    fontSize: '1.0625rem',
    fontWeight: 500,
    lineHeight: 1.25,
  },
  h6: {
    fontSize: '1rem',
    fontWeight: 500,
    lineHeight: 1.25,
  },
  body1: {
    fontSize: 13,
  },
  button: {
    textTransform: 'none',
  },
  label: {
    fontSize: 15,
    lineHeight: '17.58px',
    fontWeight: 400,
    color: '#656565',
  },
  smallTitle: {
    fontWeight: '400',
    fontSize: '13px',
    color: '#646464',
  },
  largeTitle: {
    fontWeight: 'bold',
    fontSize: '16px',
  },
  mediumTitle: {
    fontWeight: 'bold',
    fontSize: '14px',
  },
  description: {
    fontSize: 15,
    lineHeight: '17.58px',
    fontWeight: 400,
    color: '#656565',
    fontStyle: 'italic',
  },
} as const;

declare module '@mui/material/styles' {
  interface TypographyVariants {
    label: React.CSSProperties;
    description: React.CSSProperties;
    smallTitle: React.CSSProperties;
    mediumTitle: React.CSSProperties;
    largeTitle: React.CSSProperties;
  }

  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    label?: React.CSSProperties;
    smallTitle?: React.CSSProperties;
    mediumTitle?: React.CSSProperties;
    largeTitle?: React.CSSProperties;
    description?: React.CSSProperties;
  }
}

// Update the Typography's variant prop options
declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    label: true;
    smallTitle: true;
    mediumTitle: true;
    largeTitle: true;
    description: true;
  }
}

export default typography;
