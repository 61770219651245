import React from 'react';
import { Link } from 'react-router-dom';

import {
  Drawer as MuiDrawer,
  styled,
  DrawerProps,
} from '@mui/material';

import SidebarFooter from './SidebarFooter';
import SidebarNav from './SidebarNav';

import pingwireLogo from '../../../assets/logos/pingwire-negative.png';
import { Page } from './SidebarNavList';

export const drawerWidth = 258;

const Drawer = styled(MuiDrawer)`
  border-right: 0;

  & .MuiDrawer-paper {
    width: ${drawerWidth}px;
    border-right: 0;

    scrollbar-width: none;
    -ms-overflow-style: none;
    &::-webkit-scrollbar {
      width: 0px;
    }
  }
`;

const Brand = styled(Link)`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: ${(props) => props.theme.typography.h5.fontSize};
  font-weight: ${(props) => props.theme.typography.fontWeightMedium};
  color: ${(props) => props.theme.sidebar.header.color};
  background-color: ${(props) => props.theme.sidebar.header.background};
  font-family: ${(props) => props.theme.typography.fontFamily};
  min-height: 56px;
  padding-left: ${(props) => props.theme.spacing(6)};
  padding-right: ${(props) => props.theme.spacing(6)};
  justify-content: center;
  cursor: pointer;
  flex-grow: 0;
  ${(props) => props.theme.breakpoints.up('sm')} {
    min-height: 64px;
  }
  &:hover {
    background-color: ${(props) => props.theme.sidebar.header.background};
  }
`;

const BrandLogo = styled('img')(({ theme }) => ({
  marginRight: theme.spacing(2),
  height: 32,
}));

const Sidebar = ({
  items,
  variant = 'permanent',
  open,
  onClose,
}: {
  items: {
    title: string;
    pages: Page[];
  }[];
  variant: DrawerProps['variant'];
  open: boolean;
  onClose?: () => void;
}) => (
  <Drawer
    variant={variant}
    open={open}
    onClose={onClose}
  >
    <Brand to="/">
      <BrandLogo src={pingwireLogo} alt="Pingwire" />
    </Brand>
    <SidebarNav onClose={onClose} items={items} />
    <SidebarFooter />
  </Drawer>
);

export default Sidebar;
