import React from 'react';

import {
  Box,
  Card,
  Grid,
  Typography,
} from '@mui/material';

import {
  get,
  isEqual,
  isNull,
  isNumber,
  isUndefined,
} from 'lodash';

import {
  RecordContextProvider, useRecordContext, TextField,
} from 'react-admin';

import Answer from '../Answer';

import languages, { Languages } from '../../../../constants/languages';
import conflictFields from '../../../../constants/conflictFields';
import getCoefficientFromPeriod from '../../../../utilities/getCoefficiantFromPeriod';
import { Currencies } from '../../../../constants/currencies';

type TxObj = {
  monthlyVolume?: number;
  monthlyFrequency?: number;
  max?: number;
  median?: number;
  expectedTransactionTimeline?: {
    amount: number;
    unit: string;
  };
}

export type ExpectedTransactionBehaviorAnswer = {
  currency: Currencies;
  incoming?: TxObj;
  outgoing?: TxObj;
  process?: TxObj;
  total?: TxObj;
  transactionTypeConfiguration?: TransactionTypeConfiguration;
}

type ExtraParametersObj = {
  label?: string;
  inputLabel?: string;
  enabled?: boolean;
  options?: { id: number; label: string }[];
  unit?: 'day' | 'month' | 'year';
  period?: number;
}

type TransactionTypeConfiguration = {
  or: {
    and: {
      inclusion: 'included' | 'excluded';
      transactionTypeId: string;
    }[];
  }[];
}

export type ExtraParameters = {
  [k in 'incoming' | 'outgoing' | 'process' | 'total']?: {
    enabled?: boolean;
    hideTitle?: boolean;
    volume?: ExtraParametersObj;
    frequency?: ExtraParametersObj;
    max?: ExtraParametersObj;
    median?: ExtraParametersObj;
    expectedTransactionTimeline?: ExtraParametersObj;
  }
} & {
  transactionTypeLabel?: string;
  currencyOverride?: string;
  singleTransactionTypeConfigurationOverride?: TransactionTypeConfiguration | 'noTransactionTypeFilter';
  transactionTypeConfigurationOptions?: {
    value: TransactionTypeConfiguration | undefined;
    label: string;
    id: string;
  }[];
}

const LABEL = {
  [languages.EN]: 'Expected transaction behaviour',
  [languages.SV]: 'Förväntat transaktionsbeteende',
};

const NestedField = ({
  label,
  source,
  unit,
  coefficient,
  options,
}: {
  label: string;
  source: string;
  unit?: string;
  coefficient?: number;
  options?: { id: number; label: string }[];
}) => {
  const record = useRecordContext();

  const value = get(record, source);
  const sx = get(record, 'sx', {});

  const convertedValue = isNumber(value) && isNumber(coefficient)
    ? value * coefficient
    : value;

  const optionLabel = options && options.find(({ id }) => id === value)?.label;
  const string = optionLabel ?? `${convertedValue} ${unit ?? ''}`.trimEnd();

  if (isNull(value) || isUndefined(value)) {
    return (
      <Grid item xs={12} md={12}>
        <Box display="flex" flexDirection="column">
          <Typography variant="label">{label}</Typography>
          <Typography>-</Typography>
        </Box>
      </Grid>
    );
  }

  return (
    <Grid item xs={12} md={12}>
      <Box display="flex" flexDirection="column">
        <Typography variant="label">{label}</Typography>
        <TextField record={{ string }} sx={sx} source="string" emptyText="-" fontWeight="bold" />
      </Box>
    </Grid>
  );
};

const DIRECTION_VOLUME_QUESTION_LABEL = {
  incoming: {
    [languages.EN]: 'What is the volume of incoming transactions you expect to handle per month?',
    [languages.SV]: 'Vilken volym (belopp) av inkommande transaktioner förväntar ni er att hantera per månad?',
  },
  outgoing: {
    [languages.EN]: 'What is the volume of outgoing transactions you expect to handle per month?',
    [languages.SV]: 'Vilken volym (belopp) av utgående transaktioner förväntar ni er att hantera per månad?',
  },
  process: {
    [languages.EN]: 'What is the volume of transactions you expect to process per month?',
    [languages.SV]: 'Vilken volym (belopp) av transaktioner förväntar ni er att behandla per månad?',
  },
  total: {
    [languages.EN]: 'What is the total volume of transactions you expect to handle per month?',
    [languages.SV]: 'Vilken volym (belopp) av transaktioner förväntar ni er att hantera per månad?',
  },
};

const DIRECTION_MAX_QUESTION_LABEL = {
  incoming: {
    [languages.EN]: 'What is the estimated highest amount of an incoming transaction?',
    [languages.SV]: 'Vad är det uppskattade högsta beloppet för en inkommande transaktion?',
  },
  outgoing: {
    [languages.EN]: 'What is the estimated singular highest amount of an outgoing transaction?',
    [languages.SV]: 'Vad är det uppskattade högsta beloppet för en utgående transaktion?',
  },
  process: {
    [languages.EN]: 'What is the estimated singular highest amount of a processed transaction?',
    [languages.SV]: 'Vad är det uppskattade högsta beloppet för en transaktion ni behandlar?',
  },
  total: {
    [languages.EN]: 'What is the estimated singular highest amount of a transaction?',
    [languages.SV]: 'Vad är det uppskattade högsta beloppet för en transaktion?',
  },
};

const DIRECTION_MEDIAN_QUESTION_LABEL = {
  incoming: {
    [languages.EN]: 'What is the estimated typical amount for an incoming transaction?',
    [languages.SV]: 'Vad är det uppskattade snittbeloppet per inkommande transaktion?',
  },
  outgoing: {
    [languages.EN]: 'What is the estimated typical amount for an outgoing transaction?',
    [languages.SV]: 'Vad är det uppskattade snittbeloppet per utgående transaktion?',
  },
  process: {
    [languages.EN]: 'What is the estimated typical amount for a processed transaction?',
    [languages.SV]: 'Vad är det uppskattade snittbeloppet per transaktion ni behandlar?',
  },
  total: {
    [languages.EN]: 'What is the estimated typical amount for a transaction?',
    [languages.SV]: 'Vad är det uppskattade snittbeloppet per transaktion?',
  },
};

const DIRECTION_FREQUENCY_QUESTION_LABEL = {
  incoming: {
    [languages.EN]: 'How many incoming transactions do you expect to receive per month?',
    [languages.SV]: 'Hur många inkommande transaktioner förväntar ni er att ta emot per månad?',
  },
  outgoing: {
    [languages.EN]: 'How many outgoing transactions do you expect to send per month?',
    [languages.SV]: 'Hur många utgående transaktioner förväntar ni er att skicka per månad?',
  },
  process: {
    [languages.EN]: 'How many transactions do you expect to process per month?',
    [languages.SV]: 'Hur många transaktioner förväntar ni er att behandla per månad?',
  },
  total: {
    [languages.EN]: 'How many transactions do you expect to be involved in every month?',
    [languages.SV]: 'Hur många transaktioner förväntar ni er att vara involverade i varje månad?',
  },
};

const DIRECTION_TIMELINE_QUESTION_LABEL = {
  incoming: {
    [languages.EN]: 'What is the expected timeframe for which you expect incoming transactions?',
    [languages.SV]: 'Vilken tidsperiod förväntar ni er för inkommande transaktioner?',
  },
  outgoing: {
    [languages.EN]: 'What is the expected timeframe for which you expect outgoing transactions?',
    [languages.SV]: 'Vilken tidsperiod förväntar ni er för utgående transaktioner?',
  },
  process: {
    [languages.EN]: 'What is the expected timeframe for which you expect to process transactions?',
    [languages.SV]: 'Vilken tidsperiod förväntar ni er att behandla transaktioner?',
  },
  total: {
    [languages.EN]: 'What is the expected timeframe for which you expect transactions?',
    [languages.SV]: 'Vilken tidsperiod förväntar ni er för utgående transaktioner?',
  },
};

const ExpectedTransactionBehaviorFields = ({
  extraParameters,
  language,
  expectedTransactionBehavior,
  sx,
}: {
  extraParameters?: ExtraParameters;
  language: Languages;
  expectedTransactionBehavior: ExpectedTransactionBehaviorAnswer;
  sx: {};
}) => (
  <RecordContextProvider value={{ language, ...expectedTransactionBehavior, sx }}>
    <Grid container spacing={6} py="1%" px="1%">
      {(!extraParameters || extraParameters?.incoming?.volume?.enabled) && (
        <NestedField
          source="incoming.monthlyVolume"
          label={extraParameters?.incoming?.volume?.label
            ?? DIRECTION_VOLUME_QUESTION_LABEL.incoming[language]}
          unit={expectedTransactionBehavior?.currency}
          coefficient={getCoefficientFromPeriod(
            extraParameters?.incoming?.volume?.unit,
            extraParameters?.incoming?.volume?.period,
          )}
          options={extraParameters?.incoming?.volume?.options}
        />
      )}
      {(!extraParameters || extraParameters?.incoming?.max?.enabled) && (
        <NestedField
          source="incoming.max"
          label={extraParameters?.incoming?.max?.label
            ?? DIRECTION_MAX_QUESTION_LABEL.incoming[language]}
          unit={expectedTransactionBehavior?.currency}
        />
      )}
      {(!extraParameters || extraParameters?.incoming?.median?.enabled) && (
        <NestedField
          source="incoming.median"
          label={extraParameters?.incoming?.median?.label
            ?? DIRECTION_MEDIAN_QUESTION_LABEL.incoming[language]}
          unit={expectedTransactionBehavior?.currency}
        />
      )}
      {(!extraParameters || extraParameters?.incoming?.frequency?.enabled) && (
        <NestedField
          source="incoming.monthlyFrequency"
          label={extraParameters?.incoming?.frequency?.label
            ?? DIRECTION_FREQUENCY_QUESTION_LABEL.incoming[language]}
          options={extraParameters?.incoming?.frequency?.options}
          coefficient={getCoefficientFromPeriod(
            extraParameters?.incoming?.frequency?.unit,
            extraParameters?.incoming?.frequency?.period,
          )}
        />
      )}
      {(!extraParameters || extraParameters?.incoming?.expectedTransactionTimeline?.enabled) && (
        <NestedField
          source="incoming.expectedTransactionTimeline.amount"
          label={extraParameters?.incoming?.expectedTransactionTimeline?.label
            ?? DIRECTION_TIMELINE_QUESTION_LABEL.incoming[language]}
          unit={expectedTransactionBehavior?.incoming?.expectedTransactionTimeline?.unit}
        />
      )}
      {(!extraParameters || extraParameters?.outgoing?.volume?.enabled) && (
        <NestedField
          source="outgoing.monthlyVolume"
          label={extraParameters?.outgoing?.volume?.label
            ?? DIRECTION_VOLUME_QUESTION_LABEL.outgoing[language]}
          unit={expectedTransactionBehavior?.currency}
          options={extraParameters?.outgoing?.volume?.options}
          coefficient={getCoefficientFromPeriod(
            extraParameters?.outgoing?.volume?.unit,
            extraParameters?.outgoing?.volume?.period,
          )}
        />
      )}
      {(!extraParameters || extraParameters?.outgoing?.max?.enabled) && (
        <NestedField
          source="outgoing.max"
          label={extraParameters?.outgoing?.max?.label
            ?? DIRECTION_MAX_QUESTION_LABEL.outgoing[language]}
          unit={expectedTransactionBehavior?.currency}
        />
      )}
      {(!extraParameters || extraParameters?.outgoing?.median?.enabled) && (
        <NestedField
          source="outgoing.median"
          label={extraParameters?.outgoing?.median?.label
            ?? DIRECTION_MEDIAN_QUESTION_LABEL.outgoing[language]}
          unit={expectedTransactionBehavior?.currency}
        />
      )}
      {(!extraParameters || extraParameters?.outgoing?.frequency?.enabled) && (
        <NestedField
          source="outgoing.monthlyFrequency"
          label={extraParameters?.outgoing?.frequency?.label
            ?? DIRECTION_FREQUENCY_QUESTION_LABEL.outgoing[language]}
          options={extraParameters?.outgoing?.frequency?.options}
          coefficient={getCoefficientFromPeriod(
            extraParameters?.outgoing?.frequency?.unit,
            extraParameters?.outgoing?.frequency?.period,
          )}
        />
      )}
      {(!extraParameters || extraParameters?.outgoing?.expectedTransactionTimeline?.enabled) && (
        <NestedField
          source="outgoing.expectedTransactionTimeline.amount"
          label={extraParameters?.outgoing?.expectedTransactionTimeline?.label
            ?? DIRECTION_TIMELINE_QUESTION_LABEL.outgoing[language]}
          unit={expectedTransactionBehavior?.outgoing?.expectedTransactionTimeline?.unit}
        />
      )}
      {(!extraParameters || extraParameters?.process?.volume?.enabled) && (
        <NestedField
          source="process.monthlyVolume"
          label={extraParameters?.process?.volume?.label
            ?? DIRECTION_VOLUME_QUESTION_LABEL.process[language]}
          unit={expectedTransactionBehavior?.currency}
          options={extraParameters?.process?.volume?.options}
          coefficient={getCoefficientFromPeriod(
            extraParameters?.process?.volume?.unit,
            extraParameters?.process?.volume?.period,
          )}
        />
      )}
      {(!extraParameters || extraParameters?.process?.max?.enabled) && (
        <NestedField
          source="process.max"
          label={extraParameters?.process?.max?.label
            ?? DIRECTION_MAX_QUESTION_LABEL.process[language]}
          unit={expectedTransactionBehavior?.currency}
        />
      )}
      {(!extraParameters || extraParameters?.process?.median?.enabled) && (
        <NestedField
          source="process.median"
          label={extraParameters?.process?.median?.label
            ?? DIRECTION_MEDIAN_QUESTION_LABEL.process[language]}
          unit={expectedTransactionBehavior?.currency}
        />
      )}
      {(!extraParameters || extraParameters?.process?.frequency?.enabled) && (
        <NestedField
          source="process.monthlyFrequency"
          label={extraParameters?.process?.frequency?.label
            ?? DIRECTION_FREQUENCY_QUESTION_LABEL.process[language]}
          options={extraParameters?.process?.frequency?.options}
          coefficient={getCoefficientFromPeriod(
            extraParameters?.process?.frequency?.unit,
            extraParameters?.process?.frequency?.period,
          )}
        />
      )}
      {(!extraParameters || extraParameters?.process?.expectedTransactionTimeline?.enabled) && (
        <NestedField
          source="process.expectedTransactionTimeline.amount"
          label={extraParameters?.process?.expectedTransactionTimeline?.label
            ?? DIRECTION_TIMELINE_QUESTION_LABEL.process[language]}
          unit={expectedTransactionBehavior?.process?.expectedTransactionTimeline?.unit}
        />
      )}
      {(!extraParameters || extraParameters?.total?.volume?.enabled) && (
        <NestedField
          source="total.monthlyVolume"
          label={extraParameters?.total?.volume?.label
            ?? DIRECTION_VOLUME_QUESTION_LABEL.total[language]}
          unit={expectedTransactionBehavior?.currency}
          options={extraParameters?.total?.volume?.options}
          coefficient={getCoefficientFromPeriod(
            extraParameters?.total?.volume?.unit,
            extraParameters?.total?.volume?.period,
          )}
        />
      )}
      {(!extraParameters || extraParameters?.total?.max?.enabled) && (
        <NestedField
          source="total.max"
          label={extraParameters?.total?.max?.label ?? DIRECTION_MAX_QUESTION_LABEL.total[language]}
          unit={expectedTransactionBehavior?.currency}
        />
      )}
      {(!extraParameters || extraParameters?.total?.median?.enabled) && (
        <NestedField
          source="total.median"
          label={extraParameters?.total?.median?.label
            ?? DIRECTION_MEDIAN_QUESTION_LABEL.total[language]}
          unit={expectedTransactionBehavior?.currency}
        />
      )}
      {(!extraParameters || extraParameters?.total?.frequency?.enabled) && (
        <NestedField
          source="total.monthlyFrequency"
          label={extraParameters?.total?.frequency?.label
            ?? DIRECTION_FREQUENCY_QUESTION_LABEL.total[language]}
          options={extraParameters?.total?.frequency?.options}
          coefficient={getCoefficientFromPeriod(
            extraParameters?.total?.frequency?.unit,
            extraParameters?.total?.frequency?.period,
          )}
        />
      )}
      {(!extraParameters || extraParameters?.total?.expectedTransactionTimeline?.enabled) && (
        <NestedField
          source="total.expectedTransactionTimeline.amount"
          label={extraParameters?.total?.expectedTransactionTimeline?.label
            ?? DIRECTION_TIMELINE_QUESTION_LABEL.total[language]}
          unit={expectedTransactionBehavior?.total?.expectedTransactionTimeline?.unit}
        />
      )}
    </Grid>
  </RecordContextProvider>
);

const ValueField = ({
  language,
  extraParameters,
}: {
  language?: Languages;
  extraParameters?: ExtraParameters;
}) => {
  const record = useRecordContext<{
    value: ExpectedTransactionBehaviorAnswer;
    sx?: {};
  }>();
  const value = get(record, 'value');
  const sx = get(record, 'sx', {});

  if (!language) return null;
  if (!value) return null;

  return (
    <Card
      variant="outlined"
      square
      sx={{
        m: 0,
        py: 0,
        width: '100%',
      }}
    >
      <Grid container bgcolor="background.paper">
        <ExpectedTransactionBehaviorFields
          extraParameters={extraParameters}
          expectedTransactionBehavior={value}
          language={language}
          sx={sx}
        />
      </Grid>
    </Card>
  );
};

const ExpectedTransactionBehavior = ({
  extraParameters,
}: {
  extraParameters?: ExtraParameters;
}) => {
  const record = useRecordContext<{
    language: Languages;
    expectedTransactionBehaviour: ExpectedTransactionBehaviorAnswer[];
  }>();
  const answers = record?.expectedTransactionBehaviour;
  const language = record?.language;
  if (!language || !answers) return null;

  const overrideValue = extraParameters?.singleTransactionTypeConfigurationOverride;
  if (overrideValue) {
    const index = overrideValue === 'noTransactionTypeFilter'
      ? answers.findIndex(({ transactionTypeConfiguration }) => isUndefined(
        transactionTypeConfiguration,
      ))
      : answers.findIndex(({ transactionTypeConfiguration }) => isEqual(
        transactionTypeConfiguration,
        overrideValue,
      ));
    return (
      <Answer
        label={LABEL[language]}
        isComplexType
        source={`expectedTransactionBehaviour[${index}]`}
        datapoint={conflictFields.TRANSACTION_MONITORING_THRESHOLD}
      >
        <ValueField language={language} extraParameters={extraParameters} />
      </Answer>
    );
  }

  return answers.map((expectedTransactionBehavior, index) => {
    if (!extraParameters?.transactionTypeConfigurationOptions) return null;

    const option = extraParameters.transactionTypeConfigurationOptions.find(({ value }) => isEqual(
      expectedTransactionBehavior.transactionTypeConfiguration,
      value,
    ));
    if (!option) return null;

    return (
      <Answer
        key={JSON.stringify(expectedTransactionBehavior)}
        label={`${LABEL[language]} - ${option.label}`}
        isComplexType
        source={`expectedTransactionBehaviour[${index}]`}
        datapoint={conflictFields.TRANSACTION_MONITORING_THRESHOLD}
      >
        <ValueField language={language} extraParameters={extraParameters} />
      </Answer>
    );
  });
};

ExpectedTransactionBehavior.ValueField = ValueField;

export default ExpectedTransactionBehavior;
