import React, { useCallback } from 'react';

import {
  Box,
} from '@mui/material';
import { Form, TextInput } from 'react-admin';

import entityTypes, { EntityTypes } from '../../../../../constants/entityTypes';
import SubmitOnChange from '../../../../utils/SubmitOnChange';

export interface FilterType {
  'nationalIdentifier.identifier'?: string;
  firstName?: string;
  lastName?: string;
  registrationNumber?: string;
  name?: string;
}

const Filter = ({
  filter,
  setFilter,
  type,
}: {
  filter?: FilterType;
  setFilter: (x: (prev?: FilterType) => FilterType) => void;
  type: EntityTypes;
}) => {
  const onSubmit = useCallback((data: FilterType) => {
    setFilter((prev) => ({ ...prev, ...data }));
  }, [setFilter]);

  return (
    <Box>
      <Form record={filter}>
        <SubmitOnChange onSubmit={onSubmit} throttleMs={200} />
        {type === entityTypes.INDIVIDUAL && (
          <Box display="flex" gap={2}>
            <TextInput
              source="nationalIdentifier.identifier"
              size="small"
              label="National identifier"
              helperText={false}
            />
            <TextInput
              source="firstName"
              size="small"
              label="First name"
              helperText={false}
            />
            <TextInput
              source="lastName"
              size="small"
              label="Last name"
              helperText={false}
            />
          </Box>
        )}
        {type === entityTypes.BUSINESS && (
          <Box display="flex" gap={2}>
            <TextInput
              source="registrationNumber"
              size="small"
              label="Organization Number"
              helperText={false}
            />
            <TextInput
              source="name"
              size="small"
              label="Name"
              helperText={false}
            />
          </Box>
        )}
      </Form>
    </Box>
  );
};

export default Filter;
