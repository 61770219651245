import { ValueOf } from '../utilities/types';

const productTypes = {
  SWISH: 'swish',
} as const;

export const productTypesChoices = [
  {
    id: productTypes.SWISH,
    name: 'Swish',
  },
];

export type ProductTypes = ValueOf<typeof productTypes>;

export default productTypes;
