import React, { useCallback, useMemo } from 'react';

import {
  Box,
  Button,
} from '@mui/material';

import {
  AutocompleteInput,
  ReferenceInput,
  useGetIdentity,
} from 'react-admin';

import { useFormContext } from 'react-hook-form';

import ExtendedChoiceContext from '../utils/ExtendedChoiceContext';

const DEESCALATED_FILTER_VALUE = 'deescalated';

const enableGetReference = (currentValue?: string | null) => currentValue !== null && currentValue !== '' && currentValue !== DEESCALATED_FILTER_VALUE;

const CaseEscalationFilter = ({ source }: {source: string}) => {
  const { setValue } = useFormContext();
  const { identity } = useGetIdentity();
  const handleEscalateTo = useCallback(() => {
    if (identity?.id) {
      setValue(source, identity?.id);
    }
  }, [identity?.id, setValue, source]);

  const handleDeescalated = useCallback(() => {
    setValue(source, DEESCALATED_FILTER_VALUE);
  }, [setValue, source]);

  const extraChoices = useMemo(() => [{
    name: 'Not escalated',
    id: DEESCALATED_FILTER_VALUE,
  }], []);

  return (
    <Box
      display="flex"
      alignItems="flex-end"
      flexDirection="column"
    >
      <Box display="flex" gap={1}>
        <Button onClick={handleDeescalated} size="small">Not escalated</Button>
        <Button onClick={handleEscalateTo} size="small">Escalated to me</Button>
      </Box>
      <ReferenceInput
        source={source}
        reference="members"
        label="Member"
        filter={{ active: true }}
        enableGetReference={enableGetReference}
      >
        <ExtendedChoiceContext extraChoices={extraChoices}>
          <AutocompleteInput
            fullWidth
            filterSelectedOptions={false}
            filterToQuery={(searchText) => ({ name: searchText })}
            optionText={(item: { name?: string; firstName: string; lastName: string}) => item.name ?? `${item.firstName} ${item.lastName}`}
          />
        </ExtendedChoiceContext>
      </ReferenceInput>
    </Box>
  );
};

export default CaseEscalationFilter;
