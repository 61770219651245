import React from 'react';

import {
  Grid,
} from '@mui/material';

import ThresholdInput from '../ruleCommon/ThresholdInput';
import TransactionTypeConfiguration from '../ruleCommon/TransactionTypeConfiguration';

const AmountThreshold = () => (
  <Grid item xs={12} md={12}>
    <Grid container spacing={1}>
      <ThresholdInput source="default" />
      <TransactionTypeConfiguration />
    </Grid>
  </Grid>

);

export default AmountThreshold;
