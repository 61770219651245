import React from 'react';

import {
  Datagrid,
  TextField,
  DateField,
  ReferenceField,
  ChipField,
} from 'react-admin';

import LinkField from '../../../layout/LinkField';
import NoResults from '../../../layout/NoResults';
import StatusField from '../../../../customFields/StatusField';
import ReferenceEntityField from '../../../../customFields/ReferenceEntityField';
import { boldDataGridStyle } from '../../../../constants/style/datagridStyles';

const CarPurchase = (
  {
    children,
  }: {
    children?: React.ReactNode;
  },
) => (
  <Datagrid sx={boldDataGridStyle} bulkActionButtons={false} empty={<NoResults variant="h6" />}>
    <LinkField sortable={false} source="id" />
    <DateField sortable source="createdAt" showTime />
    <StatusField sortable={false} source="recommendation" />
    <ReferenceField sortable={false} reference="processes" source="process">
      <ChipField source="name" />
    </ReferenceField>
    <DateField sortable={false} source="extraInformation.date" showTime />
    <TextField source="extraInformation.amount" label="Amount" />
    <TextField sortable={false} source="extraInformation.currency" label="Currency" />
    <ReferenceEntityField sortable={false} source="extraInformation.car" label="Car" />
    <ReferenceEntityField sortable={false} source="extraInformation.buyer" label="Buyer" />
    <ReferenceEntityField sortable={false} source="extraInformation.seller" label="Seller" />
    <ReferenceEntityField sortable={false} source="extraInformation.guarantor" label="Guarantor" />
    {children}
  </Datagrid>
);

export default CarPurchase;
