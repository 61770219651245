import React from 'react';

import {
  Box,
  Grid,
  Typography,
  Fade,
} from '@mui/material';

import PreviousOwnerList from './PreviousOwnersList';

import useEntityData from '../../../../../hooks/useEntityData';

import PurchaseButton from '../../../../layout/button/PurchaseButton';

import actions from '../../../../../constants/actions';
import { EntityTypes } from '../../../../../constants/entityTypes';

type CarPreviousOwners = {
  id: string;
  createdAt: Date;
  entityId: string;
  regnr?: string;
  skyltnr?: string;
  fabrikat?: string;
  fordonsslag?: string;
  farg?: string;
  chassi?: string;
  armodell?: string;
  leasing?: string;
  anteg?: string;
  fbnr?: string;
  namn?: string;
  adress?: string;
  postnr?: string;
  postanst?: string;
  datforv?: string;
  tidigareAgareLista?: {
    fbnr?: string;
    namn?: string;
    adress?: string;
    postnr?: string;
    postAnst?: string;
    datforv?: string;
    legarRoll?: string;
    lNamn?: string;
    ladress?: string;
    lPostnr?: string;
    lPostAnst?: string;
    lOrgnr?: string;
    lEgareFrom?: string;
    entity?: {
      id: string;
      type: EntityTypes;
    };
  }[];
  fortsattning?: boolean;
  sidref?: string;
  err: string;
};

const PreviousOwners = () => {
  const { data, handlePurchase, isLoading } = useEntityData<CarPreviousOwners>({ source: 'previous-owner', getAction: actions.CAR_CREATE_BASIC_DATA });

  if (data) {
    return (
      <Grid container spacing={6}>
        <PreviousOwnerList data={data} handlePurchase={handlePurchase} isLoading={isLoading} />
      </Grid>
    );
  }
  return (
    <Fade in={!isLoading}>
      <Box margin={5} display="flex" alignItems="center" width="100%" flexDirection="column">
        <Typography variant="smallTitle">No Data Available</Typography>
        <PurchaseButton action={actions.CAR_CREATE_PREVIOUS_OWNERS_DATA} loading={isLoading} label="Get Previous Owner Data" empty onClick={handlePurchase} />
      </Box>
    </Fade>
  );
};

export default PreviousOwners;
