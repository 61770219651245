import React from 'react';

import { Grid } from '@mui/material';

import Entity, { EntityFormValues, prefillEntity } from './Entity';
import Person, { PersonFormValues, prefillPerson } from './Person';
import entityTypes, { EntityTypes } from '../../../../../../constants/entityTypes';
import { IIndividual } from '../../../../../../utilities/schemas/individual';
import { IBusiness } from '../../../../../../utilities/schemas/business';

export type PartyFormValues = PersonFormValues | EntityFormValues;

export const prefillPartyGetter = (type: EntityTypes) => (record: IIndividual | IBusiness) => {
  if (type === entityTypes.INDIVIDUAL) {
    return prefillPerson(record as IIndividual);
  }
  if (type === entityTypes.BUSINESS) {
    return prefillEntity(record as IBusiness);
  }
  throw new Error('Unsupported entity type');
};

const PartyForm = ({
  type,
}: {
  type: EntityTypes;
}) => (
  <Grid>
    {type === entityTypes.INDIVIDUAL && <Person />}
    {type === entityTypes.BUSINESS && <Entity />}
  </Grid>

);
export default PartyForm;
