import React, { useCallback } from 'react';

import {
  useCreate,
  useDataProvider,
  useGetOne,
  useNotify,
} from 'react-admin';

import {
  Button,
  Box,
  Typography,
  Fade,
} from '@mui/material';

import NoteAddIcon from '@mui/icons-material/NoteAdd';
import SettingsIcon from '@mui/icons-material/Settings';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import ErrorIcon from '@mui/icons-material/Error';

import { useWatch } from 'react-hook-form';
import { useParams } from 'react-router-dom';

import cleaner from '../../utilities/dataProvider/cleaner';
import errorNotification from '../../utilities/errorNotification';
import reportStatuses from '../../constants/reportStatuses';

const Generate = ({ reportCode }: { reportCode: 'STR' | 'SAR' }) => {
  const dataProvider = useDataProvider();
  const { id } = useParams();
  const report = useWatch({ name: 'report' });
  const notify = useNotify();

  const [create, { data: generatedReportData, isLoading, error }] = useCreate(
    'reports',
    {
      data: {
        report: {
          ...(report ? cleaner(report) : {}),
          report_code: reportCode,
          submission_code: 'E',
          caseId: id,
        },
        type: 'fipo',
      },
    },
    {
      onError: ((e) => { notify(errorNotification(e), { type: 'error' }); }),
    },
  );

  const reportId = generatedReportData?.id;
  const { data } = useGetOne(
    'reports',
    { id: reportId },
    {
      enabled: !!reportId,
      refetchInterval: (latestData) => (latestData?.status === reportStatuses.READY ? false : 500),
    },
  );

  const fileId = data?.files?.at(0)?.id;
  const handleDownloadClick = useCallback(() => {
    dataProvider.getOne(`reports/download/${reportId}`, { id: fileId })
      .then((response) => {
        window.open(response.data.downloadUrl, '_blank');
      })
      .catch(() => {
        notify('Error: Could not download report', { type: 'error' });
      });
  }, [dataProvider, notify, reportId, fileId]);

  const isReportReady = data?.status === reportStatuses.READY;
  const isReportGenerating = !!reportId && !isReportReady;
  const isGenerationNotStarted = !reportId && !error;

  const handleClick = () => {
    create();
  };

  return (
    <Box height="100%" display="flex" alignItems="center" justifyContent="center" flexDirection="column" gap="5%" padding="10%">
      <Fade in>
        <Box display="flex" alignItems="center" justifyContent="center" flexDirection="column" gap={4} width="100%" height="100%">
          {isGenerationNotStarted && (
            <>
              <Typography
                alignSelf="center"
                variant="h2"
                color="primary"
              >
                Start report generation
              </Typography>
              <Button
                variant="contained"
                onClick={handleClick}
                startIcon={<NoteAddIcon />}
                size="large"
                disabled={isLoading}
              >
                Start Generation
              </Button>
            </>
          )}
          {isReportGenerating && (
            <>
              <Typography
                alignSelf="center"
                variant="h2"
                color="primary"
              >
                Generation have started
              </Typography>
              <Button
                variant="contained"
                onClick={handleDownloadClick}
                startIcon={(
                  <SettingsIcon
                    sx={{
                      animation: 'spin 2s linear infinite',
                      '@keyframes spin': {
                        '0%': {
                          transform: 'rotate(360deg)',
                        },
                        '100%': {
                          transform: 'rotate(0deg)',
                        },
                      },
                    }}
                  />
                )}
                size="large"
                disabled
              >
                Download
              </Button>
            </>
          )}
          {isReportReady && (
            <>
              <Typography
                alignSelf="center"
                variant="h2"
                color="primary"
              >
                Report is generated
              </Typography>
              <Button
                variant="contained"
                onClick={handleDownloadClick}
                startIcon={(
                  <FileDownloadIcon />
                )}
                size="large"
              >
                Download
              </Button>
            </>
          )}
          {!!error && (
            <>
              <Typography
                alignSelf="center"
                variant="h2"
                color="primary"
              >
                Report generation failed
              </Typography>
              <Button
                variant="contained"
                onClick={handleClick}
                startIcon={<ErrorIcon />}
                size="large"
                disabled={isLoading}
              >
                Retry
              </Button>
            </>
          )}
        </Box>
      </Fade>
    </Box>
  );
};

export default Generate;
