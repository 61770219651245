const palette = {
  mode: 'light',
  primary: {
    main: '#124131',
    contrastText: '#FFF',
  },
  secondary: {
    main: '#238061',
    contrastText: '#FFF',
  },
  red: {
    main: '#7C2521',
    contrastText: '#FFF',
  },
  blue: {
    main: '#6A90AF',
    contrastText: '#FFF',
  },
  yellow: {
    main: '#DFA639',
    contrastText: '#FFF',
  },
  orange: {
    main: '#D1603C',
    contrastText: '#FFF',
  },
  greenGray: {
    main: '#12413114',
    contrastText: '#124131',
  },
  background: {
    default: '#F7F9FC',
    paper: '#FFF',
    grey: '#FAFAFA',
  },
} as const;

export default palette;

type Color = {
  main: string;
  contrastText: string;
}

declare module '@mui/material/styles' {
  interface Palette {
    red: Color;
    blue: Color;
    yellow: Color;
    orange: Color;
    greenGray: Color;
  }
  interface PaletteOptions {
    red: Color;
    blue: Color;
    yellow: Color;
    orange: Color;
    greenGray: Color;
  }

  interface PaletteColor {
    red: Color;
    blue: Color;
    yellow: Color;
    orange: Color;
    greenGray: Color;
  }
}

declare module '@mui/material/SvgIcon' {
  interface SvgIconPropsColorOverrides {
    red: true;
    blue: true;
    yellow: true;
    orange: true;
    greenGray: true;
    background: true;
    'info.dark': true;
  }
}

declare module '@mui/material/Button' {
  interface ButtonPropsColorOverrides {
    red: true;
    blue: true;
    yellow: true;
    orange: true;
    greenGray: true;
    background: true;
  }
}
