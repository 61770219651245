import React from 'react';

import {
  Box,
  Typography,
  Button,
} from '@mui/material';

import CheckIcon from '@mui/icons-material/Check';

const ConflictVerifiedStatus = ({
  selected,
  otherSelected,
  markAsVerified,
} : {
  selected: boolean;
  otherSelected: boolean;
  markAsVerified: () => void;
}) => (
  <>
    {!selected && (
      <Button variant={otherSelected ? 'outlined' : 'contained'} onClick={markAsVerified}>Mark as verified</Button>)}
    {selected && (
      <Box display="flex" alignItems="center" gap={1}>
        <CheckIcon color="secondary" />
        <Typography color="secondary">Verified</Typography>
      </Box>
    )}
  </>
);

export default ConflictVerifiedStatus;
