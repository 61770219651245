/* eslint-disable react-hooks/rules-of-hooks */
import React, { useMemo } from 'react';

import { useRecordContext, SelectField } from 'react-admin';

import { get, isArray, isEmpty } from 'lodash';

import {
  Box, Card, CardContent, Divider, Grid, Typography,
} from '@mui/material';

import { getName } from 'i18n-iso-countries';
import Answer from '../Answer';

import { countryConnectionChoices } from '../../../../constants/countryConnections';
import languages, { Languages } from '../../../../constants/languages';
import { countryChoices } from '../../../../constants/countries';
import conflictFields from '../../../../constants/conflictFields';

const TITLE = {
  [languages.SV]: 'Medborgarskap',
  [languages.EN]: 'Citizenship ',
};

const CONNECTION_FIELD_LABEL = {
  [languages.SV]: 'Anknytning',
  [languages.EN]: 'Connection',
};

const ValueField = ({ language }: {language?: Languages}) => {
  const record = useRecordContext();

  if (!language) return null;

  const value = get(record, 'value') as {country: string; connections?: string[] }[] | undefined;
  const sx = get(record, 'sx', {});

  if (!isArray(value)) return null;

  return (
    <Card variant="outlined" sx={{ m: 0 }}>
      <Grid item xs={12} md={12}>
        <Grid container>
          {value.map((item, i) => (
            <Grid item xs={12} md={12} key={item.country}>
              <CardContent sx={{ p: 4 }}>
                <Grid container>
                  <Grid item xs={12} md={6}>
                    <Box display="flex" flexDirection="column">
                      <Typography variant="label">{TITLE[language]}</Typography>
                      <SelectField
                        record={{ item }}
                        source="item.country"
                        choices={countryChoices(language)}
                        fontWeight="bold"
                        sx={sx}
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Box display="flex" flexDirection="column">
                      <Typography variant="label">{CONNECTION_FIELD_LABEL[language]}</Typography>
                      {item.connections?.map((connection, j) => (
                        <Box display="flex" key={connection}>
                          <SelectField
                            record={{ connection }}
                            source="connection"
                            choices={countryConnectionChoices(language)}
                            fontWeight="bold"
                            sx={sx}
                          />
                          {(j + 1) !== item.connections?.length && (
                            <Typography mr={1}>,</Typography>
                          )}
                        </Box>
                      ))}
                    </Box>
                  </Grid>
                </Grid>
              </CardContent>
              {(i + 1) !== value.length && <Divider />}
            </Grid>
          ))}
        </Grid>
      </Grid>
    </Card>
  );
};

const Citizenship = () => {
  const record = useRecordContext();

  const language = record?.language as Languages;

  return (
    <Answer
      label={TITLE[language]}
      datapoint={conflictFields.CITIZENSHIP}
      source="citizenship"
      isComplexType
    >
      <ValueField language={language} />
    </Answer>
  );
};

Citizenship.ValueField = ValueField;

Citizenship.Nested = ({ source }: { source?: string }) => {
  const record = useRecordContext();

  const language = record?.language as Languages;

  const countries = useMemo(
    () => get((record), `${source ?? 'citizenship'}`),
    [source, record],
  ) as {country: string; connections?: string[] }[] | undefined;

  return (
    <>
      <Grid item xs={12} md={3}>
        <Box display="flex" flexDirection="column">
          <Typography variant="label">{TITLE[language]}</Typography>
          <Box display="flex" gap={0}>
            <Typography>
              {
                countries?.map((item: {country: string; connection?: string }) => (getName(item.country, language))).join(', ')
              }
            </Typography>
            {isEmpty(countries) && <Typography>-</Typography>}
          </Box>
        </Box>
      </Grid>
      {countries?.map((item: {country: string; connections?: string[] }) => (
        <Grid key={item.country} item xs={12} md={3}>
          <Box display="flex" flexDirection="column">
            <Typography variant="label">{`Connection to ${getName(item.country, language)}`}</Typography>
            <Typography>
              {item.connections?.map((connection) => (
                countryConnectionChoices(language).find(({ id }) => id === connection)?.name
              )).join(', ')}
            </Typography>
            {isEmpty(item.connections) && <Typography>-</Typography>}
          </Box>
        </Grid>
      ))}
    </>
  );
};

export default Citizenship;
