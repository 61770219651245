import { ValueOf } from '../utilities/types';

const productPartyRoles = {
  PRODUCT_HOLDER: 'product-holder',
  CONNECTED_ACCOUNT: 'connected-account',
} as const;

export const productPartyRolesChoices = [
  {
    id: productPartyRoles.PRODUCT_HOLDER,
    name: 'Product Holder',
  },
  {
    id: productPartyRoles.CONNECTED_ACCOUNT,
    name: 'Connected Account',
  },
];

export type ProductPartyRoles = ValueOf<typeof productPartyRoles>;

export default productPartyRoles;
