import Link from '@mui/material/Link';
import React from 'react';
import {
  RaRecord,
  TextField,
  TextFieldProps,
  useCreatePath,
  useRecordContext,
  useResourceContext,
} from 'react-admin';
import { Link as RouterLink } from 'react-router-dom';

const LinkField = <RecordType extends RaRecord>({
  sx,
  link,
  linkType,
  ...props
}: TextFieldProps<RecordType> & {
  link?: string;
  linkType?: string;
}) => {
  const createPath = useCreatePath();

  const resource = useResourceContext();
  const record = useRecordContext();
  const finalLink = link ?? createPath({ resource, id: record?.id, type: linkType ?? 'edit' });

  return (
    <Link component={RouterLink} to={finalLink}>
      <TextField
        sx={{ ...sx, textDecoration: 'underline' }}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...props}
      />
    </Link>
  );
};

export default LinkField;
